const actionType = {
  setArray1: "actions.master.whatsapp.setArray1",
  addArray1: "actions.master.whatsapp.addArray1",
  updateArray1: "actions.master.whatsapp.updateArray1",
  deleteArray1: "actions.master.whatsapp.deleteArray1",

  setObject1: "actions.master.whatsapp.setObject1",
  updateObject1: "actions.master.whatsapp.updateObject1",

  setOther1: "actions.master.whatsapp.other1",
  setOther2: "actions.master.whatsapp.other2",
  setOther3: "actions.master.whatsapp.other3",
  setOther4: "actions.master.whatsapp.other4",
  setOther5: "actions.master.whatsapp.other5",
};
const setArray1 = async (dispatch, payload) => {
  dispatch({ type: actionType.setArray1, payload: payload });
};
const addArray1 = async (dispatch, payload) => {
  dispatch({ type: actionType.addArray1, payload: payload });
};
const updateArray1 = async (dispatch, payload) => {
  dispatch({ type: actionType.updateArray1, payload: payload });
};
const deleteArray1 = async (dispatch, payload) => {
  dispatch({ type: actionType.deleteArray1, payload: payload });
};

const setObject1 = async (dispatch, payload) => {
  dispatch({ type: actionType.setObject1, payload: payload });
};
const updateObject1 = async (dispatch, payload) => {
  dispatch({ type: actionType.updateObject1, payload: payload });
};

const setOther1 = async (dispatch, payload) => {
  dispatch({ type: actionType.setOther1, payload: payload });
};
const setOther2 = async (dispatch, payload) => {
  dispatch({ type: actionType.setOther2, payload: payload });
};
const setOther3 = async (dispatch, payload) => {
  dispatch({ type: actionType.setOther3, payload: payload });
};
const setOther4 = async (dispatch, payload) => {
  dispatch({ type: actionType.setOther4, payload: payload });
};
const setOther5 = async (dispatch, payload) => {
  dispatch({ type: actionType.setOther5, payload: payload });
};

export const whatsapp = {
  actionType,
  setArray1,
  addArray1,
  updateArray1,
  deleteArray1,
  setObject1,
  updateObject1,
  setOther1,
  setOther2,
  setOther3,
  setOther4,
  setOther5,
};
