import GetLocalConfig from "./GetLocalConfig";

const storage = {
  priceList: "priceList",
  themes: "color-theme",
  dataUser: "data-user",
  dataOrg: "data-org",
  accessMenu: "access-menu",
};

const configEnv = await GetLocalConfig();
const { orgName, baseUrl } = configEnv.data;

const config = {
  baseUrl: baseUrl,
  orgName: orgName,
  cryptoScreet: "cryptoScreet",
};

const authConfig = {
  apiKey: "AIzaSyDJtk48X4FIZ3JRt3A0tRYxwlIYk249lvk",
  authDomain: "miksimp.firebaseapp.com",
  projectId: "miksimp",
  storageBucket: "miksimp.appspot.com",
  messagingSenderId: "989683510590",
  appId: "1:989683510590:web:7f4f3f0075637aa600d06f",
  measurementId: "G-R9FT2SKFNQ",
};

export { storage, config, authConfig };
