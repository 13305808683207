import { Form } from "react-bootstrap";

const TextAreaLabel = ({ title, rows, ...rest }) => {
  if (rows === undefined) {
    rows = "15";
  }
  return (
    <Form.Group className="mb-4">
      {title !== undefined ? <Form.Label>{title}</Form.Label> : ""}
      <Form.Control as="textarea" rows={rows} {...rest} />
      <Form.Text
        className="text-danger"
        style={{ display: "none", marginLeft: "10px" }}
      />
    </Form.Group>
  );
};

export default TextAreaLabel;
