import LayoutHome from "../../components/Layout/Home";
import Div from "../../components/Div";
import { ButtonGroup, Card, Form, Stack, ToggleButton } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import JWToken from "../../utils/JWToken";
import { service } from "../../store/service";
import { actions } from "../../store/actions";
import Swalert from "../../components/Swalert";
import Tables from "../../components/Tables";
import FilterListTable from "../../utils/FilterListTable";
import Icon from "../../components/Icon";
import SelectLabel from "../../components/SelectLabel";

const HotspotOnline = () => {
  const dispatch = useDispatch();
  const shOnlineReducer = useSelector((state) => state.shOnlineReducer);
  const { array1, array2, object1 } = shOnlineReducer;

  const [byMikrotik, setByMikrotik] = useState(false);
  const [listTable, setListTable] = useState(array1);
  const [searchVal, setSearchVal] = useState("");

  useEffect(() => {
    setListTable(array1);
  }, [array1]);

  useEffect(() => {
    setListTable(FilterListTable(searchVal, array1));
  }, [searchVal]);

  useEffect(() => {
    if (new JWToken()._getStatusAccessToken()) {
      readFirstData();
    }
  }, []);

  async function readFirstData() {
    const result = await service.layanan.hotspot.online._read(dispatch);
    if (result.status) {
      setByMikrotik(result.data.byMikrotik);
      if (result.data.byMikrotik) {
        result.data.listData.map((item) => {
          item.value = item.id;
          item.label = item.name;
        });
        actions.service.hotspot.online.setArray2(
          dispatch,
          result.data.listData
        );
      } else {
        actions.service.hotspot.online.setArray1(
          dispatch,
          result.data.listData
        );
      }

      return;
    }

    return new Swalert().error(result.data);
  }
  const getDataActive = async (item) => {
    const payload = {
      id: item.id,
    };
    const result = await service.layanan.hotspot.online._active(
      dispatch,
      payload
    );
    if (result.status) {
      actions.service.hotspot.online.setArray1(dispatch, result.data);
      return;
    }

    return new Swalert().error(result.data);
  };

  function setLayoutTable() {
    if (!listTable) {
      return;
    }
    return listTable.map((item, index) => {
      return (
        <Tables.Tr>
          <Tables.Td>{index + 1}</Tables.Td>
          <Tables.Td>{item.voucher}</Tables.Td>
          <Tables.Td>{item.password}</Tables.Td>
          <Tables.Td>{item.mac}</Tables.Td>
          <Tables.Td>{item.comment}</Tables.Td>
        </Tables.Tr>
      );
    });
  }

  return (
    <LayoutHome>
      <Card>
        {byMikrotik ? (
          <SelectLabel
            title={"Select Mikrotik"}
            defaultValue={object1.idParent}
            options={array2}
            onChange={(e) => getDataActive(e)}
          />
        ) : (
          ""
        )}

        <Stack direction="horizontal" style={{ marginBottom: "5px" }} gap={2}>
          <Div>
            <ButtonGroup>
              result : {listTable ? listTable.length : "0"} items
            </ButtonGroup>
          </Div>
          <Div
            className="ms-auto"
            style={{ alignSelf: "flex-end", width: "120px" }}
          >
            <Form.Control
              type={"text"}
              placeholder={"Cari Voucher . . ."}
              onChange={(e) => setSearchVal(e.target.value)}
            />
          </Div>
        </Stack>
        <Tables.Table>
          <Tables.Head>
            <Tables.Tr>
              <Tables.Th>#</Tables.Th>
              <Tables.Th>USERNAME</Tables.Th>
              <Tables.Th>PASSWORD</Tables.Th>
              <Tables.Th>MAC</Tables.Th>
              <Tables.Th>AKTIF</Tables.Th>
            </Tables.Tr>
          </Tables.Head>
          <Tables.Body>{setLayoutTable()}</Tables.Body>
        </Tables.Table>
      </Card>
    </LayoutHome>
  );
};

export default HotspotOnline;
