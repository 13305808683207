import { PageMenu } from "../../../route/PageMenu";
import CriptoJS from "../../../utils/CriptoJS";
import JWToken from "../../../utils/JWToken";
import { storage } from "../../../utils/StaticString";
import UseLocalStorage from "../../../utils/UseLocalStorage";

function CheckData() {
  function redirect(params) {
    window.location.href = params;
  }

  const [accessMenu] = UseLocalStorage(storage.accessMenu, false);
  const [dataOrg] = UseLocalStorage(storage.dataOrg, false);
  const [dataUser] = UseLocalStorage(storage.dataUser, false);
  const [themes] = UseLocalStorage(storage.themes, false);
  const cekAuth = new JWToken()._getStatusAccessToken();
  if (cekAuth) {
    const access = new CriptoJS().decript(accessMenu);
    if (!access) {
      return redirect(PageMenu.d_sigIn);
    }
    if (dataUser.user === "") {
      return redirect(PageMenu.d_user);
    }
    if (dataOrg.orgName === "") {
      for (let index = 0; index < access.length; index++) {
        const item = access[index];
        if (item.path === PageMenu.m_org) {
          return redirect(PageMenu.m_org);
        }
      }
    }
    return redirect(PageMenu.d_home);
  }
  return redirect(PageMenu.d_sigIn);
}
export default CheckData;
