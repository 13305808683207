import { useState } from "react";

import "firebase/compat/auth";
import firebase from "firebase/compat/app";

import { signInWithPopup, onAuthStateChanged } from "firebase/auth";
import { GoogleAuthProvider } from "firebase/auth";

import { authConfig } from "./StaticString";

const AuthGoogle = () => {
  if (!firebase.apps.length) {
    firebase.initializeApp(authConfig);
  }

  const [userAuth, setUser] = useState(null);
  const auth = firebase.auth();

  function logOut() {
    firebase.auth().signOut();
  }

  onAuthStateChanged(auth, (currentUser) => {
    if (currentUser) {
      setUser(currentUser.providerData[0]);
    }
  });

  const logIn = () => {
    try {
      const provider = new GoogleAuthProvider();
      signInWithPopup(auth, provider);
    } catch (error) {
      console.log(error);
    }
  };

  return [userAuth, logIn, logOut];
};

export default AuthGoogle;
