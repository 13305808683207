import { Alert, Button, Form, Image } from "react-bootstrap";
import LayoutAuth from "../../components/Layout/Auth";
import { storage } from "../../utils/StaticString";
import { useEffect, useState } from "react";
import AuthGoogle from "../../utils/AuthGoogle";

import { useDispatch } from "react-redux";
import InputLabel from "../../components/InputLabel";
import UseLocalStorage from "../../utils/UseLocalStorage";
import { orgLogo } from "../../assets";
import CriptoJS from "../../utils/CriptoJS";
import Swalert from "../../components/Swalert";
import { useNavigate } from "react-router-dom";
import JWToken from "../../utils/JWToken";
import { service } from "../../store/service";

const removeAllStorage = async () => {
  try {
    if (typeof window !== "undefined") {
      //window.localStorage.clear();
      const cekStorageUser = window.localStorage.getItem(storage.dataUser);
      if (cekStorageUser !== null) {
        window.localStorage.removeItem(storage.dataUser);
      }

      const cekStorageAccess = window.localStorage.getItem(storage.accessMenu);
      if (cekStorageAccess !== null) {
        window.localStorage.removeItem(storage.accessMenu);
      }
    }
  } catch (error) {
    console.log(error);
  }
};

const SignIn = () => {
  removeAllStorage();
  const dispatch = useDispatch();

  const [accMenu, setAccMenu] = UseLocalStorage(storage.accessMenu, false);
  const [dataUser, setDataUser] = UseLocalStorage(storage.dataUser, false);
  const [dataOrg, setDataOrg] = UseLocalStorage(storage.dataOrg, false);
  const [theme, setTheme] = UseLocalStorage(storage.themes, false);
  const [listHarga, setListHarga] = UseLocalStorage(storage.priceList, false);

  const [userAuth, logIn, logOut] = AuthGoogle();
  const [validated, setValidated] = useState(false);

  const [alertDialog, setAlertDialog] = useState("");
  const [formData, setFormData] = useState({});
  const [timeoutID, setTimeoutID] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    if (userAuth !== null) {
      signEmail();
    }
  }, [userAuth]);

  const updateAlertDialog = (color, notification) => {
    setAlertDialog(
      <Alert key={color} variant={color}>
        {notification}
      </Alert>
    );
  };
  const updateAlertDialogTimer = (color, notification) => {
    updateAlertDialog(color, notification);

    if (timeoutID !== "") {
      clearTimeout(timeoutID);
    }
    const timeoutId = setTimeout(() => {
      setAlertDialog("");
      setTimeoutID("");
    }, 5000);
    setTimeoutID(timeoutId);
  };
  const updateData = (e) => {
    setValidated(false);
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      signUserPass();
    }
  };
  const signUserPass = async () => {
    if (!formData.username || !formData.password) {
      return setValidated(true);
    }
    const result = await service.auth.sign.signUserPass(dispatch, formData);
    return resultLogin(result);
  };
  const signEmail = async () => {
    const payload = { uid: userAuth.uid, email: userAuth.email };
    const result = await service.auth.sign.signEmail(dispatch, payload);
    setInterval(function () {
      logOut();
    }, 1000);
    return resultLogin(result);
  };
  const resultLogin = async (result) => {
    if (result.status === "network_error") {
      return updateAlertDialogTimer("danger", result.error);
    } else if (result.status === "invalid_user") {
      return updateAlertDialogTimer("danger", result.error);
    } else if (result.status === "invalid_password") {
      const wrongData = result.wrong;
      if (wrongData < 3) {
        return updateAlertDialogTimer("warning", result.error);
      } else {
        return updateAlertDialogTimer("danger", result.error);
      }
    } else if (result.status === "user_locked") {
      accountLocked(result.name, result.limitLogin);
    } else if (result.status === "succes_login") {
      new JWToken()._setJWToken(result.token);

      setTheme(result.theme);
      setDataUser(result.dataUser);
      setDataOrg(result.dataOrg);
      setListHarga(result.listHarga);

      setAccMenu(new CriptoJS().encript(result.dataMenu));
      const swal = await new Swalert().success("Login Berhasil . . .");
      if (swal) {
        navigate("/", { replace: true });
      }
    }
  };
  const accountLocked = (name, limitLogin) => {
    var notify = [
      name,
      <br />,
      "Akun Anda Terkunci",
      <br />,
      "Waktu tunggu . . . .",
    ];

    updateAlertDialog("danger", notify);

    var countDownDate = new Date(limitLogin).getTime();
    var x = setInterval(function () {
      var now = new Date().getTime();
      var distance = countDownDate - now;

      var minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
      var seconds = Math.floor((distance % (1000 * 60)) / 1000);
      console.log(minutes, seconds);
      let limit = minutes + "m " + seconds + "s";
      if (minutes <= 0) {
        limit = seconds + "s";
      }
      var notify = [
        name,
        <br />,
        "Akun Anda Terkunci",
        <br />,
        "Waktu tunggu " + limit + "",
      ];
      updateAlertDialog("danger", notify);
      if (distance <= 0) {
        clearInterval(x);
        notify = [name, <br />, "Silahkan Login Kembali"];
        updateAlertDialog("success", notify);
      }
    }, 1000);
  };

  return (
    <LayoutAuth type={"sigIn"}>
      <div className="logo">
        <table style={{ width: "100%" }}>
          <tbody>
            <tr>
              <td style={{ padding: "10px", textAlign: "center" }}>
                <Image
                  style={{ height: "120px" }}
                  src={
                    dataOrg
                      ? dataOrg.orgIcon
                        ? dataOrg.orgIcon
                        : orgLogo
                      : orgLogo
                  }
                ></Image>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <p className="auth-description">
        Please sign-in to your account and continue to the dashboard
      </p>
      {alertDialog}
      <div className="auth-credentials m-b-xxl">
        <Form noValidate validated={validated}>
          <InputLabel
            title={"UserName"}
            type={"text"}
            error={"Please provide a valid UserName"}
            name={"username"}
            required
            onChange={updateData}
            onKeyDown={handleKeyDown}
          />
          <InputLabel
            title={"Password"}
            type={"password"}
            error={"Please provide a valid Password"}
            name={"password"}
            required
            onChange={updateData}
            onKeyDown={handleKeyDown}
          />
        </Form>
      </div>
      <div className="auth-submit">
        <Button
          variant="outline-primary"
          style={{ width: "100%" }}
          onClick={signUserPass}
        >
          SIGN IN
        </Button>
        <Button
          variant="outline-primary"
          style={{ width: "100%", marginTop: "5%" }}
          onClick={logIn}
        >
          <svg
            width="22"
            height="22"
            fill="currentColor"
            className="bi bi-google"
            viewBox="0 0 16 16"
            style={{ paddingBottom: "4px" }}
          >
            <path d="M15.545 6.558a9.42 9.42 0 0 1 .139 1.626c0 2.434-.87 4.492-2.384 5.885h.002C11.978 15.292 10.158 16 8 16A8 8 0 1 1 8 0a7.689 7.689 0 0 1 5.352 2.082l-2.284 2.284A4.347 4.347 0 0 0 8 3.166c-2.087 0-3.86 1.408-4.492 3.304a4.792 4.792 0 0 0 0 3.063h.003c.635 1.893 2.405 3.301 4.492 3.301 1.078 0 2.004-.276 2.722-.764h-.003a3.702 3.702 0 0 0 1.599-2.431H8v-3.08h7.545z"></path>
          </svg>{" "}
          SIGN IN WITH GOOGLE
        </Button>
      </div>
    </LayoutAuth>
  );
};

export default SignIn;
