import { useDispatch, useSelector } from "react-redux";
import LayoutHome from "../../components/Layout/Home";
import Div from "../../components/Div";
import { Button, Card, Form, Image, Modal, Table } from "react-bootstrap";
import Tables from "../../components/Tables";
import JWToken from "../../utils/JWToken";
import { useEffect, useState } from "react";
import { actions } from "../../store/actions";
import { service } from "../../store/service";
import Swalert from "../../components/Swalert";
import { check, cross, loading } from "../../assets";
import { io } from "socket.io-client";
import PhoneLabel from "../../components/PhoneLabel";
import TextAreaLabel from "../../components/TextAreaLabel";
import Icon from "../../components/Icon";
import { PageMenu } from "../../route/PageMenu";
import InputLabel from "../../components/InputLabel";
import LayoutModal from "../../components/LayoutModal";

const Whatsapp = () => {
  const dispatch = useDispatch();
  const globalReducer = useSelector((state) => state.globalReducer);
  const { modalAction } = globalReducer;

  const mWhatsappReducer = useSelector((state) => state.mWhatsappReducer);
  const { other1, other2, other3, other4, other5 } = mWhatsappReducer;
  const { array1, object1 } = mWhatsappReducer;

  const actSendMessage = "actSendMessage";
  const actAutoReply = "actAutoReply";

  const [formValidate, setFormValidate] = useState(false);
  const [waValidate, setWaValidate] = useState(true);

  useEffect(() => {
    if (new JWToken()._getValueAccessToken()) {
      getFirtsData();
    }
  }, []);

  useEffect(() => {
    if (other1 !== "") {
      setSocketWA();
    }
  }, [other1]);

  useEffect(() => {
    if (other2) {
      other2.emit("create-session", other1.noWaNotif);
      other2.on(other1.noWaNotif, (data) => {
        if (data.action === "qr") {
          const notify = "scan dengan nomor whatsapp di bawah";
          actions.master.whatsapp.setOther3(dispatch, data.payload);
          actions.master.whatsapp.setOther4(dispatch, notify);
        }
        if (data.action === "open") {
          const notify = "whatsapp terhubung ke nomor";
          actions.master.whatsapp.setOther3(dispatch, check);
          actions.master.whatsapp.setOther4(dispatch, notify);
          const dataWA = JSON.parse(data.payload);

          const payload = { keyAccesWA: dataWA.key };
          service.master.whatsapp._update(dispatch, payload);
        }
        if (data.action === "error") {
          actions.master.whatsapp.setOther4(dispatch, data.payload);
        }
        actions.master.whatsapp.setOther5(dispatch, data.action);
      });
    }
  }, [other2]);

  const getFirtsData = async () => {
    actions.master.whatsapp.setOther3(dispatch, loading);
    const result = await service.master.whatsapp._read(dispatch);
    if (result.status) {
      return actions.master.whatsapp.setOther1(dispatch, result.data);
    }
    return new Swalert().error(result.data);
  };
  const setSocketWA = async () => {
    actions.master.whatsapp.setOther2(dispatch, io(other1.urlSocketWA));
  };

  const logoutSession = async () => {
    const notify = "please wait . . .";
    actions.master.whatsapp.setOther3(dispatch, loading);
    actions.master.whatsapp.setOther4(dispatch, notify);
    other2.emit("end-session", other1.noWaNotif);
  };

  async function openModal(action, header, item) {
    setWaValidate(false);
    actions.global.setModalShow(dispatch, true);
    actions.global.setModalAction(dispatch, action);
    actions.global.setModalTitleHeader(dispatch, header);

    if (action === actAutoReply) {
      const result = await service.master.whatsapp._getAutoReply(dispatch, {});
      if (result.status) {
        item = result.data;
      } else {
        return new Swalert().error(
          "Tidak dapat mengambil data, silahkan ulangi lagi"
        );
      }
      await actions.master.whatsapp.setArray1(dispatch, item);
    } else {
      await actions.master.whatsapp.setObject1(dispatch, item);
    }
    return;
  }
  function handleChangeWA(value, country) {
    const contryCode = country.dialCode;
    if (value.length > contryCode.length) {
      if (value.slice(0, contryCode.length) === contryCode) {
        if (value.slice(contryCode.length, contryCode.length + 1) !== "0") {
          setWaValidate(true);
        } else {
          setWaValidate(false);
        }
      } else {
        setWaValidate(false);
      }
    } else {
      setWaValidate(false);
    }
    updateData("noWhatsApp", value);
  }
  const updateData = (name, value) => {
    const newData = { [name]: value };
    actions.master.whatsapp.updateObject1(dispatch, newData);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      submitHandler();
    }
  };
  const submitHandler = async () => {
    if (modalAction === actSendMessage) {
      if (!waValidate) {
        updateData("noWhatsApp", "62");
        return new Swalert().error("Berikan Nomor Whatsapp yang valid");
      }

      if (!object1.name) {
        setFormValidate(true);
      }

      const result = await service.master.whatsapp._sendMessage(
        dispatch,
        object1
      );
      if (result.status) {
        actions.global.setModalShow(dispatch, false);
        return new Swalert().success("Berhasil Terkirim");
      }
      return new Swalert().error(result.data);
    }
    if (modalAction === actAutoReply) {
      for (let index = 0; index < array1.length; index++) {
        const el = array1[index];
        if (el.req === "") {
          return new Swalert().warning(
            "Message pada baris ke " + (index + 1) + " tidak boleh kosong"
          );
        }
        if (el.res === "") {
          return new Swalert().warning(
            "Reply pada baris ke " + (index + 1) + " tidak boleh kosong"
          );
        }
      }
      const dataAutoReply = JSON.stringify(array1);
      const payload = { dataAutoReply: dataAutoReply };
      const result = await service.master.whatsapp._setAutoReply(
        dispatch,
        payload
      );
      if (result.status) {
        actions.global.setModalShow(dispatch, false);
        return new Swalert().success(result.data);
      }
      return new Swalert().error(result.data);
    }
  };
  function modalFormSendMessage() {
    return (
      <Form noValidate validated={formValidate}>
        <InputLabel
          title="Nama Penerima"
          name={"name"}
          value={object1.name}
          required
          onChange={(e) => updateData(e.target.name, e.target.value)}
          onKeyDown={handleKeyDown}
        />
        <PhoneLabel
          title="Whatsapp Penerima"
          value={object1.noWhatsApp}
          onChange={handleChangeWA}
          onKeyDown={handleKeyDown}
        />
        <TextAreaLabel
          title={"Isi Pesan"}
          name={"messageWA"}
          value={object1.messageWA}
          onChange={(e) => updateData(e.target.name, e.target.value)}
        />
      </Form>
    );
  }
  function modalFormAutoReply() {
    const changeReq = (i, value) => {
      array1[i].req = value;
    };
    const changeRes = (i, value) => {
      array1[i].res = value;
    };
    const removeIndex = (index) => {
      const dataRow = [...array1];
      dataRow.splice(index, 1);
      actions.master.whatsapp.setArray1(dispatch, dataRow);
    };
    const addNewData = () => {
      actions.master.whatsapp.addArray1(dispatch, { req: "", res: "" });
    };
    return (
      <Form noValidate validated={formValidate}>
        <Tables.Table>
          <Tables.Body>
            <Tables.Tr>
              <Tables.Th>NO</Tables.Th>
              <Tables.Th>MESSAGE</Tables.Th>
              <Tables.Th>REPLY</Tables.Th>
              <Tables.Th>DELETE</Tables.Th>
            </Tables.Tr>
            {array1.map(({ req, res }, index) => {
              return (
                <Tables.Tr>
                  <Tables.Td>{index + 1}</Tables.Td>
                  <Tables.Td>
                    <Form.Control
                      type={"text"}
                      defaultValue={req}
                      onChange={(e) => {
                        changeReq(index, e.target.value);
                      }}
                    />
                  </Tables.Td>
                  <Tables.Td>
                    <Form.Control
                      as="textarea"
                      defaultValue={res}
                      rows={1}
                      onChange={(e) => {
                        changeRes(index, e.target.value);
                      }}
                    />
                  </Tables.Td>
                  <Tables.Td>
                    <Button
                      size="sm"
                      variant={"outline-danger"}
                      style={{ padding: "1px 1px 1px 11px" }}
                      onClick={() => removeIndex(index)}
                    >
                      <Icon style={{ fontSize: "20px" }}>delete</Icon>
                    </Button>
                  </Tables.Td>
                </Tables.Tr>
              );
            })}
          </Tables.Body>
        </Tables.Table>
        <Button
          size="sm"
          variant={"outline-primary"}
          style={{ padding: "1px 1px 1px 11px", marginTop: "10px" }}
          onClick={addNewData}
        >
          <Icon style={{ fontSize: "20px" }}>add_circle</Icon>
        </Button>
      </Form>
    );
  }
  const styleTrans = {
    borderColor: "#00000000",
    backgroundColor: "#00000000",
  };

  return (
    <LayoutHome>
      <Card>
        <LayoutModal onSave={submitHandler}>
          {modalAction === actSendMessage ? modalFormSendMessage() : ""}
          {modalAction === actAutoReply ? modalFormAutoReply() : ""}
        </LayoutModal>
        <Div style={{ width: "100%", height: "100%", margin: "15px" }}>
          <Div className="d-flex flex-column align-items-center text-center">
            <Div className="mt-3">
              <h1 style={{ marginBottom: "30px" }}>WhatsApp API QR</h1>
            </Div>
            <Image
              src={other3}
              alt="img"
              style={{ width: "300px", borderRadius: "5px", cursor: "pointer" }}
            />
            <Div className="mt-3">
              <p className="text-muted font-size-sm text-danger">{other4}</p>
              <h4 style={{ marginBottom: "30px" }}>{other1.noWaNotif}</h4>
              {other5 !== "open" ? (
                ""
              ) : (
                <Table>
                  <Tables.Body>
                    <Tables.Tr>
                      <Tables.Th colspan="3" style={styleTrans}>
                        <Button
                          style={{ marginBottom: "20px" }}
                          variant="danger"
                          onClick={logoutSession}
                        >
                          LOG OUT
                        </Button>
                      </Tables.Th>
                    </Tables.Tr>
                    <Tables.Tr>
                      <Tables.Th style={styleTrans}>
                        <Button
                          variant="primary"
                          onClick={() =>
                            openModal(actSendMessage, "Send Message Whatsapp", {
                              noWhatsApp: "",
                              messageWA: "",
                            })
                          }
                        >
                          SEND MESSAGE
                        </Button>
                      </Tables.Th>
                      <Tables.Th style={styleTrans}>
                        <Button
                          variant="primary"
                          onClick={() =>
                            openModal(
                              actAutoReply,
                              "Auto Reply Whatsapp",
                              false
                            )
                          }
                        >
                          AUTO REPLY
                        </Button>
                      </Tables.Th>
                    </Tables.Tr>
                  </Tables.Body>
                </Table>
              )}
            </Div>
          </Div>
        </Div>
      </Card>
    </LayoutHome>
  );
};

export default Whatsapp;
