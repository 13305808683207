function randNumb() {
  return Math.floor(Math.random() * 1000000);
}
const li = ({ children, ...props }) => {
  const random = randNumb();
  return (
    <li key={random} {...props}>
      {children}
    </li>
  );
};
const ul = ({ children, ...props }) => {
  const random = randNumb();
  return (
    <ul key={random} {...props}>
      {children}
    </ul>
  );
};
const ol = ({ children, ...props }) => {
  const random = randNumb();
  return (
    <ol key={random} {...props}>
      {children}
    </ol>
  );
};

const List = {
  li: li,
  ul: ul,
  ol: ol,
};

export default List;
