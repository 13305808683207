import { useDispatch, useSelector } from "react-redux";
import { Button, Modal } from "react-bootstrap";
import Div from "./Div";
import { actions } from "../store/actions";

const LayoutModal = ({ children, align, onSave }) => {
  if (align === undefined) {
    align = "left";
  }
  const globalReducer = useSelector((state) => state.globalReducer);
  const { loader, accessCRUD } = globalReducer;
  const { modalShow, modalBtnClose, modalTitleHeader } = globalReducer;

  const dispatch = useDispatch();

  function closeModal() {
    actions.global.setModalShow(dispatch, false);
  }
  function btnSave() {
    if (accessCRUD.accCreated || accessCRUD.accUpdate) {
      return (
        <Modal.Footer>
          <Button
            variant="primary"
            disabled={loader}
            onClick={!loader ? onSave : null}
          >
            {loader ? "Loading…" : "Process"}
          </Button>
        </Modal.Footer>
      );
    }
  }
  return (
    <Modal
      show={modalShow}
      onHide={closeModal}
      backdrop="static"
      keyboard={false}
    >
      <Div style={{ padding: "20px" }}>
        <Modal.Header closeButton={modalBtnClose}>
          <Modal.Title>
            <b>{modalTitleHeader}</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ textAlign: align }}>{children}</Modal.Body>
        {btnSave()}
      </Div>
    </Modal>
  );
};

export default LayoutModal;
