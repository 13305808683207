import { access } from "./access";
import { org } from "./org";
import { whatsapp } from "./whatsapp";
import { mikrotik } from "./mikrotik";
import { user } from "./user";
import { setting } from "./setting";
import { message } from "./message";

const master = { access, org, whatsapp, mikrotik, user, setting, message };

export default master;
