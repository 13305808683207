import HotspotSetting from "./HotspotSetting";
import HotspotProfile from "./HotspotProfile";
import HotspotVoucher from "./HotspotVoucher";
import HotspotActive from "./HotspotActive";
import HotspotOnline from "./HotspotOnline";
import HotspotHistory from "./HotspotHistory";
import HotspotTemplate from "./HotspotTemplate";
import PPoEProfile from "./PPoEProfile";
import StaticProfile from "./StaticProfile";

const Service = {
  HotspotSetting,
  HotspotProfile,
  HotspotVoucher,
  HotspotActive,
  HotspotOnline,
  HotspotHistory,
  HotspotTemplate,
  StaticProfile,
  PPoEProfile,
};

export default Service;
