import { actions } from "../../../actions";

const initialState = {
  array1: [],
  array2: [],
  object1: false,
};

const actionType = actions.service.hotspot.setting.actionType;

const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionType.setArray1:
      return {
        ...state,
        array1: payload,
      };
    case actionType.addArray1:
      return {
        ...state,
        array1: [...state.array1, payload],
      };
    case actionType.updateArray1:
      return {
        ...state,
        array1: state.array1.map((item) =>
          item.id === payload.id ? payload : item
        ),
      };
    case actionType.deleteArray1:
      return {
        ...state,
        array1: state.array1.filter((item) => item.id !== payload.id),
      };

    case actionType.setArray2:
      return {
        ...state,
        array2: payload,
      };
    case actionType.addArray2:
      return {
        ...state,
        array2: [...state.array2, payload],
      };
    case actionType.updateArray2:
      return {
        ...state,
        array2: state.array2.map((item) =>
          item.id === payload.id ? payload : item
        ),
      };
    case actionType.deleteArray2:
      return {
        ...state,
        array2: state.array2.filter((item) => item.id !== payload.id),
      };

    case actionType.setObject1:
      return {
        ...state,
        object1: payload,
      };
    case actionType.updateObject1:
      return {
        ...state,
        object1: {
          ...state.object1,
          [Object.keys(payload)[0]]: Object.values(payload)[0],
        },
      };

    default:
      return {
        ...state,
      };
  }
};
export default reducer;
