import {
  createRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from "react";

import Cropper from "react-easy-crop";
import getCroppedImg from "./Crop";
import { Form } from "react-bootstrap";

const EasyCrop = ({
  image,
  rHeight,
  rWidth,
  clickSelect,
  clickCrop,
  clickSave,
}) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const [imageCrop, setImageCrop] = useState(image);

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const childFunctionCrop = useCallback(async () => {
    try {
      const blobImage = await getCroppedImg(
        imageCrop,
        croppedAreaPixels,
        rotation
      );
      clickSave({ status: true, response: blobImage });
    } catch (e) {
      clickSave({ status: false, response: e });
    }
  }, [croppedAreaPixels, rotation, imageCrop]);

  const refFile = createRef();
  const childFunctionSelect = () => {
    refFile.current.click();
  };
  useImperativeHandle(clickSelect, () => ({
    childFunctionSelect,
  }));
  useImperativeHandle(clickCrop, () => ({
    childFunctionCrop,
  }));
  const selectFile = (e) => {
    if (e.target.files[0]) {
      setImageCrop(URL.createObjectURL(e.target.files[0]));
    }
  };
  return (
    <div>
      <input
        type="file"
        accept="image/png, image/jpeg, image/svg+xml"
        style={{ height: "0", overflow: "hidden" }}
        ref={refFile}
        onChange={selectFile}
      />

      <div
        style={{
          paddingBottom: "100px",
          position: "relative",
          border: "1px",
          borderStyle: "solid",
        }}
      >
        <div style={{ minHeight: "300px", minWidth: "300px" }}>
          <Cropper
            image={imageCrop}
            crop={crop}
            rotation={rotation}
            zoom={zoom}
            zoomSpeed={4}
            maxZoom={3}
            zoomWithScroll={true}
            showGrid={true}
            aspect={rHeight / rWidth}
            onCropChange={setCrop}
            onCropComplete={onCropComplete}
            onZoomChange={setZoom}
            onRotationChange={setRotation}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "800px",
            position: "absolute",
          }}
        ></div>
      </div>

      <div>
        <br></br>
        <label>Rotate</label>
        <Form.Range
          value={rotation}
          min={0}
          max={360}
          step={0.1}
          onChange={(e) => setRotation(e.target.value)}
        />
        <label>Zoom</label>
        <Form.Range
          value={zoom}
          min={1}
          max={3}
          step={0.01}
          onChange={(e) => setZoom(e.target.value)}
        />
      </div>
    </div>
  );
};
export default EasyCrop;
