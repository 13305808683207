import LayoutHome from "../../components/Layout/Home";
import Div from "../../components/Div";
import { Alert, Card, Col, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import JWToken from "../../utils/JWToken";
import { service } from "../../store/service";
import { actions } from "../../store/actions";
import Swalert from "../../components/Swalert";
import Icon from "../../components/Icon";
import ReactApexChart from "react-apexcharts";
import Tables from "../../components/Tables";

const Home = () => {
  const dispatch = useDispatch();
  const { listHarga } = useSelector((state) => state.globalReducer);
  const pHomeReducer = useSelector((state) => state.pHomeReducer);
  const { object1, array1, array2 } = pHomeReducer;

  useEffect(() => {
    console.log(pHomeReducer);
  }, [pHomeReducer]);

  const [alert, setAlert] = useState("");
  const [priceList, setPriceList] = useState(false);

  useEffect(() => {
    if (new JWToken()._getStatusAccessToken()) {
      readFirstData();
    }
  }, []);

  async function readFirstData() {
    const result = await service.primary.home._read(dispatch);
    if (result.status) {
      actions.primary.home.setObject1(dispatch, result.data.rekapPendapatan);
      actions.primary.home.setArray1(dispatch, result.data.stokVoucherAdmin);
      actions.primary.home.setArray2(dispatch, result.data.stokVoucherAll);
      setAlert(result.data.statusService);
      setPriceList(result.data.priceList);
      return;
    }

    return new Swalert().error(result.data);
  }

  function setRupiah(value) {
    return new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
      minimumFractionDigits: 0,
    }).format(value);
  }
  const optionsAdmin = {
    xaxis: {
      categories: object1 ? object1.categories : [],
    },
    yaxis: {
      show: false,
      labels: {
        show: true,
        formatter: function (val) {
          return val;
        },
      },
    },
    tooltip: {
      followCursor: true,
      y: {
        formatter: function (val) {
          return setRupiah(val);
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        return setRupiah(val);
      },
      style: {
        fontSize: "12px",
        fontWeight: "bold",
      },
      background: {
        enabled: true,
        foreColor: "#fff",
        borderRadius: 2,
        padding: 4,
        opacity: 0.9,
        borderWidth: 1,
        borderColor: "#9a9cab33",
      },
    },
    chart: {
      height: 380,
      type: "area",
      stacked: false,
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    stroke: {
      curve: "smooth",
    },
    title: {
      text: object1
        ? object1.seller === "Reseller"
          ? "OMSET RESELLER"
          : "REKAP PENDAPATAN"
        : "REKAP PENDAPATAN",
      align: "center",
      style: {
        fontSize: "16px",
        fontWeight: "bold",
        color: "#9a9cab",
      },
    },
    grid: {
      borderColor: "#9a9cab33",
      row: {
        colors: ["#9a9cab33", "transparent"],
        opacity: 0.3,
      },
    },
    fill: {
      type: "gradient",
      gradient: {
        gradientToColors: ["#0000ff"],
        type: "horizontal",
        stops: [0, 100, 100, 100],
      },
    },
    markers: {
      size: 1,
    },
    legend: {
      position: "bottom",
      horizontalAlign: "center",
      floating: false,
    },
  };
  const arr = [
    "widget-stats-icon widget-stats-icon-warning",
    "widget-stats-icon widget-stats-icon-danger",
    "widget-stats-icon widget-stats-icon-success",
    "widget-stats-icon widget-stats-icon-purple",
  ];
  const iconArr = ["new_releases", "severe_cold", "stars", "sunny"];
  function cardVoucherAdmin() {
    return array1.map((item) => {
      const rand = Math.floor(Math.random() * 3);
      const rand2 = Math.floor(Math.random() * 3);
      return (
        <Col md>
          <Card className="widget widget-stats">
            <Div className="widget-stats-container d-flex">
              <Div className={arr[rand]}>
                <Icon className="material-icons-outlined">
                  {iconArr[rand2]}
                </Icon>
              </Div>
              <Div className="widget-stats-content flex-fill">
                <span
                  className="widget-stats-title"
                  style={{ marginTop: "8px" }}
                >
                  {"Voucher " + item.profile}
                </span>
                <span>
                  <small>
                    Stok Voucher : <b>{item.voucher}</b>
                  </small>
                </span>
              </Div>
            </Div>
          </Card>
        </Col>
      );
    });
  }
  function cardVoucherAll() {
    return array2.map((item) => {
      if (item.seller === "Reseller") {
        return;
      }
      if (item.listStok.length <= 0) {
        return;
      }
      const rand = Math.floor(Math.random() * 3);
      const rand2 = Math.floor(Math.random() * 3);
      return (
        <Col md>
          <Card className="widget widget-stats">
            <Div className="widget-stats-container d-flex">
              <Div className={arr[rand]} style={{ margin: "10px" }}>
                <Icon className="material-icons-outlined">
                  {iconArr[rand2]}
                </Icon>
              </Div>
              <Div
                className="widget-stats-content flex-fill"
                style={{ marginLeft: "10px" }}
              >
                <span className="widget-stats-title">
                  {"REKAP ADMIN " + item.name}
                </span>
                <span>
                  <Div>
                    Omset : <b>{setRupiah(item.omset)}</b>
                  </Div>
                  <Div style={{ marginTop: "-5px" }}>
                    Komisi : <b>{setRupiah(item.sharing)}</b>
                  </Div>
                  <Div style={{ marginTop: "-5px" }}>
                    Orgnisasi : <b>{setRupiah(item.omset - item.sharing)}</b>
                  </Div>
                </span>
              </Div>
            </Div>
          </Card>
        </Col>
      );
    });
  }
  function setLayoutTableStaticPrice() {
    if (!priceList) {
      return;
    }
    function dataBody() {
      return listHarga.map((item, index) => {
        return (
          <Tables.Tr>
            <Tables.Td>{index + 1}</Tables.Td>
            <Tables.Td>{item.name}</Tables.Td>
            <Tables.Td>
              <del>{setRupiah(item.cost)}</del>
              <br></br>
              <b style={{ fontSize: "16px" }}>{setRupiah(item.promo)}</b>
            </Tables.Td>
          </Tables.Tr>
        );
      });
    }

    return (
      <>
        <Card>
          <Tables.Table>
            <Tables.Head>
              <Tables.Tr>
                <Tables.Th>#</Tables.Th>
                <Tables.Th>SERVICE</Tables.Th>
                <Tables.Th>COST/DAY</Tables.Th>
              </Tables.Tr>
            </Tables.Head>
            <Tables.Body>{dataBody()}</Tables.Body>
          </Tables.Table>
          <Div style={{ marginTop: "10px" }}>
            {listHarga.map((item, index) => {
              return (
                <>
                  <Card.Body>
                    <Card.Title>{item.name}</Card.Title>
                    <Card.Text>{item.information}</Card.Text>
                  </Card.Body>
                </>
              );
            })}
          </Div>
        </Card>
      </>
    );
  }
  function showTableStokVoucher() {
    return array2.map((item) => {
      if (item.listStok.length <= 0) {
        return;
      }
      function dataBody() {
        return item.listStok.map((stok, index) => {
          return (
            <Tables.Tr>
              <Tables.Td>{index + 1}</Tables.Td>
              <Tables.Td>{stok.seller + " " + stok.name}</Tables.Td>
              {stok.newStok.map((newS) => {
                return <Tables.Td>{newS.stokVoucher}</Tables.Td>;
              })}
            </Tables.Tr>
          );
        });
      }
      return (
        <Card md={4}>
          <Form.Control
            type="text"
            readOnly
            value={"Stok Voucher " + item.name}
          />
          <Tables.Table>
            <Tables.Head>
              <Tables.Tr>
                <Tables.Th>#</Tables.Th>
                <Tables.Th>Seller</Tables.Th>
                {item.listProfile.map((prof) => {
                  return <Tables.Th>{prof.name}</Tables.Th>;
                })}
              </Tables.Tr>
            </Tables.Head>
            <Tables.Body>{dataBody()}</Tables.Body>
          </Tables.Table>
        </Card>
      );
    });
  }
  return (
    <LayoutHome>
      {alert.alert ? <Alert variant={alert.layout}>{alert.data}</Alert> : ""}
      {setLayoutTableStaticPrice()}
      <Row>{cardVoucherAll()}</Row>
      {showTableStokVoucher()}

      <Row>{cardVoucherAdmin()}</Row>
      {object1 ? (
        <Card>
          <ReactApexChart
            options={optionsAdmin}
            series={object1.series}
            type="area"
            height="380"
            stacked={false}
            toolbar={false}
          />
        </Card>
      ) : (
        ""
      )}
    </LayoutHome>
  );
};

export default Home;
