const actionType = {
  setArray1: "actions.service.hotspot.profile.setArray1",
  addArray1: "actions.service.hotspot.profile.addArray1",
  updateArray1: "actions.service.hotspot.profile.updateArray1",
  deleteArray1: "actions.service.hotspot.profile.deleteArray1",

  setArray2: "actions.service.hotspot.profile.setArray2",
  addArray2: "actions.service.hotspot.profile.addArray2",
  updateArray2: "actions.service.hotspot.profile.updateArray2",
  deleteArray2: "actions.service.hotspot.profile.deleteArray2",

  setObject1: "actions.service.hotspot.profile.setObject1",
  updateObject1: "actions.service.hotspot.profile.updateObject1",
};
const setArray1 = async (dispatch, payload) => {
  dispatch({ type: actionType.setArray1, payload: payload });
};
const addArray1 = async (dispatch, payload) => {
  dispatch({ type: actionType.addArray1, payload: payload });
};
const updateArray1 = async (dispatch, payload) => {
  dispatch({ type: actionType.updateArray1, payload: payload });
};
const deleteArray1 = async (dispatch, payload) => {
  dispatch({ type: actionType.deleteArray1, payload: payload });
};

const setArray2 = async (dispatch, payload) => {
  dispatch({ type: actionType.setArray2, payload: payload });
};
const addArray2 = async (dispatch, payload) => {
  dispatch({ type: actionType.addArray2, payload: payload });
};
const updateArray2 = async (dispatch, payload) => {
  dispatch({ type: actionType.updateArray2, payload: payload });
};
const deleteArray2 = async (dispatch, payload) => {
  dispatch({ type: actionType.deleteArray2, payload: payload });
};

const setObject1 = async (dispatch, payload) => {
  dispatch({ type: actionType.setObject1, payload: payload });
};
const updateObject1 = async (dispatch, payload) => {
  dispatch({ type: actionType.updateObject1, payload: payload });
};

export const profile = {
  actionType,
  setArray1,
  addArray1,
  updateArray1,
  deleteArray1,
  setArray2,
  addArray2,
  updateArray2,
  deleteArray2,
  setObject1,
  updateObject1,
};
