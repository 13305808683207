import LayoutHome from "../../components/Layout/Home";
import Div from "../../components/Div";
import {
  Alert,
  ButtonGroup,
  Card,
  Dropdown,
  DropdownButton,
  Form,
  Stack,
  ToggleButton,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import JWToken from "../../utils/JWToken";
import { service } from "../../store/service";
import { actions } from "../../store/actions";
import Swalert from "../../components/Swalert";
import Tables from "../../components/Tables";
import FilterListTable from "../../utils/FilterListTable";
import Icon from "../../components/Icon";
import SelectLabel from "../../components/SelectLabel";
import LayoutModal from "../../components/LayoutModal";

const HotspotActive = () => {
  const dispatch = useDispatch();
  const { modalAction } = useSelector((state) => state.globalReducer);
  const shActiveReducer = useSelector((state) => state.shActiveReducer);
  const { array1, array2, array3, array4, object1 } = shActiveReducer;

  const [listTable, setListTable] = useState(array1);
  const [searchVal, setSearchVal] = useState("");
  const [alert, setAlert] = useState("");

  const [optionServer, setOptionServer] = useState([]);

  useEffect(() => {
    setError(listTable);
  }, [listTable]);

  useEffect(() => {
    setListTable(array1);
  }, [array1]);

  useEffect(() => {
    setListTable(FilterListTable(searchVal, array1));
  }, [searchVal]);

  useEffect(() => {
    if (new JWToken()._getStatusAccessToken()) {
      readFirstData();
    }
  }, []);

  async function readFirstData() {
    const result = await service.layanan.hotspot.active._read(dispatch);
    if (result.status) {
      result.data.listReset.map((item) => {
        item.name = item.voucher;
        for (let index = 0; index < result.data.listProfile.length; index++) {
          const prof = result.data.listProfile[index];
          if (prof.id === item.idProfile) {
            item.profile = prof.name;
            item.idProfile = prof.idProfile;
            item.mikrotik = prof.idParent;
          }
        }
      });

      actions.service.hotspot.active.setArray1(dispatch, result.data.listReset);
      actions.service.hotspot.active.setArray2(
        dispatch,
        result.data.listProfile
      );
      actions.service.hotspot.active.setArray3(dispatch, result.data.listAdmin);
      actions.service.hotspot.active.setArray4(
        dispatch,
        result.data.listMikrotik
      );
      return;
    }

    return new Swalert().error(result.data);
  }

  function setError(listTable) {
    for (let index = 0; index < listTable.length; index++) {
      const element = listTable[index];
      if (element.messageErr !== "") {
        setAlert(
          "error row " +
            (index + 1) +
            " : server pada voucher tidak dikenali di router mikrotik"
        );
        index = listTable.length;
      } else {
        setAlert("");
      }
    }
  }
  async function openModal(item) {
    if (item.messageErr === "") {
      return;
    }
    actions.global.setModalShow(dispatch, true);
    actions.global.setModalTitleHeader(
      dispatch,
      "Update Server " + item.voucher
    );
    const newData = {
      server: "",
      mikrotik: item.mikrotik,
      idRow: item.id,
      idProfile: item.idProfile,
    };
    actions.service.hotspot.active.setObject1(dispatch, newData);
    getListServerVoucher(item.mikrotik);
    return;
  }
  async function getListServerVoucher(idMirotik) {
    const payload = { idMirotik: idMirotik };
    const result =
      await service.layanan.hotspot.active._getListServerHotspotMikrotik(
        dispatch,
        payload
      );
    if (result.status) {
      const newOptSer = [];
      result.data.map((item) => {
        const data = { value: item, label: item };
        newOptSer.push(data);
      });
      return setOptionServer(newOptSer);
    }
    actions.global.setModalShow(dispatch, false);
    return new Swalert().error(result.data);
  }
  const updateData = async (name, value) => {
    const newData = { [name]: value };
    actions.service.hotspot.active.updateObject1(dispatch, newData);
  };

  async function asyncMikrotik(item) {
    const swal = await new Swalert().confirm(
      "Sinkronkan voucher active ke " + item.name + " ?",
      "Yes"
    );
    if (swal) {
      const newArr = [];
      for (let index = 0; index < array1.length; index++) {
        const element = array1[index];
        if (element.mikrotik === item.id) {
          newArr.push(element);
        }
      }

      const first = 1;
      const total = newArr.length;
      serviceSyncVoucher(first, total, item.id);
    }
  }

  async function serviceSyncVoucher(first, total, idMikrotik) {
    actions.global.setLoaderText(dispatch, "process " + first + " to " + total);
    const newData = {
      first: first,
      idMikrotik: idMikrotik,
    };
    const result = await service.layanan.hotspot.active._async(
      dispatch,
      newData
    );
    if (result.status) {
      for (let index = 0; index < result.data.listVoucher.length; index++) {
        const item = result.data.listVoucher[index];
        item.name = item.voucher;
        for (let index = 0; index < array2.length; index++) {
          const prof = array2[index];
          if (prof.id === item.idProfile) {
            item.profile = prof.name;
            item.idProfile = prof.idProfile;
            item.mikrotik = prof.idParent;
          }
        }
        actions.service.hotspot.voucher.updateArray1(dispatch, item);
      }
      if (parseInt(result.data.first) < total) {
        return serviceSyncVoucher(result.data.first, total, idMikrotik);
      } else {
        return new Swalert().success("Sinkron Voucher Success");
      }
    }
    return new Swalert().error(result.data);
  }

  async function resetVoucher(item) {
    const swal = await new Swalert().confirm(
      "Reset MAC " + item.name + "?",
      "Yes"
    );
    if (swal) {
      const result = await service.layanan.hotspot.active._reset(
        dispatch,
        item
      );
      if (result.status) {
        result.data.name = result.data.voucher;
        for (let index = 0; index < array2.length; index++) {
          const prof = array2[index];
          if (prof.id === result.data.idProfile) {
            result.data.profile = prof.name;
            result.data.idProfile = prof.idProfile;
            result.data.mikrotik = prof.idParent;
          }
        }
        actions.service.hotspot.active.updateArray1(dispatch, result.data);
        return new Swalert().success("Reset MAC " + item.name + " Berhasil");
      }
      return new Swalert().error(result.data);
    }
  }
  function setLayoutTable() {
    if (!listTable) {
      return;
    }
    return listTable.map((item, index) => {
      function iconReset() {
        return (
          <ToggleButton
            variant={"outline-warning"}
            style={{ padding: "3px 3px 3px 5px" }}
            onClick={() => resetVoucher(item)}
          >
            <Icon style={{ fontSize: "20px", marginLeft: "0px" }}>
              lock_reset
            </Icon>
          </ToggleButton>
        );
      }
      function admin() {
        return array3.map((adm) => {
          if (adm.id === item.idUser) {
            return adm.name;
          }
        });
      }
      const style =
        item.messageErr !== ""
          ? { cursor: "pointer", color: "red" }
          : { cursor: "default" };
      return (
        <Tables.Tr>
          <Tables.Td>{index + 1}</Tables.Td>
          <Tables.Td>{iconReset()}</Tables.Td>
          {array4.length > 0 ? (
            <Tables.Td style={style}>
              {array4.map((mik) => {
                if (mik.id === item.mikrotik) {
                  return mik.name;
                }
              })}
            </Tables.Td>
          ) : (
            ""
          )}
          {array3.length > 0 ? <Tables.Td>{admin()}</Tables.Td> : ""}
          <Tables.Td style={style} onClick={() => openModal(item)}>
            {item.server}
          </Tables.Td>
          <Tables.Td style={style} onClick={() => openModal(item)}>
            {item.voucher}
          </Tables.Td>
          <Tables.Td style={style} onClick={() => openModal(item)}>
            {item.password}
          </Tables.Td>
          <Tables.Td style={style} onClick={() => openModal(item)}>
            {item.profile}
          </Tables.Td>
          <Tables.Td style={style} onClick={() => openModal(item)}>
            {item.mac}
          </Tables.Td>
          <Tables.Td style={style} onClick={() => openModal(item)}>
            {item.comment}
          </Tables.Td>
        </Tables.Tr>
      );
    });
  }
  async function updateSever() {
    const newData = {
      idRow: object1.idRow,
      mikrotik: object1.mikrotik,
      idProfileMik: object1.idProfile,
      server: object1.server.value,
    };
    const result = await service.layanan.hotspot.active._updateServer(
      dispatch,
      newData
    );
    if (result.status) {
      result.data.map((item) => {
        item.name = item.voucher;
        for (let index = 0; index < array2.length; index++) {
          const prof = array2[index];
          if (prof.id === item.idProfile) {
            item.profile = prof.name;
            item.idProfile = prof.idProfile;
            item.mikrotik = prof.idParent;
          }
        }
      });
      actions.service.hotspot.active.setArray1(dispatch, result.data);
      actions.global.setModalShow(dispatch, false);
      return new Swalert().success("Update Berhasil");
    }
    actions.global.setModalShow(dispatch, false);
    return new Swalert().error(result.data);
  }
  function modalFormRenewServer() {
    return (
      <SelectLabel
        title={"Server"}
        isSearchable={false}
        options={optionServer}
        value={object1.server}
        onChange={(e) => updateData("server", e)}
      />
    );
  }

  return (
    <LayoutHome>
      <LayoutModal onSave={updateSever}>{modalFormRenewServer()}</LayoutModal>
      {alert !== "" ? <Alert variant={"danger"}>{alert}</Alert> : ""}
      <Card>
        <Stack direction="horizontal" style={{ marginBottom: "5px" }} gap={2}>
          <Div>
            {array4.length > 0 ? (
              <>
                <DropdownButton
                  size="sm"
                  variant="outline-success"
                  title="SYNC"
                >
                  {array4.map((item) => {
                    return (
                      <Dropdown.Item onClick={() => asyncMikrotik(item)}>
                        {item.name}
                      </Dropdown.Item>
                    );
                  })}
                </DropdownButton>
                <br></br>
              </>
            ) : (
              ""
            )}

            <ButtonGroup>result : {listTable.length} items</ButtonGroup>
          </Div>
          <Div
            className="ms-auto"
            style={{ alignSelf: "flex-end", width: "120px" }}
          >
            <Form.Control
              type={"text"}
              placeholder={"Cari Voucher . . ."}
              onChange={(e) => setSearchVal(e.target.value)}
            />
          </Div>
        </Stack>
        <Tables.Table>
          <Tables.Head>
            <Tables.Tr>
              <Tables.Th>#</Tables.Th>
              <Tables.Th>RST_MAC</Tables.Th>
              {array4.length > 0 ? <Tables.Th>ROUTER</Tables.Th> : ""}
              {array3.length > 0 ? <Tables.Th>ADMIN</Tables.Th> : ""}
              <Tables.Th>SERVER</Tables.Th>
              <Tables.Th>USERNAME</Tables.Th>
              <Tables.Th>PASSWORD</Tables.Th>
              <Tables.Th>PROFILE</Tables.Th>
              <Tables.Th>MAC</Tables.Th>
              <Tables.Th>AKTIF</Tables.Th>
            </Tables.Tr>
          </Tables.Head>
          <Tables.Body>{setLayoutTable()}</Tables.Body>
        </Tables.Table>
      </Card>
    </LayoutHome>
  );
};

export default HotspotActive;
