import { useDispatch, useSelector } from "react-redux";
import LayoutHome from "../../components/Layout/Home";
import { useEffect, useRef, useState } from "react";
import Div from "../../components/Div";
import {
  Button,
  ButtonGroup,
  Card,
  Form,
  Image,
  Modal,
  Stack,
  Tab,
  Table,
  Tabs,
  ToggleButton,
} from "react-bootstrap";
import { actions } from "../../store/actions";
import Tables from "../../components/Tables";
import { IdnKab, loading, profile } from "../../assets";
import { RWebShare } from "react-web-share";
import Icon from "../../components/Icon";
import { config, storage } from "../../utils/StaticString";
import Swalert from "../../components/Swalert";
import JWToken from "../../utils/JWToken";
import { service } from "../../store/service";
import { PageMenu } from "../../route/PageMenu";
import InputLabel from "../../components/InputLabel";
import SelectLabel from "../../components/SelectLabel";
import PhoneLabel from "../../components/PhoneLabel";
import Span from "../../components/Span";
import EasyCrop from "../../components/Cropped/EasyCrop";
import FilterListTable from "../../utils/FilterListTable";
import LayoutModal from "../../components/LayoutModal";
import TextAreaLabel from "../../components/TextAreaLabel";
import Terbilang from "../../utils/Terbilang";
import UseLocalStorage from "../../utils/UseLocalStorage";
import BootstrapTable from "react-bootstrap-table-next";

const Message = () => {
  const dispatch = useDispatch();
  const { modalAction } = useSelector((state) => state.globalReducer);
  const mMessageReducer = useSelector((state) => state.mMessageReducer);
  const { array1, array2, array3, array4, object1, object2 } = mMessageReducer;

  const [listHarga, setListHarga] = UseLocalStorage(storage.priceList, false);

  useEffect(() => {
    console.log(mMessageReducer);
  }, [mMessageReducer]);

  const [tab, setTab] = useState(1);
  const [formValidate, setFormValidate] = useState(false);
  const [optionMonthYear, setOptionMonthYear] = useState([]);

  const [btnDeleteItem, setBtnDeleteItem] = useState(false);
  const [listTable, setListTable] = useState(array1);
  const [searchVal, setSearchVal] = useState("");

  const actFailed = "actFailed";
  const actSheduler = "actSheduler";
  const actReceived = "actReceived";
  const actSetting = "actSetting";

  useEffect(() => {
    if (new JWToken()._getStatusAccessToken()) {
      getFirstData();
    }
  }, []);

  useEffect(() => {
    setListTable(array1);
  }, [array1]);

  async function getFirstData() {
    const result = await service.master.message._read(dispatch);
    if (result.status) {
      const dateNow = result.data.dateNow;
      const month = result.data.listMessage.month;
      const message = result.data.listMessage.message;

      setBtnDeleteItem(false);
      message.map((item) => {
        item.expand = false;
        if (!item.expired) {
          setBtnDeleteItem(true);
        }
      });

      const selectNow = {
        value: dateNow,
        label: dateNow,
      };

      actions.master.message.setObject1(dispatch, { month: selectNow });
      actions.master.message.setArray1(dispatch, message);
      actions.master.message.setArray2(dispatch, month);
      return;
    }
    return new Swalert().error(result.data);
  }
  async function updateSocket(event, item) {
    if (event.key === "Enter") {
      item.urlSocketWA = event.target.value;
      const result = await service.master.message._updateSocket(dispatch, item);
      if (result.status) {
        actions.master.message.updateArray1(dispatch, item);
        return new Swalert().success("Update Socket Success");
      }
      return new Swalert().warning("Update Socket Failed");
    }
  }

  async function openModal(action, header, item) {
    actions.global.setModalShow(dispatch, true);
    actions.global.setModalAction(dispatch, action);
    actions.global.setModalTitleHeader(dispatch, header);
    actions.master.message.setObject1(dispatch, item);
    return;
  }
  const updateData1 = (name, value) => {
    const newData = { [name]: value };
    actions.master.message.updateObject1(dispatch, newData);
    getMessage(value);
  };
  const getMessage = async (month) => {
    const result = await service.master.message._getMessage(dispatch, month);
    if (result.status) {
      setBtnDeleteItem(false);
      result.data.message.map((item) => {
        item.expand = false;
        if (!item.expired) {
          setBtnDeleteItem(true);
        }
      });
      actions.master.message.setArray1(dispatch, result.data.message);
      return;
    }

    return new Swalert().error(result.data);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      submitHandler();
    }
  };
  const submitHandler = async () => {};

  function setLayoutMessage() {
    function dataBody() {
      return array1.map((item, index) => {
        function textStatus() {
          return (
            <>
              {item.status === "jadwal"
                ? item.expired
                  ? "Expired scheduler"
                  : "Schedule"
                : item.status === "terkirim"
                ? "Message sent"
                : item.expired
                ? "Message failed & Expired Scheduler"
                : "Message failed"}
            </>
          );
        }
        function textKeterangan() {
          return (
            <>
              {item.status === "jadwal"
                ? item.expired
                  ? item.dateDayJadwal
                  : item.jadwal
                : item.status === "terkirim"
                ? item.dateSend
                : item.respon}
            </>
          );
        }
        function colorScheduler(value) {
          if (item.status === "jadwal") {
            if (!item.expired) {
              return <Div className={"text-primary"}>{value}</Div>;
            } else {
              return <Div className={"text-danger"}>{value}</Div>;
            }
          } else if (item.status === "terkirim") {
            return <Div className={"text-success"}>{value}</Div>;
          } else {
            return <Div className={"text-danger"}>{value}</Div>;
          }
        }
        function expand() {
          item.expand = !item.expand;
          actions.master.message.updateArray1(dispatch, item);
        }
        return (
          <>
            <Tables.Tr style={{ cursor: "pointer" }} onClick={expand}>
              <Tables.Td>{index + 1}</Tables.Td>
              <Tables.Td>{item.nama}</Tables.Td>
              <Tables.Td>{item.penerima}</Tables.Td>
              <Tables.Td>{colorScheduler(textStatus())}</Tables.Td>
              <Tables.Td>{colorScheduler(textKeterangan())}</Tables.Td>
              {!item.expired ? (
                item.status === "jadwal" ? (
                  <Tables.Td>
                    <ToggleButton
                      variant={"outline-danger"}
                      style={{ padding: "3px 3px 3px 5px" }}
                    >
                      <Icon style={{ fontSize: "20px", marginLeft: "0px" }}>
                        delete
                      </Icon>
                    </ToggleButton>
                  </Tables.Td>
                ) : (
                  <Tables.Td>
                    <ToggleButton
                      disabled={true}
                      variant={"outline-danger"}
                      style={{ padding: "3px 3px 3px 5px" }}
                    >
                      <Icon style={{ fontSize: "20px", marginLeft: "0px" }}>
                        delete
                      </Icon>
                    </ToggleButton>
                  </Tables.Td>
                )
              ) : (
                <Tables.Td>
                  <ToggleButton
                    disabled={true}
                    variant={"outline-danger"}
                    style={{ padding: "3px 3px 3px 5px" }}
                  >
                    <Icon style={{ fontSize: "20px", marginLeft: "0px" }}>
                      delete
                    </Icon>
                  </ToggleButton>
                </Tables.Td>
              )}
            </Tables.Tr>
            {item.expand ? (
              <Tables.Tr>
                <Tables.Td></Tables.Td>
                <Tables.Td
                  colspan={btnDeleteItem ? 5 : 4}
                  style={{ textAlign: "left" }}
                >
                  {item.message.split("\n").map(function (dat, idx) {
                    return (
                      <span key={idx}>
                        {dat}
                        <br />
                      </span>
                    );
                  })}
                </Tables.Td>
              </Tables.Tr>
            ) : (
              ""
            )}
          </>
        );
      });
    }
    return (
      <>
        <Tables.Table>
          <Tables.Head>
            <Tables.Tr>
              <Tables.Th>#</Tables.Th>
              <Tables.Th>NAMA</Tables.Th>
              <Tables.Th>PENERIMA</Tables.Th>
              <Tables.Th>STATUS</Tables.Th>
              <Tables.Th>KETERANGAN</Tables.Th>
              <Tables.Th>AKSI</Tables.Th>
            </Tables.Tr>
          </Tables.Head>
          <Tables.Body>{dataBody()}</Tables.Body>
        </Tables.Table>
      </>
    );
  }
  function setRupiah(value) {
    return new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
      minimumFractionDigits: 0,
    }).format(value);
  }

  const styleLeft = {
    textAlign: "left",
    paddingTop: "0px",
    paddingBottom: "0px",
  };
  const styleRight = {
    textAlign: "right",
    paddingTop: "0px",
    paddingBottom: "0px",
  };
  return (
    <LayoutHome>
      <LayoutModal onSave={submitHandler}></LayoutModal>
      <Card style={{ marginBottom: "20px" }}>
        <Stack direction="horizontal" style={{ margin: "10px" }} gap={2}>
          <Div>
            <ButtonGroup>
              <ToggleButton
                variant={"outline-warning"}
                style={{ padding: "3px 3px 3px 5px" }}
                onClick={() => setTab(1)}
              >
                {" MESSAGE "}
              </ToggleButton>
              <ToggleButton
                variant="outline-primary"
                size="sm"
                onClick={() => setTab(2)}
              >
                {" TEMPLATE "}
              </ToggleButton>
            </ButtonGroup>
          </Div>
          <Div
            className="ms-auto"
            style={{ alignSelf: "flex-end", width: "120px" }}
          >
            {tab === 1 ? (
              <Form.Control
                type={"text"}
                placeholder={"Cari Nama . . ."}
                onChange={(e) => setSearchVal(e.target.value)}
              />
            ) : (
              ""
            )}
          </Div>
        </Stack>
        {tab === 1 ? (
          <>
            <SelectLabel
              style={{ marginBottom: "-20px" }}
              isSearchable={true}
              options={array2}
              value={object1.month}
              onChange={(e) => updateData1("month", e)}
            />
            {setLayoutMessage()}
          </>
        ) : (
          ""
        )}
      </Card>
    </LayoutHome>
  );
};

export default Message;
