import { useDispatch, useSelector } from "react-redux";
import LayoutHome from "../../components/Layout/Home";
import { useEffect, useRef, useState } from "react";
import Div from "../../components/Div";
import {
  Button,
  ButtonGroup,
  Card,
  Form,
  Image,
  Modal,
  Stack,
  Tab,
  Table,
  Tabs,
  ToggleButton,
} from "react-bootstrap";
import { actions } from "../../store/actions";
import Tables from "../../components/Tables";
import { IdnKab, loading, profile } from "../../assets";
import { RWebShare } from "react-web-share";
import Icon from "../../components/Icon";
import { config, storage } from "../../utils/StaticString";
import Swalert from "../../components/Swalert";
import JWToken from "../../utils/JWToken";
import { service } from "../../store/service";
import { PageMenu } from "../../route/PageMenu";
import InputLabel from "../../components/InputLabel";
import SelectLabel from "../../components/SelectLabel";
import PhoneLabel from "../../components/PhoneLabel";
import Span from "../../components/Span";
import EasyCrop from "../../components/Cropped/EasyCrop";
import FilterListTable from "../../utils/FilterListTable";
import LayoutModal from "../../components/LayoutModal";
import TextAreaLabel from "../../components/TextAreaLabel";
import Terbilang from "../../utils/Terbilang";
import UseLocalStorage from "../../utils/UseLocalStorage";

const Setting = () => {
  const dispatch = useDispatch();
  const { modalAction } = useSelector((state) => state.globalReducer);
  const mSettingReducer = useSelector((state) => state.mSettingReducer);
  const { array1, array2, array3, array4, object1, object2 } = mSettingReducer;

  const [listHarga, setListHarga] = UseLocalStorage(storage.priceList, false);

  const [tab, setTab] = useState(1);
  const [formValidate, setFormValidate] = useState(false);
  const [optionMonthYear, setOptionMonthYear] = useState([]);

  const actUpdateSocketAll = "actUpdateSocketAll";
  const actUpdateStaicPrice = "actUpdateStaicPrice";
  const actTopupKoin = "actTopupKoin";
  const actMutasiKoin = "actMutasiKoin";

  useEffect(() => {
    if (new JWToken()._getStatusAccessToken()) {
      getFirstData();
    }
  }, []);

  async function getFirstData() {
    const result = await service.master.setting._read(dispatch);
    if (result.status) {
      result.data.listOrg.map((item) => {
        item.value = item.id;
        item.label = item.orgName;
      });

      actions.master.setting.setArray1(dispatch, result.data.saldo);
      actions.master.setting.setArray2(dispatch, result.data.listOrg);
      actions.master.setting.setArray3(dispatch, result.data.mikrotik);
      return;
    }

    return new Swalert().error(result.data);
  }
  async function updateSocket(event, item) {
    if (event.key === "Enter") {
      item.urlSocketWA = event.target.value;
      const result = await service.master.setting._updateSocket(dispatch, item);
      if (result.status) {
        actions.master.setting.updateArray1(dispatch, item);
        return new Swalert().success("Update Socket Success");
      }
      return new Swalert().warning("Update Socket Failed");
    }
  }
  async function updateSocketAll() {
    if (object1.urlSocketWA === "") {
      return setFormValidate(true);
    }
    const payload = { urlSocketWA: object1.urlSocketWA };
    const result = await service.master.setting._updateSocketAll(
      dispatch,
      payload
    );
    if (result.status) {
      actions.global.setModalShow(dispatch, false);
      actions.master.setting.setArray1(dispatch, result.data);
      return new Swalert().success("Update Socket Success");
    }
    return new Swalert().warning("Update Socket Failed");
  }
  async function updateStaticPrice() {
    if (parseInt(object1.price) < 0) {
      return setFormValidate(true);
    }
    if (parseInt(object1.promo) < 0) {
      return setFormValidate(true);
    }
    const result = await service.master.setting._updateStaticPrice(
      dispatch,
      object1
    );
    if (result.status) {
      setListHarga(result.data);
      actions.global.setModalShow(dispatch, false);
      return new Swalert().success("Update Success");
    }
    return new Swalert().warning("Update Failed");
  }
  async function topupCoinOrg() {
    if (!object1.org) {
      return new Swalert().warning("Select Organization");
    }
    if (parseInt(object1.nominal) <= 0) {
      return setFormValidate(true);
    }
    const newData = {
      orgId: object1.org.id,
      nominal: object1.nominal,
    };
    const result = await service.master.setting._topupCoin(dispatch, newData);
    if (result.status) {
      actions.global.setModalShow(dispatch, false);
      actions.master.setting.setArray4(dispatch, result.data);
      return new Swalert().success("Topup Success");
    }
    return new Swalert().warning("Topup Failed");
  }
  async function mutasiCoinOrg() {
    if (!object2.org) {
      return new Swalert().warning("Select Organization");
    }
    if (object2.month === "") {
      return new Swalert().warning("Select Month");
    }
    const newData = {
      orgId: object2.org.id,
      month: object2.month.value,
    };
    const result = await service.master.setting._mutasiCoin(dispatch, newData);
    if (result.status) {
      actions.global.setModalShow(dispatch, false);
      actions.master.setting.setArray4(dispatch, result.data);
      return;
    }
    return new Swalert().warning("Gte Data Failed");
  }

  async function openModal(action, header, item) {
    actions.global.setModalShow(dispatch, true);
    actions.global.setModalAction(dispatch, action);
    actions.global.setModalTitleHeader(dispatch, header);
    actions.master.setting.setObject1(dispatch, item);
    return;
  }
  const updateData = (name, value) => {
    setFormValidate(false);
    const newData = { [name]: value };
    actions.master.setting.updateObject1(dispatch, newData);
  };
  const updateData2 = async (name, value) => {
    setFormValidate(false);
    const newData = { [name]: value };
    actions.master.setting.updateObject2(dispatch, newData);

    if (name === "org") {
      updateData2("month", { value: "", label: "" });
      const result = await service.master.setting._getMonth(dispatch, {
        orgId: value.id,
      });
      if (result.status) {
        result.data.map((item) => {
          item.value = item.monthYear;
          item.label = item.monthYear;
        });
        setOptionMonthYear(result.data);
      }
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      submitHandler();
    }
  };
  const submitHandler = async () => {
    if (modalAction === actUpdateSocketAll) {
      updateSocketAll();
    }
    if (modalAction === actUpdateStaicPrice) {
      updateStaticPrice();
    }
    if (modalAction === actTopupKoin) {
      topupCoinOrg();
    }
    if (modalAction === actMutasiKoin) {
      mutasiCoinOrg();
    }
  };

  function modalFormSocketWA() {
    return (
      <Form noValidate validated={formValidate}>
        <InputLabel
          title="url Socket WA"
          name={"urlSocketWA"}
          required
          value={object1.urlSocketWA}
          onChange={(e) => updateData(e.target.name, e.target.value)}
          onKeyDown={handleKeyDown}
        />
      </Form>
    );
  }
  function modalFormStaticPrice() {
    return (
      <Form noValidate validated={formValidate}>
        <InputLabel
          title="Promo"
          name={"promo"}
          type={"number"}
          min={0}
          validated
          value={object1.promo}
          notify={setRupiah(object1.promo * 30) + " / 30 day"}
          onChange={(e) => updateData(e.target.name, e.target.value)}
          onKeyDown={handleKeyDown}
        />
        <InputLabel
          title="Price"
          name={"cost"}
          type={"number"}
          min={0}
          validated
          value={object1.cost}
          notify={setRupiah(object1.cost * 30) + " / 30 day"}
          onChange={(e) => updateData(e.target.name, e.target.value)}
          onKeyDown={handleKeyDown}
        />
        <TextAreaLabel
          title="Information"
          name={"information"}
          validated
          rows={5}
          value={object1.information}
          onChange={(e) => updateData(e.target.name, e.target.value)}
        />
      </Form>
    );
  }
  function modalFormTopupCoin() {
    return (
      <Form noValidate validated={formValidate}>
        <SelectLabel
          title={"Select Organization"}
          options={array2}
          value={object1.org}
          onChange={(e) => updateData("org", e)}
        />
        <InputLabel
          title="Nominal Topup"
          name={"nominal"}
          type={"number"}
          min={1}
          required
          notify={Terbilang(object1.nominal) + " Rupiah"}
          value={object1.nominal}
          onChange={(e) => updateData(e.target.name, e.target.value)}
          onKeyDown={handleKeyDown}
        />
      </Form>
    );
  }
  function modalFormMutasiCoin() {
    return (
      <Form noValidate validated={formValidate}>
        <SelectLabel
          title={"Select Organization"}
          isSearchable={true}
          options={array2}
          value={object2.org}
          onChange={(e) => updateData2("org", e)}
        />
        <SelectLabel
          title={"Select Month"}
          isSearchable={true}
          options={optionMonthYear}
          value={object2.month}
          onChange={(e) => updateData2("month", e)}
        />
      </Form>
    );
  }

  function setLayoutTableSocket() {
    var totalLayanan = 0;
    var totalSaldo = 0;
    function dataBody() {
      return array2.map((item, index) => {
        function saldo() {
          for (let index = 0; index < array1.length; index++) {
            const el = array1[index];
            if (el.id === item.id) {
              totalSaldo += el.cost;
              return el.cost;
            }
          }
          return "0";
        }
        function layanan() {
          let sum = 0;
          item.service.map((ser) => {
            sum += parseInt(ser.promo.substring(1));
          });
          totalLayanan += sum;
          return sum;
        }
        return (
          <Tables.Tr style={{ cursor: "pointer" }}>
            <Tables.Td>{index + 1}</Tables.Td>
            <Tables.Td style={{ cursor: "default" }}>{item.orgName}</Tables.Td>
            <Tables.Td style={{ cursor: "default" }}>
              {setRupiah(layanan())}
            </Tables.Td>
            <Tables.Td style={{ cursor: "default" }}>
              {setRupiah(saldo())}
            </Tables.Td>
            <Tables.Td style={{ cursor: "default" }}>
              <Form.Control
                type="text"
                defaultValue={item.urlSocketWA}
                onKeyDown={(event) => updateSocket(event, item)}
              />
            </Tables.Td>
          </Tables.Tr>
        );
      });
    }
    return (
      <Tables.Table>
        <Tables.Head>
          <Tables.Tr>
            <Tables.Th>#</Tables.Th>
            <Tables.Th>NAME</Tables.Th>
            <Tables.Th>LAYANAN</Tables.Th>
            <Tables.Th>SALDO</Tables.Th>
            <Tables.Th>SOCKET WA</Tables.Th>
          </Tables.Tr>
        </Tables.Head>
        <Tables.Body>{dataBody()}</Tables.Body>
        <Tables.Footer>
          <Tables.Th colspan={2}>TOTAL</Tables.Th>
          <Tables.Th>{setRupiah(totalLayanan)}</Tables.Th>
          <Tables.Th>{setRupiah(totalSaldo)}</Tables.Th>
          <Tables.Th></Tables.Th>
        </Tables.Footer>
      </Tables.Table>
    );
  }
  function setLayoutTableStaticPrice() {
    function dataBody() {
      return listHarga.map((item, index) => {
        return (
          <Tables.Tr style={{ cursor: "pointer" }}>
            <Tables.Td>{index + 1}</Tables.Td>
            <Tables.Td
              onClick={() =>
                openModal(actUpdateStaicPrice, "Update " + item.name, item)
              }
            >
              {item.name}
            </Tables.Td>
            <Tables.Td
              onClick={() =>
                openModal(actUpdateStaicPrice, "Update " + item.name, item)
              }
            >
              {setRupiah(item.promo)}
            </Tables.Td>
            <Tables.Td
              onClick={() =>
                openModal(actUpdateStaicPrice, "Update " + item.name, item)
              }
            >
              {setRupiah(item.cost)}
            </Tables.Td>
            <Tables.Td
              onClick={() =>
                openModal(actUpdateStaicPrice, "Update " + item.name, item)
              }
            >
              <Form.Control as="textarea" readOnly value={item.information} />
            </Tables.Td>
          </Tables.Tr>
        );
      });
    }

    return (
      <Tables.Table>
        <Tables.Head>
          <Tables.Tr>
            <Tables.Th>#</Tables.Th>
            <Tables.Th>SERVICE</Tables.Th>
            <Tables.Th>PROMO/DAY</Tables.Th>
            <Tables.Th>PRICE/DAY</Tables.Th>
            <Tables.Th>INFORMATION</Tables.Th>
          </Tables.Tr>
        </Tables.Head>
        <Tables.Body>{dataBody()}</Tables.Body>
      </Tables.Table>
    );
  }
  function setLayoutTableMutasi() {
    function dataBodyMutasi() {
      if (!array4.mutasi) {
        return;
      }
      var sum = parseInt(array4.saldoAwal);
      return array4.mutasi.map((item, index) => {
        var debet = "";
        var kredit = "";
        sum += parseInt(item.cost);
        if (parseInt(item.cost) > 0) {
          debet = setRupiah(item.cost);
        } else {
          kredit = setRupiah(item.cost);
        }
        return (
          <Tables.Tr style={{ cursor: "pointer" }}>
            <Tables.Td>{index + 2}</Tables.Td>
            <Tables.Td>{item.dateTime}</Tables.Td>
            <Tables.Td>{item.invoice}</Tables.Td>
            <Tables.Td>{item.information}</Tables.Td>
            <Tables.Td>{debet}</Tables.Td>
            <Tables.Td>{kredit}</Tables.Td>
            <Tables.Td>{setRupiah(sum)}</Tables.Td>
          </Tables.Tr>
        );
      });
    }
    return (
      <Tables.Table>
        <Tables.Head>
          <Tables.Tr>
            <Tables.Th>#</Tables.Th>
            <Tables.Th>DATE</Tables.Th>
            <Tables.Th>INVOICE</Tables.Th>
            <Tables.Th>KETERANGAN</Tables.Th>
            <Tables.Th>DEBET</Tables.Th>
            <Tables.Th>KREDIT</Tables.Th>
            <Tables.Th>SALDO</Tables.Th>
          </Tables.Tr>
        </Tables.Head>
        <Tables.Body>
          {array4.saldoAwal ? (
            <Tables.Tr style={{ cursor: "pointer" }}>
              <Tables.Td>1</Tables.Td>
              <Tables.Td colspan={5}>Saldo Awal</Tables.Td>
              <Tables.Td>{setRupiah(array4.saldoAwal)}</Tables.Td>
            </Tables.Tr>
          ) : (
            ""
          )}
          {dataBodyMutasi()}
        </Tables.Body>
      </Tables.Table>
    );
  }
  function setRupiah(value) {
    return new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
      minimumFractionDigits: 0,
    }).format(value);
  }

  return (
    <LayoutHome>
      <LayoutModal onSave={submitHandler}>
        {modalAction === actUpdateSocketAll ? modalFormSocketWA() : ""}
        {modalAction === actUpdateStaicPrice ? modalFormStaticPrice() : ""}
        {modalAction === actTopupKoin ? modalFormTopupCoin() : ""}
        {modalAction === actMutasiKoin ? modalFormMutasiCoin() : ""}
      </LayoutModal>
      <Card>
        <Div style={{ margin: "5px" }}>
          <ButtonGroup>
            <ToggleButton
              variant="outline-primary"
              size="sm"
              onClick={() => setTab(1)}
            >
              SOCKET WA
            </ToggleButton>
            <ToggleButton
              variant="outline-warning"
              size="sm"
              onClick={() => setTab(4)}
            >
              COIN ORG
            </ToggleButton>
            <ToggleButton
              variant="outline-danger"
              size="sm"
              onClick={() => setTab(2)}
            >
              STATIC PRICE
            </ToggleButton>
          </ButtonGroup>
          <Div
            style={{
              marginTop: "10px",
              marginBottom: "10px",
              border: "1px solid ",
            }}
          ></Div>
          {tab === 1 ? (
            <>
              <ButtonGroup style={{ marginBottom: "10px", marginTop: "10px" }}>
                <ToggleButton
                  variant="outline-primary"
                  size="sm"
                  onClick={() =>
                    openModal(actUpdateSocketAll, "Created User", {
                      urlSocketWA: "",
                    })
                  }
                >
                  UPDATE ALL
                </ToggleButton>
              </ButtonGroup>
              {setLayoutTableSocket()}
            </>
          ) : (
            ""
          )}
          {tab === 2 ? <> {setLayoutTableStaticPrice()}</> : ""}
          {tab === 4 ? (
            <>
              <ButtonGroup style={{ marginBottom: "10px", marginTop: "10px" }}>
                <ToggleButton
                  variant="outline-primary"
                  size="sm"
                  onClick={() =>
                    openModal(actTopupKoin, "TopUp Coin", { nominal: 0 })
                  }
                >
                  TOP UP COIN
                </ToggleButton>
                <ToggleButton
                  variant="outline-success"
                  size="sm"
                  onClick={() => openModal(actMutasiKoin, "Mutasi Coin", false)}
                >
                  MUTASI COIN
                </ToggleButton>
              </ButtonGroup>
              {setLayoutTableMutasi()}
            </>
          ) : (
            ""
          )}
        </Div>
      </Card>
    </LayoutHome>
  );
};

export default Setting;
