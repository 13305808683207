import { useDispatch, useSelector } from "react-redux";
import LayoutHome from "../../components/Layout/Home";
import { useEffect, useRef, useState } from "react";
import Div from "../../components/Div";
import {
  Button,
  ButtonGroup,
  Form,
  Image,
  Modal,
  Table,
  ToggleButton,
} from "react-bootstrap";
import { actions } from "../../store/actions";
import Tables from "../../components/Tables";
import { IdnKab, loading, profile } from "../../assets";
import { RWebShare } from "react-web-share";
import Icon from "../../components/Icon";
import { config } from "../../utils/StaticString";
import Swalert from "../../components/Swalert";
import JWToken from "../../utils/JWToken";
import { service } from "../../store/service";
import { PageMenu } from "../../route/PageMenu";
import InputLabel from "../../components/InputLabel";
import SelectLabel from "../../components/SelectLabel";
import PhoneLabel from "../../components/PhoneLabel";
import Span from "../../components/Span";
import EasyCrop from "../../components/Cropped/EasyCrop";

const PPoEProfile = () => {
  const dispatch = useDispatch();
  const globalReducer = useSelector((state) => state.globalReducer);
  const { formValidate, modalAction, modalClose } = globalReducer;
  const { modalShow, modalHeader, modalData } = globalReducer;
  const { accessMenu, loader } = globalReducer;

  const reducer = useSelector((state) => state.masterMikrotikReducer);
  const { listDataTable } = reducer;

  const actCreated = "actCreated";
  const actUpdate = "actUpdate";

  const [listTable, setListTable] = useState(listDataTable);
  const [searchVal, setSearchVal] = useState("");

  useEffect(() => {
    if (new JWToken()._getStatusAccessToken()) {
      getFirtsData();
    }
  }, []);

  useEffect(() => {
    console.log(modalData);
  }, [modalData]);
  useEffect(() => {
    setListTable(listDataTable);
  }, [listDataTable]);

  useEffect(() => {
    handleSearch();
  }, [searchVal]);

  function handleSearch() {
    if (searchVal === "") {
      setListTable(listDataTable);
      return;
    }
    const filterBySearch = listDataTable.filter((item) => {
      if (item.name.toLowerCase().includes(searchVal.toLowerCase())) {
        return item;
      }
    });
    setListTable(filterBySearch);
  }
  async function setModal(action, header, status, item) {
    if (item) {
      item.pass = atob(item.pass);
    }
    actions.global.setModalAction(dispatch, action);
    actions.global.setModalShow(dispatch, status);
    actions.global.setModalHeader(dispatch, header);
    actions.global.setModalData(dispatch, item);
  }
  const getFirtsData = async () => {
    const result = await service.master.mikrotik._read(dispatch);
    if (result.status) {
      return actions.master.mikrotik.setList(dispatch, result.data);
    }
    return new Swalert().error(result.data);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      submitHandler();
    }
  };
  const submitHandler = async () => {
    if (modalAction === actCreated) {
      createdNewData();
    }
    if (modalAction === actUpdate) {
      updateDataItem();
    }
  };
  async function createdNewData() {
    if (
      modalData.host === "" ||
      modalData.name === "" ||
      modalData.port === "" ||
      modalData.user === ""
    ) {
      return actions.global.setFormValidate(dispatch, true);
    }
    modalData.pass = btoa(modalData.pass);
    const result = await service.master.mikrotik._created(dispatch, modalData);
    if (result.status) {
      setModal(false, false, false, false);
      actions.master.mikrotik.addList(dispatch, result.data);
      return new Swalert().success("Tambah Data Success");
    }
    return new Swalert().error(result.data);
  }
  async function updateDataItem() {
    if (
      modalData.host === "" ||
      modalData.name === "" ||
      modalData.port === "" ||
      modalData.user === ""
    ) {
      return actions.global.setFormValidate(dispatch, true);
    }
    modalData.pass = btoa(modalData.pass);
    const result = await service.master.mikrotik._update(dispatch, modalData);
    if (result.status) {
      setModal(false, false, false, false);
      actions.master.mikrotik.updateList(dispatch, result.data);
      return new Swalert().success("Update Data Success");
    }
    return new Swalert().error(result.data);
  }
  async function deleteItem(item) {
    const swal = await new Swalert().confirm("Delete User " + item.name, "Yes");
    if (swal) {
      const result = await service.master.mikrotik._delete(dispatch, item);
      if (result.status) {
        setModal(false, false, false, false);
        actions.master.mikrotik.deleteList(dispatch, item);
        return new Swalert().success("Delete " + item.name + " Berhasil");
      }
      return new Swalert().error(result.data);
    }
  }

  async function pingItem(item) {
    const swal = await new Swalert().confirm("Ping " + item.name + " ?", "Yes");
    if (swal) {
      const result = await service.master.mikrotik._pingMokrotik(
        dispatch,
        item
      );
      if (result.status) {
        if (result.data) {
          return new Swalert().success("Mikrotik " + item.name + " Terhubung");
        } else {
          return new Swalert().warning(
            "Mikrotik " + item.name + " Tidak Dapat Terhubung"
          );
        }
      }
      return new Swalert().error(result.data);
    }
  }

  const setModalData = (name, value) => {
    actions.global.setFormValidate(dispatch, false);
    const newData = { ...modalData, [name]: value };
    actions.global.setModalData(dispatch, newData);
  };
  function modalFormDetail() {
    return (
      <Form noValidate validated={formValidate}>
        <InputLabel
          title="Nama"
          name={"name"}
          defaultValue={modalData.name}
          required
          onChange={(e) => setModalData(e.target.name, e.target.value)}
          onKeyDown={handleKeyDown}
        />
        <InputLabel
          title="Host"
          name={"host"}
          defaultValue={modalData.host}
          required
          onChange={(e) => setModalData(e.target.name, e.target.value)}
          onKeyDown={handleKeyDown}
        />
        <InputLabel
          title="Port"
          type={"number"}
          name={"port"}
          defaultValue={modalData.port}
          required
          onChange={(e) => setModalData(e.target.name, e.target.value)}
          onKeyDown={handleKeyDown}
        />
        <InputLabel
          title="User"
          name={"user"}
          defaultValue={modalData.user}
          required
          onChange={(e) => setModalData(e.target.name, e.target.value)}
          onKeyDown={handleKeyDown}
        />
        <InputLabel
          title="Passsword"
          type={"password"}
          name={"pass"}
          defaultValue={modalData.pass}
          required
          onChange={(e) => setModalData(e.target.name, e.target.value)}
          onKeyDown={handleKeyDown}
        />
      </Form>
    );
  }
  function setLayoutModal() {
    return (
      <Modal
        show={modalShow}
        onHide={() => setModal(false, false, false, false)}
        backdrop="static"
        keyboard={false}
      >
        <Div style={{ padding: "20px" }}>
          <Modal.Header closeButton={modalClose}>
            <Modal.Title>
              <b>{modalHeader}</b>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {loader ? (
              <Div style={{ textAlign: "center" }}>
                <Image
                  src={loading}
                  alt="img"
                  style={{
                    width: "37px",
                    borderRadius: "5px",
                    margin: "80px",
                  }}
                />
              </Div>
            ) : (
              modalFormDetail()
            )}
          </Modal.Body>
          <Modal.Footer>
            {loader ? (
              ""
            ) : (
              <Button variant="outline-primary" onClick={submitHandler}>
                Simpan
              </Button>
            )}
          </Modal.Footer>
        </Div>
      </Modal>
    );
  }
  function setLayoutTable() {
    function pingDataItem(item) {
      return (
        <ButtonGroup>
          <ToggleButton
            variant={"outline-success"}
            style={{ padding: "3px 3px 3px 5px" }}
            onClick={() => pingItem(item)}
          >
            <Icon style={{ fontSize: "20px", marginLeft: "0px" }}>
              network_ping
            </Icon>
          </ToggleButton>
        </ButtonGroup>
      );
    }
    function deleteDataItem(item) {
      return (
        <ButtonGroup>
          <ToggleButton
            variant={"outline-danger"}
            style={{ padding: "3px 3px 3px 5px" }}
            onClick={() => deleteItem(item)}
          >
            <Icon style={{ fontSize: "20px", marginLeft: "0px" }}>delete</Icon>
          </ToggleButton>
        </ButtonGroup>
      );
    }
    function capitalizeFirstLetter(str) {
      const arr = str.split(" ");
      for (var i = 0; i < arr.length; i++) {
        arr[i] =
          arr[i].slice(0, 1).toUpperCase() + arr[i].slice(1).toLowerCase();
      }
      const str2 = arr.join(" ");
      return str2;
    }
    function openModal(item) {
      setModal(actUpdate, "Update User", true, item);
    }

    function dataBody() {
      if (!listTable) {
        return;
      }
      return listTable.map((item, index) => {
        return (
          <Tables.Tr style={{ cursor: "pointer" }}>
            <Tables.Td>{index + 1}</Tables.Td>
            <Tables.Td style={{ cursor: "default" }}>
              {pingDataItem(item)}
            </Tables.Td>
            <Tables.Td onClick={() => openModal(item)}>{item.name}</Tables.Td>
            <Tables.Td onClick={() => openModal(item)}>
              {capitalizeFirstLetter(item.name)}
            </Tables.Td>
            <Tables.Td onClick={() => openModal(item)}>{item.port}</Tables.Td>
            <Tables.Td style={{ cursor: "default" }}>
              {deleteDataItem(item)}
            </Tables.Td>
          </Tables.Tr>
        );
      });
    }

    return (
      <Tables.Table>
        <Tables.Head>
          <Tables.Tr>
            <Tables.Th>#</Tables.Th>
            <Tables.Th>PING</Tables.Th>
            <Tables.Th>NAMA</Tables.Th>
            <Tables.Th>HOST</Tables.Th>
            <Tables.Th>PORT</Tables.Th>
            <Tables.Th>ACTION</Tables.Th>
          </Tables.Tr>
        </Tables.Head>
        <Tables.Body>{dataBody()}</Tables.Body>
      </Tables.Table>
    );
  }
  return (
    <LayoutHome>
      <Div>
        <Div style={{ margin: "10px", overflow: "auto" }}>
          <Button
            variant="outline-primary"
            size="sm"
            onClick={() =>
              setModal(actCreated, "Created User", true, {
                orgId: "",
                name: "",
                host: "",
                port: "0",
                user: "",
                pass: "",
              })
            }
          >
            TAMBAH MIKROTIK
          </Button>
          <Div
            style={{
              float: "right",
              overflow: "auto",
            }}
          >
            <Form.Control
              type={"text"}
              placeholder={"Cari nama . . ."}
              onChange={(e) => setSearchVal(e.target.value)}
            />
          </Div>
        </Div>
      </Div>
      {setLayoutTable()}
      {setLayoutModal()}
    </LayoutHome>
  );
};

export default PPoEProfile;
