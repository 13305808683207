import { useState } from "react";
import { storage } from "./../utils/StaticString";
import UseLocalStorage from "./../utils/UseLocalStorage";

import { Form, Toast } from "react-bootstrap";
import Select from "react-select";
import Icon from "./Icon";

const SelectLabel = ({ title, options, toast, ...prop }) => {
  const [theme] = UseLocalStorage(storage.themes, "light_mode");
  const [showToast, setShowToast] = useState(false);

  if (options === undefined) options = [];
  if (toast === undefined) {
    toast = false;
  }

  var styleCustom = "";
  if (theme === "dark_mode") {
    styleCustom = {
      control: (provided, state) => ({
        ...provided,
        cursor: "pointer",
        borderColor: "#2d2d3f",
        backgroundColor: "#ffffff00",
        alignItems: "baseline",
      }),
      option: (provided, state) => ({
        ...provided,
        cursor: "pointer",
        borderColor: "#2d2d3f",
        backgroundColor: state.isFocused
          ? "#2d2d3fb8"
          : state.isSelected
          ? "#0c0c0c"
          : "#181821",
      }),
      menu: (provided, state) => ({
        ...provided,
        backgroundColor: "#181821",
      }),
      singleValue: (provided, state) => ({
        ...provided,
        color: "#c2c4d1",
      }),
      multiValue: (provided, state) => ({
        ...provided,
        backgroundColor: "#4c4c4d",
      }),
      multiValueLabel: (provided, state) => ({
        ...provided,
        cursor: "text",
        color: "#c2c4d1",
      }),
      multiValueRemove: (provided, state) => ({
        ...provided,
        color: "#c2c4d1",
        borderColor: "#2d2d3f",
      }),
      indicatorSeparator: (provided, state) => ({
        ...provided,
        backgroundColor: "#909aa7",
      }),
      input: (provided, state) => ({
        ...provided,
        color: "#fff",
      }),
    };
  } else {
    styleCustom = {
      control: (provided, state) => ({
        ...provided,
        cursor: "pointer",
        alignItems: "baseline",
      }),
      option: (provided, state) => ({
        ...provided,
        cursor: "pointer",
      }),
      singleValue: (provided, state) => ({
        ...provided,
        color: "#5e6063",
      }),
      multiValue: (provided, state) => ({
        ...provided,
        backgroundColor: "#dbdbdb",
      }),
      multiValueLabel: (provided, state) => ({
        ...provided,
        cursor: "text",
        color: "#3f3f3f",
      }),
      multiValueRemove: (provided, state) => ({
        ...provided,
        color: "#5e6063",
        borderColor: "#5e6063",
      }),
      indicatorSeparator: (provided, state) => ({
        ...provided,
        backgroundColor: "#5e6063",
      }),
      input: (provided, state) => ({
        ...provided,
        color: "#5e6063",
      }),
    };
  }
  return (
    <Form.Group className={"mb-4"}>
      {title !== undefined ? (
        <>
          <Form.Label>{title}</Form.Label>
          {toast ? (
            <>
              <Icon
                addClass={" text-primary"}
                style={{
                  fontSize: "18px",
                  marginLeft: "7px",
                  cursor: "pointer",
                  verticalAlign: "middle",
                }}
                onClick={() => setShowToast(!showToast)}
              >
                help
              </Icon>
              <Toast
                style={{ position: "absolute", maxWidth: "250px" }}
                show={showToast}
                onClick={() => setShowToast(!showToast)}
              >
                <Toast.Body>{toast}</Toast.Body>
              </Toast>
            </>
          ) : (
            ""
          )}
        </>
      ) : (
        ""
      )}
      <Select styles={styleCustom} options={options} {...prop} />
      <Form.Text
        className="text-danger"
        style={{ display: "none", marginLeft: "10px" }}
      />
    </Form.Group>
  );
};

export default SelectLabel;
