const actionType = {
  setArray1: "actions.service.hotspot.voucher.setArray1",
  addArray1: "actions.service.hotspot.voucher.addArray1",
  updateArray1: "actions.service.hotspot.voucher.updateArray1",
  deleteArray1: "actions.service.hotspot.voucher.deleteArray1",

  setArray2: "actions.service.hotspot.voucher.setArray2",
  addArray2: "actions.service.hotspot.voucher.addArray2",
  updateArray2: "actions.service.hotspot.voucher.updateArray2",
  deleteArray2: "actions.service.hotspot.voucher.deleteArray2",

  setArray3: "actions.service.hotspot.voucher.setArray3",
  addArray3: "actions.service.hotspot.voucher.addArray3",
  updateArray3: "actions.service.hotspot.voucher.updateArray3",
  deleteArray3: "actions.service.hotspot.voucher.deleteArray3",

  setArray4: "actions.service.hotspot.voucher.setArray4",
  addArray4: "actions.service.hotspot.voucher.addArray4",
  updateArray4: "actions.service.hotspot.voucher.updateArray4",
  deleteArray4: "actions.service.hotspot.voucher.deleteArray4",

  setArray5: "actions.service.hotspot.voucher.setArray5",
  addArray5: "actions.service.hotspot.voucher.addArray5",
  updateArray5: "actions.service.hotspot.voucher.updateArray5",
  deleteArray5: "actions.service.hotspot.voucher.deleteArray5",

  setObject1: "actions.service.hotspot.voucher.setObject1",
  updateObject1: "actions.service.hotspot.voucher.updateObject1",

  setObject2: "actions.service.hotspot.voucher.setObject2",
  updateObject2: "actions.service.hotspot.voucher.updateObject2",

  setObject3: "actions.service.hotspot.voucher.setObject3",
  updateObject3: "actions.service.hotspot.voucher.updateObject3",

  setObject4: "actions.service.hotspot.voucher.setObject4",
  updateObject4: "actions.service.hotspot.voucher.updateObject4",

  setObject5: "actions.service.hotspot.voucher.setObject5",
  updateObject5: "actions.service.hotspot.voucher.updateObject5",

  setOther1: "actions.service.hotspot.voucher.other1",
  setOther2: "actions.service.hotspot.voucher.other2",
  setOther3: "actions.service.hotspot.voucher.other3",
  setOther4: "actions.service.hotspot.voucher.other4",
  setOther5: "actions.service.hotspot.voucher.other5",
};
const setArray1 = async (dispatch, payload) => {
  dispatch({ type: actionType.setArray1, payload: payload });
};
const addArray1 = async (dispatch, payload) => {
  dispatch({ type: actionType.addArray1, payload: payload });
};
const updateArray1 = async (dispatch, payload) => {
  dispatch({ type: actionType.updateArray1, payload: payload });
};
const deleteArray1 = async (dispatch, payload) => {
  dispatch({ type: actionType.deleteArray1, payload: payload });
};

const setArray2 = async (dispatch, payload) => {
  dispatch({ type: actionType.setArray2, payload: payload });
};
const addArray2 = async (dispatch, payload) => {
  dispatch({ type: actionType.addArray2, payload: payload });
};
const updateArray2 = async (dispatch, payload) => {
  dispatch({ type: actionType.updateArray2, payload: payload });
};
const deleteArray2 = async (dispatch, payload) => {
  dispatch({ type: actionType.deleteArray2, payload: payload });
};

const setArray3 = async (dispatch, payload) => {
  dispatch({ type: actionType.setArray3, payload: payload });
};
const addArray3 = async (dispatch, payload) => {
  dispatch({ type: actionType.addArray3, payload: payload });
};
const updateArray3 = async (dispatch, payload) => {
  dispatch({ type: actionType.updateArray3, payload: payload });
};
const deleteArray3 = async (dispatch, payload) => {
  dispatch({ type: actionType.deleteArray3, payload: payload });
};

const setArray4 = async (dispatch, payload) => {
  dispatch({ type: actionType.setArray4, payload: payload });
};
const addArray4 = async (dispatch, payload) => {
  dispatch({ type: actionType.addArray4, payload: payload });
};
const updateArray4 = async (dispatch, payload) => {
  dispatch({ type: actionType.updateArray4, payload: payload });
};
const deleteArray4 = async (dispatch, payload) => {
  dispatch({ type: actionType.deleteArray4, payload: payload });
};

const setArray5 = async (dispatch, payload) => {
  dispatch({ type: actionType.setArray5, payload: payload });
};
const addArray5 = async (dispatch, payload) => {
  dispatch({ type: actionType.addArray5, payload: payload });
};
const updateArray5 = async (dispatch, payload) => {
  dispatch({ type: actionType.updateArray5, payload: payload });
};
const deleteArray5 = async (dispatch, payload) => {
  dispatch({ type: actionType.deleteArray5, payload: payload });
};

const setObject1 = async (dispatch, payload) => {
  dispatch({ type: actionType.setObject1, payload: payload });
};
const updateObject1 = async (dispatch, payload) => {
  dispatch({ type: actionType.updateObject1, payload: payload });
};

const setObject2 = async (dispatch, payload) => {
  dispatch({ type: actionType.setObject2, payload: payload });
};
const updateObject2 = async (dispatch, payload) => {
  dispatch({ type: actionType.updateObject2, payload: payload });
};

const setObject3 = async (dispatch, payload) => {
  dispatch({ type: actionType.setObject3, payload: payload });
};
const updateObject3 = async (dispatch, payload) => {
  dispatch({ type: actionType.updateObject3, payload: payload });
};

const setObject4 = async (dispatch, payload) => {
  dispatch({ type: actionType.setObject4, payload: payload });
};
const updateObject4 = async (dispatch, payload) => {
  dispatch({ type: actionType.updateObject4, payload: payload });
};

const setObject5 = async (dispatch, payload) => {
  dispatch({ type: actionType.setObject5, payload: payload });
};
const updateObject5 = async (dispatch, payload) => {
  dispatch({ type: actionType.updateObject5, payload: payload });
};

const setOther1 = async (dispatch, payload) => {
  dispatch({ type: actionType.setOther1, payload: payload });
};
const setOther2 = async (dispatch, payload) => {
  dispatch({ type: actionType.setOther2, payload: payload });
};
const setOther3 = async (dispatch, payload) => {
  dispatch({ type: actionType.setOther3, payload: payload });
};
const setOther4 = async (dispatch, payload) => {
  dispatch({ type: actionType.setOther4, payload: payload });
};
const setOther5 = async (dispatch, payload) => {
  dispatch({ type: actionType.setOther5, payload: payload });
};

export const voucher = {
  actionType,
  setArray1,
  addArray1,
  updateArray1,
  deleteArray1,
  setArray2,
  addArray2,
  updateArray2,
  deleteArray2,
  setArray3,
  addArray3,
  updateArray3,
  deleteArray3,
  setArray4,
  addArray4,
  updateArray4,
  deleteArray4,
  setArray5,
  addArray5,
  updateArray5,
  deleteArray5,
  setObject1,
  updateObject1,
  setObject2,
  updateObject2,
  setObject3,
  updateObject3,
  setObject4,
  updateObject4,
  setObject5,
  updateObject5,
  setOther1,
  setOther2,
  setOther3,
  setOther4,
  setOther5,
};
