import LayoutHome from "../../components/Layout/Home";
import Div from "../../components/Div";
import {
  Alert,
  Button,
  ButtonGroup,
  Card,
  Col,
  Container,
  Form,
  Row,
  Stack,
  Table,
  ToggleButton,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import JWToken from "../../utils/JWToken";
import { service } from "../../store/service";
import { actions } from "../../store/actions";
import Swalert from "../../components/Swalert";
import SelectLabel from "../../components/SelectLabel";
import LayoutModal from "../../components/LayoutModal";
import InputLabel from "../../components/InputLabel";
import Tables from "../../components/Tables";
import Icon from "../../components/Icon";
import FilterListTable from "../../utils/FilterListTable";
import { config } from "../../utils/StaticString";

const HotspotVoucher = () => {
  const dispatch = useDispatch();
  const { modalAction, loader, accessCRUD } = useSelector(
    (state) => state.globalReducer
  );
  const { accCreated, accDelete, accUpdate } = accessCRUD;

  const shVoucherReducer = useSelector((state) => state.shVoucherReducer);
  const { object1, object2, object5 } = shVoucherReducer;
  const { other1, other2, other3, other4, other5 } = shVoucherReducer;
  const { array1, array2, array3, array4, array5 } = shVoucherReducer;

  const [formValidate, setFormValidate] = useState(false);

  const [listTable, setListTable] = useState(array1);
  const [searchVal, setSearchVal] = useState("");

  const [typeList, setTypeList] = useState("VOUCHER");
  const [optionAdmin, setOptionAdmin] = useState([]);
  const [optionProfile, setOptionProfile] = useState([]);
  const [optionServer, setOptionServer] = useState([]);
  const [alert, setAlert] = useState("");

  const [seller, setSeller] = useState(false);
  const [optionSeller, setOptionSeller] = useState([]);
  const [optionProfileSeller, setOptionProfileSeller] = useState([]);

  const actAddData = "actAddData";
  const actGenerateData = "actGenerateData";
  const actGetListVocuher = "actGetListVocuher";
  const actEditVoucher = "actEditVoucher";
  const actRenewServer = "actRenewServer";

  const created = {
    mikrotik: { value: "", label: "", name: "" },
    profile: { value: "", label: "" },
    admin: { value: "", label: "", seller: "" },
    quantity: "10",
    loginMode: { value: "vc", label: "Voucher" },
    nameLength: { value: "4", label: "4" },
    prefix: "",
    character: { value: "lower", label: "Random abcdef" },
    server: { value: "all", label: "all" },
    comment: "",
  };

  const optionNameLength = [
    { value: "4", label: "4" },
    { value: "6", label: "6" },
    { value: "8", label: "8" },
    { value: "10", label: "10" },
  ];

  const optionLoginMode = [
    { value: "vc", label: "Voucher" },
    { value: "up", label: "Username & Password" },
  ];

  const optionCharacter = [
    { value: "lower", label: "Random abcdef" },
    { value: "upper", label: "Random ABCDEF" },
    { value: "upplow", label: "Random aBcdeF" },
    { value: "num", label: "Random 123456" },
    { value: "numlow", label: "Random 123abc" },
    { value: "lownum", label: "Random abc123" },
    { value: "rand", label: "Random a1b23c" },
  ];

  useEffect(() => {
    setErrorAndTotal(listTable);
  }, [listTable]);

  useEffect(() => {
    setListTable(array1);
  }, [array1]);

  useEffect(() => {
    setListTable(FilterListTable(searchVal, array1));
  }, [searchVal]);

  useEffect(() => {
    actions.service.hotspot.voucher.setObject1(dispatch, created);
    if (new JWToken()._getStatusAccessToken()) {
      readData();
    }
  }, []);

  async function readData() {
    const protocol = window.location.protocol;
    const host = window.location.host;
    const baseUrl = protocol + "//" + host;
    const payload = {
      baseUrl: baseUrl,
    };

    const result = await service.layanan.hotspot.voucher._read(
      dispatch,
      payload
    );
    if (result.status) {
      result.data.mikrotik.map((item) => {
        item.label = item.name;
        item.value = item.id;
        item.adminHotspot = JSON.parse(item.adminHotspot);
        item.resellerHotspot = JSON.parse(item.resellerHotspot);
      });
      result.data.users.map((item) => {
        item.label = item.name;
        item.value = item.id;
      });
      result.data.profile.map((item) => {
        item.label = item.name;
        item.value = item.id;
      });

      setSeller(result.data.seller);

      if (result.data.seller) {
        if (result.data.users.length <= 1) {
          const newOptionSeller = [];
          const newOptionProfile = [];
          const user = result.data.users[0];
          newOptionSeller.push(user);
          result.data.mikrotik.map((item) => {
            for (let a = 0; a < result.data.profile.length; a++) {
              const element = result.data.profile[a];
              if (element.idParent === item.id) {
                newOptionProfile.push(element);
              }
            }
            for (let index = 0; index < item.adminHotspot.length; index++) {
              const el = item.adminHotspot[index];
              if (user.id === el) {
                updateData2("mikrotik", item);
                updateData2("admin", user);
                index = item.adminHotspot.length;
              }
            }
            for (let index = 0; index < item.resellerHotspot.length; index++) {
              const el = item.resellerHotspot[index];
              if (user.id === el) {
                updateData2("mikrotik", item);
                updateData2("admin", user);
                index = item.resellerHotspot.length;
              }
            }
          });
          setOptionSeller(newOptionSeller);
          setOptionProfileSeller(newOptionProfile);
        }
      }

      actions.service.hotspot.voucher.setOther1(dispatch, result.data.mikrotik);
      actions.service.hotspot.voucher.setArray2(dispatch, result.data.users);
      actions.service.hotspot.voucher.setArray3(dispatch, result.data.profile);
      actions.service.hotspot.voucher.setArray5(
        dispatch,
        result.data.listTemplate
      );
      return;
    }
    return new Swalert().error(result.data);
  }
  function setErrorAndTotal(listTable) {
    for (let index = 0; index < listTable.length; index++) {
      const element = listTable[index];
      if (element.messageErr !== "") {
        setAlert(
          "error in row " +
            (index + 1) +
            " : server pada voucher tidak dikenali di router mikrotik"
        );
        index = listTable.length;
      } else {
        setAlert("");
      }
    }
    var total = 0;
    var seller = 0;
    listTable.map((item) => {
      total += parseInt(item.harga);
      seller += resseler(item);
    });
    const newData = { total, seller };
    actions.service.hotspot.voucher.setOther2(dispatch, newData);
    function resseler(item) {
      const percen = 100 - parseInt(item.percen);
      const price = parseInt(item.harga);
      const seller = (price * percen) / 100;
      return seller;
    }
  }
  async function openModal(action, header, item) {
    if (action === actEditVoucher) {
      if (item.name === item.password) {
        item.loginMode = { value: "vc", label: "Voucher" };
      } else {
        item.loginMode = { value: "up", label: "Username & Password" };
      }
    }
    if (action === actRenewServer) {
      getListServerVoucher(object5.mikrotik.id);
    }
    actions.global.setLoaderText(dispatch, "");
    actions.global.setModalShow(dispatch, true);
    actions.global.setModalAction(dispatch, action);
    actions.global.setModalTitleHeader(dispatch, header);
    actions.service.hotspot.voucher.setObject1(dispatch, item);
    if (action === actGenerateData) {
      actions.service.hotspot.voucher.setObject3(dispatch, false);
      actions.service.hotspot.voucher.setArray4(dispatch, []);
      setOptionProfile([]);
      setOptionAdmin([]);
    }

    return;
  }
  async function getListServerVoucher(idMirotik) {
    const payload = { idMirotik: idMirotik };
    const result =
      await service.layanan.hotspot.voucher._getListServerHotspotMikrotik(
        dispatch,
        payload
      );
    if (result.status) {
      const newOptSer = [];
      result.data.map((item) => {
        const data = { value: item, label: item };
        newOptSer.push(data);
      });
      return setOptionServer(newOptSer);
    }
    actions.global.setModalShow(dispatch, false);
    return new Swalert().error(result.data);
  }
  const updateData = async (name, value) => {
    setFormValidate(false);
    const newData = { [name]: value };
    actions.service.hotspot.voucher.updateObject1(dispatch, newData);

    if (name === "mikrotik") {
      const newOpt = [];
      array3.map((item) => {
        if (item.idParent === value.id) {
          newOpt.push(item);
        }
      });
      setOptionProfile(newOpt);

      const newOptA = [];
      value.adminHotspot.map((item) => {
        for (let index = 0; index < array2.length; index++) {
          const el = array2[index];
          if (item === el.id) {
            newOptA.push(el);
            index = array2.length;
          }
        }
      });
      value.resellerHotspot.map((item) => {
        for (let index = 0; index < array2.length; index++) {
          const el = array2[index];
          if (item === el.id) {
            newOptA.push(el);
            index = array2.length;
          }
        }
      });

      setOptionAdmin(newOptA);

      updateData("profile", { value: "", label: "" });
      updateData("admin", { value: "", label: "", seller: "" });
    }
  };

  const updateData2 = async (name, value) => {
    const newData = { [name]: value };
    actions.service.hotspot.voucher.updateObject2(dispatch, newData);
    if (name === "mikrotik") {
      const newOpt = [];
      array3.map((item) => {
        if (item.idParent === value.id) {
          newOpt.push(item);
        }
      });
      setOptionProfileSeller(newOpt);

      const newOptA = [];
      value.adminHotspot.map((item) => {
        for (let index = 0; index < array2.length; index++) {
          const el = array2[index];
          if (item === el.id) {
            newOptA.push(el);
            index = array2.length;
          }
        }
      });
      value.resellerHotspot.map((item) => {
        for (let index = 0; index < array2.length; index++) {
          const el = array2[index];
          if (item === el.id) {
            newOptA.push(el);
            index = array2.length;
          }
        }
      });

      setOptionSeller(newOptA);

      updateData2("profile", { value: "", label: "" });
      updateData2("admin", { value: "", label: "", seller: "" });
      updateData2("comment", { value: "", label: "", datetime: "select" });
    }
    if (name === "admin") {
      if (object2) {
        if (value.value !== "" && object2.profile.value !== "") {
          const newDataReq = {
            admin: value.id,
            profile: object2.profile.id,
          };
          getComment(newDataReq);
          updateData2("comment", { value: "", label: "", datetime: "select" });
        }
      }
    }
    if (name === "profile") {
      if (object2) {
        if (value.value !== "" && object2.admin.value !== "") {
          const newDataReq = {
            admin: object2.admin.id,
            profile: value.id,
          };
          getComment(newDataReq);
          updateData2("comment", { value: "", label: "", datetime: "select" });
        }
      }
    }
  };

  const updateData5 = (name, value) => {
    const newData = { [name]: value };
    actions.service.hotspot.voucher.updateObject5(dispatch, newData);
  };

  async function getComment(newDataReq) {
    const result = await service.layanan.hotspot.voucher._getComment(
      dispatch,
      newDataReq
    );
    if (result.status) {
      result.data.map((item) => {
        item.value = item.comment;
        item.label = item.comment;
      });
      actions.service.hotspot.voucher.setArray4(dispatch, result.data);
      if (modalAction === actGetListVocuher) {
        updateData2("comment", { value: "", label: "", datetime: "select" });
      }
      return;
    }
    return new Swalert().error(result.data);
  }

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      submitHandler();
    }
  };
  const submitHandler = async () => {
    if (modalAction === actAddData) {
      await generateVoucher();
    }
    if (modalAction === actGenerateData) {
      await generateVoucher();
    }
    if (modalAction === actGetListVocuher) {
      await getlistVoucher();
    }
    if (modalAction === actEditVoucher) {
      return updateVoucher();
    }
    if (modalAction === actRenewServer) {
      return updateServerVoucher();
    }
  };

  async function generateVoucher() {
    if (object1.admin.value === "") {
      return new Swalert().error("Select a valid Admin");
    }
    if (object1.profile.value === "") {
      return new Swalert().error("Select a valid Profile");
    }
    if (object1.quantity === "") {
      return new Swalert().error("Fill in valid Quantity");
    }
    if (parseInt(object1.quantity) <= 0) {
      return new Swalert().error("Berikan Quantity yang valid");
    }
    if (object1.loginMode.value === "") {
      return new Swalert().error("Select a valid Login Mode");
    }
    if (object1.nameLength.value === "") {
      return new Swalert().error("Select a valid Length");
    }
    if (object1.character.value === "") {
      return new Swalert().error("Select a valid Character");
    }
    var percen = "0";
    if (object1.admin.seller === "Admin") {
      percen = object1.mikrotik.adminPercen;
    } else {
      percen = object1.mikrotik.resellerPercen;
    }
    const newData = {
      admin: object1.admin.value,
      profile: object1.profile.value,
      price: object1.profile.price,
      seller: object1.admin.seller,
      percen: percen,
      quantity: object1.quantity,
      loginMode: object1.loginMode.value,
      nameLength: object1.nameLength.value,
      prefix: object1.prefix,
      character: object1.character.value,
      server: object1.server.value,
      comment: object1.comment,
    };
    //return console.log(newData);

    actions.global.setModalShow(dispatch, false);
    const result = await service.layanan.hotspot.voucher._created(
      dispatch,
      newData
    );
    if (result.status) {
      var idProfile = "";
      var idUser = "";
      var comment = "";
      var voucher = false;
      result.data.listVoucher.map((item) => {
        item.name = item.voucher;
        idProfile = item.idProfile;
        idUser = item.idUser;
        comment = item.comment;
        if (item.voucher === item.password) {
          voucher = true;
        }
      });
      if (voucher) {
        setTypeList("VC");
      } else {
        setTypeList("UP");
      }

      var coment = { value: "", label: "", comment: "", datetime: "" };
      result.data.listComment.map((item) => {
        item.value = item.comment;
        item.label = item.comment;
        if (item.comment === comment) {
          coment = item;
        }
      });

      updateData5("mikrotik", object1.mikrotik);
      updateData5("profile", object1.profile);
      updateData5("admin", object1.admin);
      updateData5("comment", coment);

      updateData2("mikrotik", object1.mikrotik);
      updateData2("profile", object1.profile);
      updateData2("admin", object1.admin);
      updateData2("comment", coment);

      actions.service.hotspot.voucher.setArray1(
        dispatch,
        result.data.listVoucher
      );
      actions.service.hotspot.voucher.setArray4(
        dispatch,
        result.data.listComment
      );
      actions.service.hotspot.voucher.setOther3(dispatch, result.data.invoice);

      const first = 1;
      const action = "Created";
      const total = result.data.listVoucher.length;

      return serviceSyncVoucher(
        action,
        first,
        total,
        idProfile,
        idUser,
        comment
      );
    }
    const swal = await new Swalert().error(result.data);
    if (swal) {
      actions.global.setModalShow(dispatch, true);
    }
  }

  async function getlistVoucher() {
    if (object2.admin.value === "") {
      return new Swalert().error("Berikan Admin yang valid");
    }
    if (object2.profile.value === "") {
      return new Swalert().error("Berikan Profile yang valid");
    }
    if (object2.comment.value === "") {
      return new Swalert().error("Berikan Comment yang valid");
    }
    const newData = {
      admin: object2.admin.value,
      profile: object2.profile.value,
      comment: object2.comment.value,
    };

    updateData5("mikrotik", object2.mikrotik);
    updateData5("profile", object2.profile);
    updateData5("admin", object2.admin);
    updateData5("comment", object2.comment);

    const result = await service.layanan.hotspot.voucher._getListVoucher(
      dispatch,
      newData
    );
    if (result.status) {
      var voucher = false;
      result.data.listVoucher.map((item) => {
        item.name = item.voucher;
        if (item.voucher === item.password) {
          voucher = true;
        }
      });
      if (voucher) {
        setTypeList("VC");
      } else {
        setTypeList("UP");
      }
      actions.global.setModalShow(dispatch, false);
      actions.service.hotspot.voucher.setOther3(dispatch, result.data.invoice);
      actions.service.hotspot.voucher.setArray1(
        dispatch,
        result.data.listVoucher
      );
      return;
    }
    return new Swalert().error(result.data);
  }

  async function updateVoucher() {
    if (object1.loginMode.value === "vc") {
      if (object1.voucher === "") {
        return new Swalert().error("Berikan Voucher yang valid");
      }
    } else {
      if (object1.voucher === "") {
        return new Swalert().error("Berikan UserName yang valid");
      }
      if (object1.password === "") {
        return new Swalert().error("Berikan Password yang valid");
      }
    }
    if (object1.comment === "") {
      return new Swalert().error("Berikan Comment yang valid");
    }

    var pass = object1.password;
    if (object1.loginMode.value === "vc") {
      pass = object1.voucher;
    }

    const newData = {
      id: object1.id,
      voucher: object1.voucher,
      password: pass,
      comment: object1.comment,
    };

    const result = await service.layanan.hotspot.voucher._update(
      dispatch,
      newData
    );
    if (result.status) {
      actions.global.setModalShow(dispatch, false);
      actions.service.hotspot.voucher.updateArray1(dispatch, result.data);
      return;
    }
    return new Swalert().error(result.data);
  }

  async function deleteVoucher() {
    const swal = await new Swalert().confirm(
      "Delete by comment " + object5.comment.value + "?",
      "Yes"
    );
    if (swal) {
      const first = 1;
      const total = array1.length;
      serviceDeleteVoucher(
        first,
        total,
        object5.profile.id,
        object5.admin.id,
        object5.comment.comment
      );
    }
  }
  async function serviceDeleteVoucher(
    first,
    total,
    idProfile,
    idUser,
    comment
  ) {
    actions.global.setLoaderText(dispatch, "delete " + first + " to " + total);
    const newData = {
      first: first,
      profile: idProfile,
      admin: idUser,
      comment: comment,
    };
    const result = await service.layanan.hotspot.voucher._delete(
      dispatch,
      newData
    );
    if (result.status) {
      if (parseInt(result.data.first) < total) {
        return serviceDeleteVoucher(
          result.data.first,
          total,
          idProfile,
          idUser,
          comment
        );
      } else {
        result.data.listComment.map((item) => {
          item.value = item.comment;
          item.label = item.comment;
        });
        actions.service.hotspot.voucher.setArray1(
          dispatch,
          result.data.listVoucher
        );
        actions.service.hotspot.voucher.setArray4(
          dispatch,
          result.data.listComment
        );
        actions.global.setModalShow(dispatch, false);
        updateData2("comment", { value: "", label: "", datetime: "select" });
        return new Swalert().success("Delete Voucher Success");
      }
    }
    return new Swalert().error(result.data);
  }

  async function updateServerVoucher() {
    if (!object1.server) {
      return new Swalert().error("Pilih Server yang valid");
    }
    const newData = {
      profile: object5.profile.id,
      admin: object5.admin.id,
      comment: object5.comment.comment,
      server: object1.server.value,
    };
    const result = await service.layanan.hotspot.voucher._updateServer(
      dispatch,
      newData
    );
    if (result.status) {
      actions.global.setModalShow(dispatch, false);
      actions.service.hotspot.voucher.setArray1(dispatch, result.data);

      const first = 1;
      const total = array1.length;
      const action = "Sinkron";
      serviceSyncVoucher(
        action,
        first,
        total,
        object5.profile.id,
        object5.admin.id,
        object5.comment.comment
      );

      return;
    }
    return new Swalert().error(result.data);
  }

  async function syncVoucher() {
    const swal = await new Swalert().confirm(
      "Sinkronkan " + object5.comment.value + " ke Mikrotik?",
      "Yes"
    );
    if (swal) {
      const first = 1;
      const total = array1.length;
      const action = "Sinkron";
      serviceSyncVoucher(
        action,
        first,
        total,
        object5.profile.id,
        object5.admin.id,
        object5.comment.comment
      );
    }
  }
  async function serviceSyncVoucher(
    action,
    first,
    total,
    idProfile,
    idUser,
    comment
  ) {
    actions.global.setLoaderText(dispatch, "process " + first + " to " + total);
    const newData = {
      first: first,
      profile: idProfile,
      admin: idUser,
      comment: comment,
    };
    const result = await service.layanan.hotspot.voucher._sync(
      dispatch,
      newData
    );
    if (result.status) {
      if (parseInt(result.data.first) < total) {
        return serviceSyncVoucher(
          action,
          result.data.first,
          total,
          idProfile,
          idUser,
          comment
        );
      } else {
        actions.global.setModalShow(dispatch, false);
        actions.service.hotspot.voucher.setArray1(
          dispatch,
          result.data.listVoucher
        );
        return new Swalert().success(action + " Voucher Success");
      }
    }
    return new Swalert().error(result.data);
  }

  function showSelectPrint() {
    var htmlUrl = "";
    var price = "";
    for (let index = 0; index < array1.length; index++) {
      const element = array1[index];
      price = element.harga;
      index = array1.length;
    }
    array5.map((item) => {
      var pathUrl = item.url;
      pathUrl += "&comment=" + object5.comment.value;
      pathUrl += "&profile=" + object5.profile.name;
      pathUrl += "&name=" + object5.admin.name;
      pathUrl += "&dnsName=" + object2.mikrotik.dnsName;
      pathUrl += "&price=" + price;

      htmlUrl +=
        "<a href='" +
        pathUrl +
        "' target='_blank' ><button class='btn btn-outline-success btn-sm' style='margin-bottom: 10px;'>Print " +
        item.name +
        "</button></a><br>";
    });
    new Swalert().custom("Select Template", htmlUrl);
  }

  function modalFormEdit() {
    return (
      <Form noValidate validated={formValidate}>
        <Row>
          <SelectLabel
            title={"Login Mode"}
            isSearchable={false}
            options={optionLoginMode}
            value={object1.loginMode}
            onChange={(e) => updateData("loginMode", e)}
          />
          <InputLabel
            title={object1.loginMode.value === "vc" ? "Voucher" : "UserName"}
            name={"voucher"}
            required
            defaultValue={object1.voucher}
            onChange={(e) => updateData(e.target.name, e.target.value)}
          />
          {object1.loginMode.value === "vc" ? (
            ""
          ) : (
            <InputLabel
              title="Password"
              name={"password"}
              required
              defaultValue={object1.password}
              onChange={(e) => updateData(e.target.name, e.target.value)}
            />
          )}

          <InputLabel
            title="Comment"
            name={"comment"}
            required
            defaultValue={object1.comment}
            onChange={(e) => updateData(e.target.name, e.target.value)}
          />
        </Row>
      </Form>
    );
  }
  function modalFormAdd() {
    return (
      <Form noValidate validated={formValidate}>
        <Row>
          <SelectLabel
            title={
              object5.mikrotik
                ? "Select " + object5.mikrotik.seller
                : "Select Seller"
            }
            isSearchable={true}
            value={object5.admin}
            getOptionLabel={(option) => `${option.seller} : ${option.label}`}
          />
          <SelectLabel
            title={"Select Profile"}
            isSearchable={false}
            value={object5.profile}
          />
          <InputLabel
            title="Server"
            type={"text"}
            defaultValue={object1.server.value}
            readOnly
          />
          <InputLabel
            title="Quantity"
            name={"quantity"}
            type={"number"}
            defaultValue={object1.quantity}
            required
            onChange={(e) => updateData(e.target.name, e.target.value)}
            onKeyDown={handleKeyDown}
          />
          <SelectLabel
            title={"Login Mode"}
            isSearchable={false}
            value={object1.loginMode}
          />
          <SelectLabel
            title={object1.loginMode.label + " Length"}
            isSearchable={false}
            options={optionNameLength}
            value={object1.nameLength}
            onChange={(e) => updateData("nameLength", e)}
          />
          <InputLabel
            title="Prefix"
            name={"prefix"}
            defaultValue={object1.prefix}
            onChange={(e) => updateData(e.target.name, e.target.value)}
            onKeyDown={handleKeyDown}
          />
          <SelectLabel
            title={"Character"}
            isSearchable={false}
            options={optionCharacter}
            value={object1.character}
            onChange={(e) => updateData("character", e)}
          />
          <InputLabel
            title="Comment"
            name={"comment"}
            readOnly
            defaultValue={object5.comment.value}
          />
        </Row>
      </Form>
    );
  }
  function modalFormGenerate() {
    function keterangan() {
      const percen = parseInt(object1.mikrotik.resellerPercen);
      const qty = parseInt(object1.quantity);
      const harga = parseInt(object1.profile.price) * qty;
      const hargaSeller = (harga * percen) / 100;
      const ppn = parseInt(object1.mikrotik.resellerPPN);
      var totalHarga = harga - hargaSeller;
      var ppnPrice = "0";
      if (ppn > 0) {
        ppnPrice = ((harga - hargaSeller) * ppn) / 100;
        totalHarga = harga - hargaSeller + ppnPrice;
      }
      return (
        <>
          <span>
            Aksi ini akan melakukan transaksi di keuangan organisasi sebagai{" "}
            Penjualan Voucher (<b>Pemasukan</b>) dengan rincian sebagai berikut
            :
          </span>
          <Table style={{ marginTop: "5px", marginBottom: "20px" }}>
            <Tables.Body>
              <Tables.Tr>
                <Tables.Td style={styleLeft}>Harga</Tables.Td>
                <Tables.Td style={styleRight}>{setRupiah(harga)}</Tables.Td>
              </Tables.Tr>
              <Tables.Tr>
                <Tables.Td style={styleLeft}>
                  {"Potongan " + percen + "%"}
                </Tables.Td>
                <Tables.Td style={styleRight}>
                  {setRupiah(-hargaSeller)}
                </Tables.Td>
              </Tables.Tr>
              <Tables.Tr>
                <Tables.Td style={styleLeft}>{"PPN " + ppn + "%"}</Tables.Td>
                <Tables.Td style={styleRight}>{setRupiah(ppnPrice)}</Tables.Td>
              </Tables.Tr>
              <Tables.Tr>
                <Tables.Td style={styleLeft}>TOTAL</Tables.Td>
                <Tables.Td style={styleRight}>
                  <b>{setRupiah(totalHarga)}</b>
                </Tables.Td>
              </Tables.Tr>
            </Tables.Body>
          </Table>
        </>
      );
    }
    return (
      <Form noValidate validated={formValidate}>
        <Row>
          <Col xs={12}>
            <SelectLabel
              title={"Select Mikrotik"}
              isSearchable={false}
              options={other1}
              value={object1.mikrotik}
              onChange={(e) => {
                updateData("mikrotik", e);
              }}
            />
          </Col>
          <Col xs={12}>
            <SelectLabel
              title={"Select Profile"}
              isSearchable={false}
              options={optionProfile}
              value={object1.profile}
              onChange={(e) => {
                updateData("profile", e);
              }}
            />
          </Col>
          <Col xs={12}>
            <SelectLabel
              title={"Select Seller"}
              isSearchable={true}
              options={optionAdmin}
              value={object1.admin}
              getOptionLabel={(option) => `${option.seller} : ${option.label}`}
              onChange={(e) => {
                updateData("admin", e);
              }}
            />
          </Col>
          {object1 ? (
            object1.admin.value !== "" && object1.profile.value !== "" ? (
              <>
                <SelectLabel
                  title={"Server"}
                  isSearchable={false}
                  options={optionServer}
                  value={object1.server}
                  onChange={(e) => updateData("server", e)}
                />
                <InputLabel
                  title="Quantity"
                  name={"quantity"}
                  type={"number"}
                  defaultValue={object1.quantity}
                  required
                  onChange={(e) => updateData(e.target.name, e.target.value)}
                  onKeyDown={handleKeyDown}
                />
                <SelectLabel
                  title={"Login Mode"}
                  isSearchable={false}
                  options={optionLoginMode}
                  value={object1.loginMode}
                  onChange={(e) => updateData("loginMode", e)}
                />
                <SelectLabel
                  title={object1.loginMode.label + " Length"}
                  isSearchable={false}
                  options={optionNameLength}
                  value={object1.nameLength}
                  onChange={(e) => updateData("nameLength", e)}
                />
                <InputLabel
                  title="Prefix"
                  name={"prefix"}
                  defaultValue={object1.prefix}
                  onChange={(e) => updateData(e.target.name, e.target.value)}
                  onKeyDown={handleKeyDown}
                />
                <SelectLabel
                  title={"Character"}
                  isSearchable={false}
                  options={optionCharacter}
                  value={object1.character}
                  onChange={(e) => updateData("character", e)}
                />
                <InputLabel
                  title="Comment"
                  name={"comment"}
                  defaultValue={object1.comment}
                  onChange={(e) => updateData(e.target.name, e.target.value)}
                  onKeyDown={handleKeyDown}
                />
                {object1.admin.seller === "Reseller" ? keterangan() : ""}
              </>
            ) : (
              ""
            )
          ) : (
            ""
          )}
        </Row>
      </Form>
    );
  }
  function modalFormGetList() {
    return (
      <>
        <Form noValidate validated={formValidate}>
          {seller ? (
            <Row>
              <SelectLabel
                title={"Select Seller"}
                isSearchable={true}
                options={optionSeller}
                value={object2.admin}
                getOptionLabel={(option) =>
                  `${option.seller} : ${option.label}`
                }
                onChange={(e) => {
                  updateData2("admin", e);
                }}
              />
              <SelectLabel
                title={"Select Profile"}
                isSearchable={false}
                options={optionProfileSeller}
                value={object2.profile}
                onChange={(e) => {
                  updateData2("profile", e);
                }}
              />
              <SelectLabel
                title={"Select Comment"}
                isSearchable={false}
                options={array4}
                value={object2.comment}
                getOptionLabel={(option) =>
                  `${option.label}  (${option.datetime})`
                }
                onChange={(e) => updateData2("comment", e)}
              />
            </Row>
          ) : (
            <Row>
              <SelectLabel
                title={"Select Mikrotik"}
                isSearchable={false}
                options={other1}
                value={object2.mikrotik}
                onChange={(e) => {
                  updateData2("mikrotik", e);
                }}
              />
              <SelectLabel
                title={"Select Profile"}
                isSearchable={false}
                options={optionProfileSeller}
                value={object2.profile}
                onChange={(e) => {
                  updateData2("profile", e);
                }}
              />
              <SelectLabel
                title={"Select Seller"}
                isSearchable={true}
                options={optionSeller}
                value={object2.admin}
                getOptionLabel={(option) =>
                  `${option.seller} : ${option.label}`
                }
                onChange={(e) => {
                  updateData2("admin", e);
                }}
              />
              <SelectLabel
                title={"Select Comment"}
                isSearchable={false}
                options={array4}
                value={object2.comment}
                getOptionLabel={(option) =>
                  `${option.label}  (${option.datetime})`
                }
                onChange={(e) => updateData2("comment", e)}
              />
            </Row>
          )}
        </Form>
        {accUpdate ? (
          ""
        ) : (
          <Button
            variant="primary"
            disabled={loader}
            onClick={!loader ? submitHandler : null}
          >
            {loader ? "Loading…" : "Process"}
          </Button>
        )}
      </>
    );
  }
  function modalFormRenewServer() {
    return (
      <SelectLabel
        title={"Server"}
        isSearchable={false}
        options={optionServer}
        value={object1.server}
        onChange={(e) => updateData("server", e)}
      />
    );
  }
  const openInNewTab = (accessCode) => {
    const slug = "files/invoice.php?type=reseller&access=" + accessCode;
    window.open(config.baseUrl + slug, "_blank", "noreferrer");
  };

  function setLayoutTable() {
    function dataBody() {
      if (!listTable) {
        return;
      }
      if (listTable.length <= 0) {
        return;
      }
      return listTable.map((item, index) => {
        function actEdit() {
          for (let index = 0; index < array3.length; index++) {
            const element = array3[index];
            if (element.id === item.idProfile) {
              if (element.limit === "0s") {
                return (
                  <ButtonGroup>
                    <ToggleButton
                      variant={"outline-success"}
                      style={{ padding: "3px 3px 3px 5px" }}
                      onClick={() =>
                        openModal(actEditVoucher, "Edit Voucher", item)
                      }
                    >
                      <Icon style={{ fontSize: "20px", marginLeft: "0px" }}>
                        edit
                      </Icon>
                    </ToggleButton>
                  </ButtonGroup>
                );
              }
            }
          }
          return (
            <ButtonGroup>
              <ToggleButton
                variant={"outline"}
                disabled={true}
                style={{ padding: "3px 3px 3px 5px" }}
              >
                <Icon style={{ fontSize: "20px", marginLeft: "0px" }}>
                  edit
                </Icon>
              </ToggleButton>
            </ButtonGroup>
          );
        }
        function resseler() {
          const percen = 100 - parseInt(item.percen);
          const price = parseInt(item.harga);
          const seller = (price * percen) / 100;
          return seller;
        }

        return (
          <Tables.Tr>
            <Tables.Td>{index + 1}</Tables.Td>
            {accUpdate ? (
              object2.profile ? (
                object2.profile.limit === "0s" ? (
                  <Tables.Td>{actEdit()}</Tables.Td>
                ) : (
                  ""
                )
              ) : (
                ""
              )
            ) : (
              ""
            )}
            {!seller ? <Tables.Td>{item.server}</Tables.Td> : ""}
            {typeList === "VC" ? (
              <Tables.Td>{item.voucher}</Tables.Td>
            ) : (
              <>
                <Tables.Td>{item.voucher}</Tables.Td>
                <Tables.Td>{item.password}</Tables.Td>
              </>
            )}
            {item.messageErr === "" ? (
              <>
                <Tables.Td>
                  {array3.map((data) => {
                    if (data.id === item.idProfile) {
                      return data.name;
                    }
                  })}
                </Tables.Td>
                {other3 ? <Tables.Td>{setRupiah(resseler())}</Tables.Td> : ""}
                <Tables.Td>{setRupiah(item.harga)}</Tables.Td>
              </>
            ) : (
              <Tables.Td colspan={3}>
                <span style={{ color: "red" }}>
                  {"error : " + item.messageErr}
                </span>
              </Tables.Td>
            )}
          </Tables.Tr>
        );
      });
    }
    return (
      <>
        <Stack direction="horizontal" style={{ marginBottom: "5px" }} gap={2}>
          <Div>
            {alert !== "" ? (
              accCreated ? (
                <>
                  <Button
                    variant="success"
                    size="sm"
                    onClick={() => createdData(actRenewServer)}
                  >
                    RENEW SERVER
                  </Button>
                </>
              ) : (
                ""
              )
            ) : object5 ? (
              <ButtonGroup>
                {!seller ? (
                  <>
                    {accCreated ? (
                      object5.admin.seller === "Admin" ? (
                        <ToggleButton
                          variant={"outline-primary"}
                          style={{ padding: "3px 3px 3px 7px" }}
                          onClick={() => createdData(actAddData)}
                        >
                          <Icon style={{ fontSize: "20px", marginLeft: "0px" }}>
                            add_circle
                          </Icon>
                        </ToggleButton>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                    {accDelete ? (
                      object5.admin.seller === "Admin" ? (
                        <ToggleButton
                          variant={"outline-danger"}
                          style={{ padding: "3px 3px 3px 7px" }}
                          onClick={() => deleteVoucher()}
                        >
                          <Icon style={{ fontSize: "20px", marginLeft: "0px" }}>
                            delete
                          </Icon>
                        </ToggleButton>
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                    {accUpdate || accCreated || accDelete ? (
                      <ToggleButton
                        variant={"outline-warning"}
                        style={{ padding: "3px 3px 3px 7px" }}
                        onClick={() => syncVoucher()}
                      >
                        <Icon style={{ fontSize: "20px", marginLeft: "0px" }}>
                          sync
                        </Icon>
                      </ToggleButton>
                    ) : (
                      ""
                    )}
                  </>
                ) : (
                  ""
                )}
                {object5.admin.seller === "Reseller" ? (
                  <ToggleButton
                    variant={"outline-primary"}
                    style={{ padding: "3px 3px 3px 7px" }}
                    onClick={() => openInNewTab(other3.codeAccess)}
                  >
                    <Icon style={{ fontSize: "20px", marginLeft: "0px" }}>
                      description
                    </Icon>
                  </ToggleButton>
                ) : (
                  ""
                )}
                <ToggleButton
                  variant={"outline-success"}
                  style={{ padding: "3px 3px 3px 7px" }}
                  onClick={showSelectPrint}
                >
                  <Icon style={{ fontSize: "20px", marginLeft: "0px" }}>
                    print
                  </Icon>
                </ToggleButton>
              </ButtonGroup>
            ) : (
              ""
            )}
          </Div>
          <Div
            className="ms-auto"
            style={{ alignSelf: "flex-end", width: "120px" }}
          >
            <Form.Control
              type={"text"}
              placeholder={"Cari Voucher . . ."}
              onChange={(e) => setSearchVal(e.target.value)}
            />
          </Div>
        </Stack>
        <Tables.Table>
          <Tables.Head>
            <Tables.Tr>
              <Tables.Th>#</Tables.Th>
              {accUpdate ? (
                object2.profile ? (
                  object2.profile.limit === "0s" ? (
                    <Tables.Th>EDIT</Tables.Th>
                  ) : (
                    ""
                  )
                ) : (
                  ""
                )
              ) : (
                ""
              )}
              {!seller ? <Tables.Th>SERVER</Tables.Th> : ""}
              {typeList === "VC" ? (
                <Tables.Th>VOUCHER</Tables.Th>
              ) : (
                <>
                  <Tables.Th>USERNAME</Tables.Th>
                  <Tables.Th>PASSWORD</Tables.Th>
                </>
              )}
              <Tables.Th>PROFILE</Tables.Th>
              {other3 ? <Tables.Th>RESELLER</Tables.Th> : ""}
              <Tables.Th>PRICE</Tables.Th>
            </Tables.Tr>
          </Tables.Head>
          <Tables.Body>{dataBody()}</Tables.Body>
        </Tables.Table>
      </>
    );
  }
  const createdData = (act) => {
    if (act === actAddData) {
      var loginMode = { value: "up", label: "Username & Password" };
      var server = { value: "all", label: "all" };

      for (let index = 0; index < listTable.length; index++) {
        const item = listTable[index];
        server = { value: item.server, label: item.server };
        if (item.voucher === item.password) {
          loginMode = { value: "vc", label: "Voucher" };
        }
        index = listTable.length;
      }
      updateData("mikrotik", object5.mikrotik);
      updateData("profile", object5.profile);
      updateData("admin", object5.admin);
      updateData("comment", object5.comment.value);

      const created = {
        admin: object5.admin,
        profile: object5.profile,
        mikrotik: object5.mikrotik,
        quantity: "10",
        server: server,
        loginMode: loginMode,
        nameLength: { value: "4", label: "4" },
        prefix: "",
        character: { value: "lower", label: "Random abcdef" },
        comment: object5.comment.value,
      };
      return openModal(act, "Add Voucher", created);
    }
    if (act === actGenerateData) {
      return openModal(act, "Generate Voucher", created);
    }
    if (act === actGetListVocuher) {
      return openModal(act, "List Voucher", object2);
    }
    if (act === actRenewServer) {
      return openModal(act, "Update Server", {});
    }
  };
  function setRupiah(value) {
    return new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
      minimumFractionDigits: 0,
    }).format(value);
  }
  const styleLeft = {
    textAlign: "left",
    paddingTop: "0px",
    paddingBottom: "0px",
  };
  const styleRight = {
    textAlign: "right",
    paddingTop: "0px",
    paddingBottom: "0px",
  };
  return (
    <LayoutHome>
      <LayoutModal onSave={submitHandler}>
        {modalAction === actAddData ? modalFormAdd() : ""}
        {modalAction === actEditVoucher ? modalFormEdit() : ""}
        {modalAction === actGenerateData ? modalFormGenerate() : ""}
        {modalAction === actGetListVocuher ? modalFormGetList() : ""}
        {modalAction === actRenewServer ? modalFormRenewServer() : ""}
      </LayoutModal>
      {alert !== "" ? <Alert variant={"danger"}>{alert}</Alert> : ""}
      <Card>
        <Stack direction="horizontal" style={{ marginBottom: "5px" }} gap={2}>
          <Div style={{ margin: "10px" }}>
            {accCreated ? (
              <>
                <Button
                  style={{ marginBottom: "10px", marginRight: "10px" }}
                  variant="outline-warning"
                  size="sm"
                  onClick={() => createdData(actGenerateData)}
                >
                  GENERATE
                </Button>
                <Button
                  style={{ marginBottom: "10px" }}
                  variant="outline-success"
                  size="sm"
                  onClick={() => createdData(actGetListVocuher)}
                >
                  LIST VOUCHERS
                </Button>
              </>
            ) : (
              <Button
                style={{ marginBottom: "25px" }}
                variant="outline-success"
                size="sm"
                onClick={() => createdData(actGetListVocuher)}
              >
                LIST VOUCHERS
              </Button>
            )}

            {object5 ? (
              <Table style={{ marginTop: "20px", marginBottom: "10px" }}>
                <Tables.Body>
                  <Tables.Tr>
                    <Tables.Td style={styleLeft}>
                      {object5.admin.seller}
                    </Tables.Td>
                    <Tables.Td style={styleLeft}>
                      {object5.admin.name}
                    </Tables.Td>
                  </Tables.Tr>
                  <Tables.Tr>
                    <Tables.Td style={styleLeft}>Profile</Tables.Td>
                    <Tables.Td style={styleLeft}>
                      {object5.profile.name}
                    </Tables.Td>
                  </Tables.Tr>
                  <Tables.Tr>
                    <Tables.Td style={styleLeft}>Coment</Tables.Td>
                    <Tables.Td style={styleLeft}>
                      {object5.comment.comment}
                    </Tables.Td>
                  </Tables.Tr>
                  {object5.admin.seller === "Reseller" ? (
                    other3 ? (
                      <>
                        <Tables.Tr>
                          <Tables.Td style={styleLeft}>Invoice</Tables.Td>
                          <Tables.Td style={styleLeft}>
                            {other3.invoice}
                          </Tables.Td>
                        </Tables.Tr>
                        <Tables.Tr>
                          <Tables.Td style={styleLeft}>Voucher</Tables.Td>
                          <Tables.Td style={styleLeft}>
                            {other3.totalVoucher + " item"}
                          </Tables.Td>
                        </Tables.Tr>
                        <Tables.Tr>
                          <Tables.Td style={styleLeft}>Price</Tables.Td>
                          <Tables.Td style={styleLeft}>
                            {setRupiah(other3.totalHarga)}
                          </Tables.Td>
                        </Tables.Tr>
                        <Tables.Tr>
                          <Tables.Td style={styleLeft}>
                            {object5.admin.seller}
                          </Tables.Td>
                          <Tables.Td style={styleLeft}>
                            {setRupiah(other3.hargaSeller)}
                          </Tables.Td>
                        </Tables.Tr>
                        {parseInt(other3.ppn) !== 0 ? (
                          <Tables.Tr>
                            <Tables.Td style={styleLeft}>
                              {"PPN " + other3.ppn + "%"}
                            </Tables.Td>
                            <Tables.Td style={styleLeft}>
                              {setRupiah(
                                (parseInt(other3.hargaSeller) *
                                  parseInt(other3.ppn)) /
                                  100
                              )}
                            </Tables.Td>
                          </Tables.Tr>
                        ) : (
                          ""
                        )}
                      </>
                    ) : (
                      ""
                    )
                  ) : (
                    <Tables.Tr>
                      <Tables.Td style={styleLeft}>Record</Tables.Td>
                      <Tables.Td style={styleLeft}>
                        {array1.length + " item"}
                      </Tables.Td>
                    </Tables.Tr>
                  )}
                </Tables.Body>
              </Table>
            ) : (
              ""
            )}
          </Div>
        </Stack>
        {setLayoutTable()}
      </Card>
    </LayoutHome>
  );
};

export default HotspotVoucher;
