import LayoutHome from "../../components/Layout/Home";
import Div from "../../components/Div";
import {
  Accordion,
  ButtonGroup,
  Card,
  Col,
  Form,
  Row,
  Table,
  ToggleButton,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import JWToken from "../../utils/JWToken";
import { service } from "../../store/service";
import { actions } from "../../store/actions";
import Swalert from "../../components/Swalert";
import Tables from "../../components/Tables";
import FilterListTable from "../../utils/FilterListTable";
import Icon from "../../components/Icon";

import { Controlled as CodeMirror } from "react-codemirror2";
import "codemirror/lib/codemirror.css";
import "codemirror/theme/material.css";
import "codemirror/mode/jsx/jsx";
import "./codeMirror.css";
import { config } from "../../utils/StaticString";
import axios from "axios";
import SelectLabel from "../../components/SelectLabel";

const HotspotTemplate = () => {
  const dispatch = useDispatch();
  const shTemplateReducer = useSelector((state) => state.shTemplateReducer);
  const { array1, object1, other1 } = shTemplateReducer;

  const [action, setAction] = useState("");
  const actCreated = "actCreated";
  const actUpdate = "actUpdate";

  useEffect(() => {
    if (new JWToken()._getStatusAccessToken()) {
      readFirstData();
    }
  }, []);

  async function readFirstData() {
    const result = await service.layanan.hotspot.template._read(dispatch);
    if (result.status) {
      result.data.map((item) => {
        item.label = item.name;
        item.value = item.id;
      });
      actions.service.hotspot.template.setArray1(dispatch, result.data);
      return;
    }

    return new Swalert().error(result.data);
  }

  const selectTemplate = (item) => {
    setAction(actUpdate);
    actions.service.hotspot.template.setObject1(dispatch, item);
    const url = config.baseUrl + "/files/berkas/" + item.adminHotspot;
    fetch(url)
      .then((response) => response.text())
      .then((response) =>
        actions.service.hotspot.template.setOther1(dispatch, response)
      );
  };

  async function defaultTemplate() {
    actions.service.hotspot.template.setObject1(dispatch, {
      adminHotspot: "",
      name: "",
      id: "",
    });
    actions.service.hotspot.template.setOther1(dispatch, "");
    const protocol = window.location.protocol;
    const host = window.location.host;
    const baseUrl = protocol + "//" + host;
    const templateDefault = baseUrl + "/template.txt";
    fetch(templateDefault)
      .then((response) => response.text())
      .then((response) =>
        actions.service.hotspot.template.setOther1(dispatch, response)
      );
  }
  const updateData = async (value) => {
    const newData1 = { name: value };
    await actions.service.hotspot.template.updateObject1(dispatch, newData1);
    const newData2 = { adminHotspot: value };
    await actions.service.hotspot.template.updateObject1(dispatch, newData2);
  };
  const saveTemplate = async () => {
    if (object1.name === "") {
      return new Swalert().error("Template name harus di isi!");
    }
    const newBlob = new Blob([other1], {
      type: "text/plain;charset=utf-8",
    });
    const dataImage = {
      file: newBlob,
      id: object1.id,
      name: object1.name,
      fileName: object1.adminHotspot,
    };
    const result = await service.layanan.hotspot.template._update(
      dispatch,
      dataImage
    );
    if (result.status) {
      setAction("");
      result.data.map((item) => {
        item.label = item.name;
        item.value = item.id;
      });
      actions.service.hotspot.template.setArray1(dispatch, result.data);
      if (action === actCreated) {
        return new Swalert().success("Berhasil menambahkan Template");
      } else {
        return new Swalert().success("Berhasil Update Template");
      }
    }

    return new Swalert().error(result.data);
  };
  async function deleteTemplate() {
    const swal = await new Swalert().confirm("Delete " + object1.name, "Yes");
    if (swal) {
      const result = await service.layanan.hotspot.template._delete(
        dispatch,
        object1
      );
      if (result.status) {
        actions.service.hotspot.template.deleteArray1(dispatch, object1);
        return new Swalert().success("Delete " + object1.name + " Berhasil");
      }
      return new Swalert().error(result.data);
    }
  }

  const options = {
    theme: "material",
    autoCloseBrackets: true,
    cursorScrollMargin: 48,
    mode: "jsx",
    lineNumbers: true,
    indentUnit: 2,
    tabSize: 2,
    styleActiveLine: true,
    viewportMargin: 99,
  };
  return (
    <LayoutHome>
      <Card>
        <Div style={{ marginLeft: "10px", marginRight: "10px" }}>
          <SelectLabel
            title="Select Template"
            options={array1}
            onChange={(e) => selectTemplate(e)}
          />
          <Accordion style={{ marginBottom: "15px" }}>
            <Accordion.Item eventKey="0">
              <Accordion.Header>KETERANGAN</Accordion.Header>
              <Accordion.Body>
                <Table style={{ margin: "0px" }}>
                  <Tables.Body>
                    <Tables.Tr>
                      <Tables.Td
                        style={{ textAlign: "right" }}
                      >{`DNS Name : `}</Tables.Td>
                      <Tables.Td
                        style={{ textAlign: "left" }}
                      >{`<?= $dnsName; ?>`}</Tables.Td>
                    </Tables.Tr>
                    <Tables.Tr>
                      <Tables.Td
                        style={{ textAlign: "right" }}
                      >{`Voucher : `}</Tables.Td>
                      <Tables.Td
                        style={{ textAlign: "left" }}
                      >{`<?= $voucher; ?>`}</Tables.Td>
                    </Tables.Tr>
                    <Tables.Tr>
                      <Tables.Td
                        style={{ textAlign: "right" }}
                      >{`Password : `}</Tables.Td>
                      <Tables.Td
                        style={{ textAlign: "left" }}
                      >{`<?= $password; ?>`}</Tables.Td>
                    </Tables.Tr>
                    <Tables.Tr>
                      <Tables.Td
                        style={{ textAlign: "right" }}
                      >{`Price : `}</Tables.Td>
                      <Tables.Td
                        style={{ textAlign: "left" }}
                      >{`<?= $price; ?>`}</Tables.Td>
                    </Tables.Tr>
                    <Tables.Tr>
                      <Tables.Td
                        style={{ textAlign: "right" }}
                      >{`Profile : `}</Tables.Td>
                      <Tables.Td
                        style={{ textAlign: "left" }}
                      >{`<?= $profile; ?>`}</Tables.Td>
                    </Tables.Tr>
                  </Tables.Body>
                </Table>
              </Accordion.Body>
            </Accordion.Item>
          </Accordion>
        </Div>
      </Card>
      <Card style={action === "" ? { height: "auto" } : { height: "615px" }}>
        <Div style={{ margin: "10px" }}>
          <ButtonGroup>
            {action !== actCreated ? (
              <>
                <ToggleButton
                  variant={"outline-primary"}
                  style={{ padding: "3px 3px 3px 7px" }}
                  onClick={() => {
                    setAction(actCreated);
                    defaultTemplate();
                  }}
                >
                  <Icon style={{ fontSize: "20px", marginLeft: "0px" }}>
                    add_circle
                  </Icon>
                </ToggleButton>
                {action === "" ? (
                  ""
                ) : (
                  <ToggleButton
                    variant={"outline-danger"}
                    style={{ padding: "3px 3px 3px 7px" }}
                    onClick={deleteTemplate}
                  >
                    <Icon style={{ fontSize: "20px", marginLeft: "0px" }}>
                      delete
                    </Icon>
                  </ToggleButton>
                )}
              </>
            ) : (
              <>
                <Form.Control
                  type={"text"}
                  placeholder="Template Name"
                  value={object1.name}
                  onChange={(e) => updateData(e.target.value)}
                />
              </>
            )}
            {action === "" ? (
              ""
            ) : (
              <ToggleButton
                variant={"outline-success"}
                style={{ padding: "3px 3px 3px 7px" }}
                onClick={saveTemplate}
              >
                <Icon style={{ fontSize: "20px", marginLeft: "0px" }}>
                  save
                </Icon>
              </ToggleButton>
            )}
          </ButtonGroup>
        </Div>
        {action === "" ? (
          ""
        ) : (
          <CodeMirror
            value={other1}
            options={options}
            onBeforeChange={(editor, data, value) => {
              actions.service.hotspot.template.setOther1(dispatch, value);
            }}
          />
        )}
      </Card>
    </LayoutHome>
  );
};

export default HotspotTemplate;
