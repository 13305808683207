import { config } from "../../../../utils/StaticString";
import ObjToFormData from "../../ObjToFormData";
import request from "../../request";

const baseUrl = config.baseUrl + "service/hotspot/active/";

const _read = async (dispatch) => {
  const newPayload = ObjToFormData({});
  return await request(dispatch, newPayload, baseUrl + "read");
};
const _reset = async (dispatch, payload) => {
  const newPayload = ObjToFormData(payload);
  return await request(dispatch, newPayload, baseUrl + "reset");
};
const _async = async (dispatch, payload) => {
  const newPayload = ObjToFormData(payload);
  return await request(dispatch, newPayload, baseUrl + "async");
};
const _updateServer = async (dispatch, payload) => {
  const newPayload = ObjToFormData(payload);
  return await request(dispatch, newPayload, baseUrl + "updateServer");
};
const _getListServerHotspotMikrotik = async (dispatch, payload) => {
  const newPayload = ObjToFormData(payload);
  return await request(
    dispatch,
    newPayload,
    baseUrl + "getListServerHotspotMikrotik"
  );
};

export const active = {
  _read,
  _reset,
  _async,
  _updateServer,
  _getListServerHotspotMikrotik,
};
