import { config } from "../../utils/StaticString";
import ObjToFormData from "../../utils/ObjToFormData";

import CriptoJS from "../../utils/CriptoJS";
import request from "../service/request";

const actionType = {
  setLoader: "actions.global.setLoader",
  setLoaderText: "actions.global.setLoaderText",
  setNavigate: "actions.global.setNavigate",
  setDataUser: "actions.global.setDataUser",
  setDataOrg: "actions.global.setDataOrg",
  setAccessMenu: "actions.global.setAccessMenu",
  setAccessCRUD: "actions.global.setAccessCRUD",
  setGlobalLayout: "actions.global.setGlobalLayout",
  setListHarga: "actions.global.setListHarga",

  setDefault: "actions.global.setDefault",

  setModalAction: "actions.global.setModalAction",
  setModalShow: "actions.global.modalShow",
  setModalTitleHeader: "actions.global.setModalTitleHeader",
  setModalBtnClose: "actions.global.setModalBtnClose",
};

const setNavigate = (dispatch, data) => {
  dispatch({
    type: actionType.setNavigate,
    payload: data,
  });
};
const setLoader = (dispatch, data) => {
  dispatch({
    type: actionType.setLoader,
    payload: data,
  });
};
const setLoaderText = (dispatch, data) => {
  dispatch({
    type: actionType.setLoaderText,
    payload: data,
  });
};
const setDataUser = (dispatch, data) => {
  dispatch({
    type: actionType.setDataUser,
    payload: data,
  });
};
const setDataOrg = (dispatch, data) => {
  dispatch({
    type: actionType.setDataOrg,
    payload: data,
  });
};
const setAccessMenu = (dispatch, data) => {
  if (data) {
    data = new CriptoJS().decript(data);
  }
  dispatch({
    type: actionType.setAccessMenu,
    payload: data,
  });
};
const setAccessCRUD = (dispatch, data) => {
  dispatch({
    type: actionType.setAccessCRUD,
    payload: data,
  });
};
const setGlobalLayout = (dispatch, data) => {
  dispatch({
    type: actionType.setGlobalLayout,
    payload: data,
  });
};
const setListHarga = (dispatch, data) => {
  dispatch({
    type: actionType.setListHarga,
    payload: data,
  });
};

const setDefault = async (dispatch) => {
  dispatch({ type: actionType.setDefault, payload: "" });
};

const setModalAction = async (dispatch, data) => {
  dispatch({ type: actionType.setModalAction, payload: data });
};
const setModalShow = async (dispatch, data) => {
  dispatch({ type: actionType.setModalShow, payload: data });
};
const setModalTitleHeader = async (dispatch, data) => {
  dispatch({ type: actionType.setModalTitleHeader, payload: data });
};
const setModalBtnClose = async (dispatch, data) => {
  dispatch({ type: actionType.setModalBtnClose, payload: data });
};
const baseUrl = config.baseUrl + "user/";
const setThemeToSQL = async (dispatch, payload) => {
  const newPayload = ObjToFormData(payload);
  return await request(dispatch, newPayload, baseUrl + "update");
};

export const global = {
  actionType,
  setLoader,
  setLoaderText,
  setNavigate,
  setDataUser,
  setDataOrg,
  setAccessMenu,
  setAccessCRUD,
  setGlobalLayout,
  setListHarga,
  setThemeToSQL,
  setDefault,
  setModalAction,
  setModalShow,
  setModalTitleHeader,
  setModalBtnClose,
};
