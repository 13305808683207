import React from "react";
import { Provider } from "react-redux";
import store from "../store";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import { PageMenu } from "./PageMenu";
import CheckData from "../components/Layout/CheckData";

import Auth from "../pages/Auth";
import Primary from "../pages/Primary";
import Master from "../pages/Master";
import Service from "../pages/Service";
import Finance from "../pages/Finance";

const PageRoute = () => {
  return (
    <Provider store={store}>
      <BrowserRouter>
        <Routes>
          <Route path={"/"} element={<CheckData />} />
          <Route path={PageMenu.d_sigIn} element={<Auth.SignIn />} />
          <Route
            path={PageMenu.d_sigIn + "/:ref"}
            element={<Auth.Refferal />}
          />
          <Route path={PageMenu.d_home} element={<Primary.Home />} />
          <Route path={PageMenu.d_user} element={<Primary.User />} />
          <Route path={PageMenu.d_menus} element={<Primary.MenuPages />} />
          <Route path={PageMenu.m_settings} element={<Master.Setting />} />
          <Route path={PageMenu.m_access} element={<Master.Access />} />
          <Route path={PageMenu.m_org} element={<Master.Organization />} />
          <Route path={PageMenu.m_mikrotik} element={<Master.Mikrotik />} />
          <Route path={PageMenu.m_whatsapp} element={<Master.Whatsapp />} />
          <Route path={PageMenu.m_message} element={<Master.Message />} />
          <Route path={PageMenu.m_users} element={<Master.Users />} />
          <Route
            path={PageMenu.sh_setting}
            element={<Service.HotspotSetting />}
          />
          <Route
            path={PageMenu.sh_profile}
            element={<Service.HotspotProfile />}
          />
          <Route
            path={PageMenu.sh_voucher}
            element={<Service.HotspotVoucher />}
          />
          <Route
            path={PageMenu.sh_active}
            element={<Service.HotspotActive />}
          />
          <Route
            path={PageMenu.sh_online}
            element={<Service.HotspotOnline />}
          />
          <Route
            path={PageMenu.sh_history}
            element={<Service.HotspotHistory />}
          />
          <Route
            path={PageMenu.sh_template}
            element={<Service.HotspotTemplate />}
          />
          <Route
            path={PageMenu.ss_profile}
            element={<Service.StaticProfile />}
          />
          <Route path={PageMenu.sp_profile} element={<Service.PPoEProfile />} />
          <Route path={PageMenu.f_org} element={<Finance.Organization />} />
          <Route path={PageMenu.f_user} element={<Finance.User />} />
          <Route path={"/*"} element={<CheckData />} />
        </Routes>
      </BrowserRouter>
    </Provider>
  );
};
export default PageRoute;
