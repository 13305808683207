import Swal from "sweetalert2";

class Swalert {
  success = (value) => {
    return Swal.fire({
      icon: "success",
      title: "Success",
      text: value,
      confirmButtonText: "Ok",
    }).then((result) => {
      if (result.isConfirmed) {
        return true;
      }
      return false;
    });
  };
  error = (value) => {
    return Swal.fire({
      icon: "error",
      title: "Error!",
      text: value,
      confirmButtonText: "Ok",
    }).then((result) => {
      if (result.isConfirmed) {
        return true;
      }
      return false;
    });
  };

  warning = (value) => {
    return Swal.fire({
      icon: "warning",
      title: "Warning!",
      text: value,
      confirmButtonText: "Ok",
    }).then((result) => {
      if (result.isConfirmed) {
        return true;
      }
      return false;
    });
  };

  confirm = (title, textBtn) => {
    return Swal.fire({
      icon: "question",
      title: title,
      showCancelButton: true,
      confirmButtonText: textBtn,
    }).then((result) => {
      if (result.isConfirmed) {
        return true;
      }
      return false;
    });
  };
  custom = (title, html) => {
    return Swal.fire({
      title: title,
      html: html,
      showCloseButton: true,
    }).then((result) => {
      if (result.isConfirmed) {
        return true;
      }
      return false;
    });
  };
}
export default Swalert;
