import { active } from "./active";
import { history } from "./history";
import { online } from "./online";
import { profile } from "./profile";
import { template } from "./template";
import { voucher } from "./voucher";
import { setting } from "./setting";

const hotspot = {
  profile,
  setting,
  voucher,
  active,
  online,
  history,
  template,
};

export { hotspot };
