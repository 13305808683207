const actionType = {
  setArray1: "actions.finance.user.setArray1",
  addArray1: "actions.finance.user.addArray1",
  updateArray1: "actions.finance.user.updateArray1",
  deleteArray1: "actions.finance.user.deleteArray1",

  setArray2: "actions.finance.user.setArray2",
  addArray2: "actions.finance.user.addArray2",
  updateArray2: "actions.finance.user.updateArray2",
  deleteArray2: "actions.finance.user.deleteArray2",

  setArray3: "actions.finance.user.setArray3",
  addArray3: "actions.finance.user.addArray3",
  updateArray3: "actions.finance.user.updateArray3",
  deleteArray3: "actions.finance.user.deleteArray3",

  setObject1: "actions.finance.user.setObject1",
  updateObject1: "actions.finance.user.updateObject1",

  setObject2: "actions.finance.user.setObject2",
  updateObject2: "actions.finance.user.updateObject2",
};
const setArray1 = async (dispatch, payload) => {
  dispatch({ type: actionType.setArray1, payload: payload });
};
const addArray1 = async (dispatch, payload) => {
  dispatch({ type: actionType.addArray1, payload: payload });
};
const updateArray1 = async (dispatch, payload) => {
  dispatch({ type: actionType.updateArray1, payload: payload });
};
const deleteArray1 = async (dispatch, payload) => {
  dispatch({ type: actionType.deleteArray1, payload: payload });
};

const setArray2 = async (dispatch, payload) => {
  dispatch({ type: actionType.setArray2, payload: payload });
};
const addArray2 = async (dispatch, payload) => {
  dispatch({ type: actionType.addArray2, payload: payload });
};
const updateArray2 = async (dispatch, payload) => {
  dispatch({ type: actionType.updateArray2, payload: payload });
};
const deleteArray2 = async (dispatch, payload) => {
  dispatch({ type: actionType.deleteArray2, payload: payload });
};

const setArray3 = async (dispatch, payload) => {
  dispatch({ type: actionType.setArray3, payload: payload });
};
const addArray3 = async (dispatch, payload) => {
  dispatch({ type: actionType.addArray3, payload: payload });
};
const updateArray3 = async (dispatch, payload) => {
  dispatch({ type: actionType.updateArray3, payload: payload });
};
const deleteArray3 = async (dispatch, payload) => {
  dispatch({ type: actionType.deleteArray3, payload: payload });
};
const setObject1 = async (dispatch, payload) => {
  dispatch({ type: actionType.setObject1, payload: payload });
};
const updateObject1 = async (dispatch, payload) => {
  dispatch({ type: actionType.updateObject1, payload: payload });
};

const setObject2 = async (dispatch, payload) => {
  dispatch({ type: actionType.setObject2, payload: payload });
};
const updateObject2 = async (dispatch, payload) => {
  dispatch({ type: actionType.updateObject2, payload: payload });
};
export const user = {
  actionType,
  setArray1,
  addArray1,
  updateArray1,
  deleteArray1,
  setArray2,
  addArray2,
  updateArray2,
  deleteArray2,
  setArray3,
  addArray3,
  updateArray3,
  deleteArray3,
  setObject1,
  updateObject1,
  setObject2,
  updateObject2,
};
