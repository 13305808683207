import { config } from "../../../utils/StaticString";
import ObjToFormData from "../ObjToFormData";
import request from "../request";

const baseUrl = config.baseUrl + "master/access/";

const _read = async (dispatch) => {
  const newPayload = ObjToFormData({});
  return await request(dispatch, newPayload, baseUrl + "read");
};
const _created = async (dispatch, payload) => {
  const newPayload = ObjToFormData(payload);
  return await request(dispatch, newPayload, baseUrl + "created");
};
const _update = async (dispatch, payload) => {
  const newPayload = ObjToFormData(payload);
  return await request(dispatch, newPayload, baseUrl + "update");
};
const _delete = async (dispatch, payload) => {
  const newPayload = ObjToFormData(payload);
  return await request(dispatch, newPayload, baseUrl + "delete");
};

export const access = {
  _read,
  _created,
  _update,
  _delete,
};
