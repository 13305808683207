import {
  Button,
  ButtonGroup,
  Card,
  Form,
  Image,
  Modal,
  Stack,
  Tab,
  Tabs,
  ToggleButton,
} from "react-bootstrap";
import Div from "../../components/Div";
import LayoutHome from "../../components/Layout/Home";
import InputLabel from "../../components/InputLabel";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { orgLogo } from "../../assets";
import JWToken from "../../utils/JWToken";
import PhoneLabel from "../../components/PhoneLabel";
import Swalert from "../../components/Swalert";
import Span from "../../components/Span";
import EasyCrop from "../../components/Cropped/EasyCrop";
import { service } from "../../store/service";
import { actions } from "../../store/actions";
import UseLocalStorage from "../../utils/UseLocalStorage";
import { storage } from "../../utils/StaticString";
import LayoutModal from "../../components/LayoutModal";
import SelectLabel from "../../components/SelectLabel";
import Tables from "../../components/Tables";

const Organization = () => {
  const dispatch = useDispatch();
  const mOrgReducer = useSelector((state) => state.mOrgReducer);
  const { array1, array2, array3, object1, other1 } = mOrgReducer;
  const { listHarga } = useSelector((state) => state.globalReducer);

  const [dataOrg, setDataOrg] = UseLocalStorage(storage.dataOrg, false);
  const [waValidate, setWaValidate] = useState(true);

  const [tab, setTab] = useState(1);
  const [template, setTemplate] = useState(false);

  useEffect(() => {
    if (new JWToken()._getStatusAccessToken()) {
      getFirtsData();
    }
  }, []);

  useEffect(() => {
    if (template) {
      saveTemplate();
    }
  }, [template]);

  const getFirtsData = async () => {
    const result = await service.master.org._read(dispatch);
    if (result.status) {
      result.data.listMonth.map((item) => {
        item.value = item.monthYear;
        item.label = item.monthYear;
      });
      actions.master.org.setArray1(dispatch, result.data.listMonth);
      actions.master.org.setArray3(dispatch, result.data.listService);
      actions.master.org.setObject1(dispatch, result.data.dataOrg);
      actions.master.org.setOther1(dispatch, result.data.saldo);

      if (parseInt(result.data.dataOrg.templateHotspot) <= 0) {
        const protocol = window.location.protocol;
        const host = window.location.host;
        const templateDefault = protocol + "//" + host + "/template.txt";
        fetch(templateDefault)
          .then((response) => response.text())
          .then((response) => setTemplate(response));
      }
    }
  };
  const saveTemplate = async () => {
    const newBlob = new Blob([template], {
      type: "text/plain;charset=utf-8",
    });
    const dataImage = {
      file: newBlob,
      id: "",
      name: "Default",
      fileName: "template_default",
    };
    await service.layanan.hotspot.template._update(dispatch, dataImage);
  };
  async function openModal(header, item) {
    actions.global.setModalShow(dispatch, true);
    actions.global.setModalTitleHeader(dispatch, header);
    await actions.master.org.setObject1(dispatch, item);
    return;
  }
  function handleChangeWA(value, country) {
    const contryCode = country.dialCode;
    if (value.length > contryCode.length) {
      if (value.slice(0, contryCode.length) === contryCode) {
        if (value.slice(contryCode.length, contryCode.length + 1) !== "0") {
          setWaValidate(true);
        } else {
          setWaValidate(false);
        }
      } else {
        setWaValidate(false);
      }
    } else {
      setWaValidate(false);
    }
    updateData("noWaNotif", value);
  }
  const updateData = (name, value) => {
    const newData = { [name]: value };
    actions.master.org.updateObject1(dispatch, newData);
  };
  const saveDetail = async () => {
    if (!waValidate) {
      return new Swalert().error("Berikan Nomor Whatsapp yang valid");
    }
    const newData = {
      orgName: object1.orgName,
      orgDetail: object1.orgDetail,
      resellerPPN: object1.resellerPPN,
      ppoePPN: object1.ppoePPN,
      noWaNotif: object1.noWaNotif,
    };
    const result = await service.master.org._update(dispatch, newData);
    if (result.status) {
      const newDataLocal = {
        ...dataOrg,
        orgName: result.data.orgName,
        orgIcon: result.data.orgIcon,
      };

      setDataOrg(newDataLocal);
      actions.global.setModalShow(dispatch, false);
      actions.global.setDataOrg(dispatch, newDataLocal);
      actions.master.org.setObject1(dispatch, result.data);
      return new Swalert().success("Update Data Success");
    }
    return new Swalert().error(result.data);
  };
  const getMutasiCoin = async (monthYear) => {
    const newData = { monthYear: monthYear };
    const result = await service.master.org._mutasiCoin(dispatch, newData);
    if (result.status) {
      actions.master.org.setArray2(dispatch, result.data);
      return;
    }
    return new Swalert().warning("Get Mutasi Failed");
  };

  const clickSelect = useRef();
  const clickCrop = useRef();
  async function updateLogoOrg() {
    clickCrop.current?.childFunctionCrop();
  }
  function layoutModalLogo() {
    const saveImageCrop = async ({ status, response }) => {
      if (!status) {
        return new Swalert().warning("Please, select new image");
      }
      const dataImage = { image: response };
      const result = await service.master.org._logo(dispatch, dataImage);
      if (result.status) {
        const newDataLocal = {
          ...dataOrg,
          orgName: result.data.orgName,
          orgIcon: result.data.orgIcon,
        };
        setDataOrg(newDataLocal);
        actions.global.setModalShow(dispatch, false);
        actions.global.setDataOrg(dispatch, newDataLocal);
        actions.master.org.setObject1(dispatch, result.data);
        return new Swalert().success("Update Success");
      }
      return new Swalert().error(result.data);
    };
    return (
      <>
        <Button
          style={{ marginBottom: "10px" }}
          onClick={() => {
            clickSelect.current?.childFunctionSelect();
          }}
        >
          <Span>Select File</Span>
        </Button>
        <EasyCrop
          image={
            object1 ? (object1.orgIcon ? object1.orgIcon : orgLogo) : orgLogo
          }
          rHeight={5}
          rWidth={5}
          clickSelect={clickSelect}
          clickCrop={clickCrop}
          clickSave={saveImageCrop}
        />
      </>
    );
  }
  function layoutDetail() {
    return (
      <>
        <Image
          alt="img"
          onClick={() => openModal("Update Organization Logo", object1)}
          src={
            object1 ? (object1.orgIcon ? object1.orgIcon : orgLogo) : orgLogo
          }
          style={{
            width: "100px",
            borderRadius: "5px",
            cursor: "pointer",
            margin: "20px",
          }}
        />
        <Div style={{ margin: "20px" }}>
          <InputLabel
            title={"Name Orginization"}
            name={"orgName"}
            value={object1.orgName}
            required
            onChange={(e) => updateData(e.target.name, e.target.value)}
          />
          <InputLabel
            title={"Detail Orginization"}
            name={"orgDetail"}
            value={object1.orgDetail}
            required
            onChange={(e) => updateData(e.target.name, e.target.value)}
          />
          <InputLabel
            title={"PPN reseller (jika ada)"}
            type={"number"}
            name={"resellerPPN"}
            value={object1.resellerPPN}
            required
            onChange={(e) => updateData(e.target.name, e.target.value)}
          />
          <InputLabel
            title={"PPN PPoE & Static (jika ada)"}
            type={"number"}
            name={"ppoePPN"}
            value={object1.ppoePPN}
            required
            onChange={(e) => updateData(e.target.name, e.target.value)}
          />

          <PhoneLabel
            title="Whatsapp Bot / Gateway"
            value={object1.noWaNotif}
            onChange={handleChangeWA}
          />
          <Button variant="outline-primary" onClick={saveDetail}>
            Simpan
          </Button>
        </Div>
      </>
    );
  }
  function layuotSaldo() {
    return (
      <>
        <ButtonGroup style={{ marginBottom: "30px", marginTop: "10px" }}>
          <ToggleButton variant="outline-success" size="sm">
            SALDO
          </ToggleButton>
          <ToggleButton variant="outline-success" size="sm">
            {setRupiah(other1)}
          </ToggleButton>
        </ButtonGroup>
        <SelectLabel
          title={"Mutasi Saldo"}
          options={array1}
          onChange={(e) => getMutasiCoin(e.value)}
        />
        {setLayoutTableMutasi()}
      </>
    );
  }
  function layoutServie() {
    return (
      <>
        {setLayoutTableStaticPrice()}
        <Div style={{ marginTop: "10px" }}>
          {listHarga.map((item, index) => {
            return (
              <>
                <Card.Body>
                  <Card.Title>{item.name}</Card.Title>
                  <Card.Text>{item.information}</Card.Text>
                </Card.Body>
              </>
            );
          })}
        </Div>
      </>
    );
  }
  function setLayoutTableMutasi() {
    function dataBodyMutasi() {
      if (!array2.mutasi) {
        return;
      }
      var sum = parseInt(array2.saldoAwal);
      return array2.mutasi.map((item, index) => {
        var debet = "";
        var kredit = "";
        sum += parseInt(item.cost);
        if (parseInt(item.cost) > 0) {
          debet = setRupiah(item.cost);
        } else {
          kredit = setRupiah(item.cost);
        }
        return (
          <Tables.Tr>
            <Tables.Td>{index + 2}</Tables.Td>
            <Tables.Td>{item.dateTime}</Tables.Td>
            <Tables.Td>{item.invoice}</Tables.Td>
            <Tables.Td>{item.information}</Tables.Td>
            <Tables.Td>{debet}</Tables.Td>
            <Tables.Td>{kredit}</Tables.Td>
            <Tables.Td>{setRupiah(sum)}</Tables.Td>
          </Tables.Tr>
        );
      });
    }
    return (
      <Tables.Table>
        <Tables.Head>
          <Tables.Tr>
            <Tables.Th>#</Tables.Th>
            <Tables.Th>DATE</Tables.Th>
            <Tables.Th>INVOICE</Tables.Th>
            <Tables.Th>KETERANGAN</Tables.Th>
            <Tables.Th>DEBET</Tables.Th>
            <Tables.Th>KREDIT</Tables.Th>
            <Tables.Th>SALDO</Tables.Th>
          </Tables.Tr>
        </Tables.Head>
        <Tables.Body>
          {array2.saldoAwal ? (
            <Tables.Tr style={{ cursor: "pointer" }}>
              <Tables.Td>1</Tables.Td>
              <Tables.Td colspan={5}>Saldo Awal</Tables.Td>
              <Tables.Td>{setRupiah(array2.saldoAwal)}</Tables.Td>
            </Tables.Tr>
          ) : (
            ""
          )}
          {dataBodyMutasi()}
        </Tables.Body>
      </Tables.Table>
    );
  }
  function setLayoutTableStaticPrice() {
    function dataBody() {
      return listHarga.map((item, index) => {
        function promo() {
          for (let index = 0; index < array3.length; index++) {
            const el = array3[index];
            if (el.service === item.service) {
              return el.promo;
            }
          }
          return item.promo;
        }
        function checked() {
          for (let index = 0; index < array3.length; index++) {
            const el = array3[index];
            if (el.service === item.service) {
              return true;
            }
          }
          return false;
        }
        function totalLayananMirotik() {
          var total = 0;
          for (let index = 0; index < array3.length; index++) {
            const el = array3[index];
            if (el.service === item.service) {
              if (el.service === "serviceMikrotik") {
                total++;
              }
            }
          }
          return total;
        }
        return (
          <Tables.Tr>
            <Tables.Td>{index + 1}</Tables.Td>
            <Tables.Td>{item.name}</Tables.Td>
            <Tables.Td>
              <del>{setRupiah(item.cost)}</del>
              <br></br>
              <b style={{ fontSize: "16px" }}>{setRupiah(promo())}</b>
            </Tables.Td>
            <Tables.Td>
              {totalLayananMirotik() === 0 ? (
                <Form.Check checked={checked()} aria-label={"opt-" + index} />
              ) : (
                totalLayananMirotik()
              )}
            </Tables.Td>
          </Tables.Tr>
        );
      });
    }

    return (
      <>
        <Tables.Table>
          <Tables.Head>
            <Tables.Tr>
              <Tables.Th>#</Tables.Th>
              <Tables.Th>SERVICE</Tables.Th>
              <Tables.Th>PRICE/DAY</Tables.Th>
              <Tables.Th>STATUS</Tables.Th>
            </Tables.Tr>
          </Tables.Head>
          <Tables.Body>{dataBody()}</Tables.Body>
        </Tables.Table>
      </>
    );
  }
  function setRupiah(value) {
    return new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
      minimumFractionDigits: 0,
    }).format(value);
  }
  return (
    <LayoutHome>
      <Card>
        <LayoutModal onSave={updateLogoOrg} align={"center"}>
          {layoutModalLogo()}
        </LayoutModal>
        <Div style={{ margin: "5px" }}>
          <ButtonGroup>
            <ToggleButton
              variant="outline-primary"
              size="sm"
              onClick={() => setTab(1)}
            >
              DETAIL
            </ToggleButton>
            <ToggleButton
              variant="outline-warning"
              size="sm"
              onClick={() => setTab(2)}
            >
              SALDO
            </ToggleButton>
            <ToggleButton
              variant="outline-danger"
              size="sm"
              onClick={() => setTab(3)}
            >
              SERVICE
            </ToggleButton>
          </ButtonGroup>
          <Div
            style={{
              marginTop: "10px",
              marginBottom: "10px",
              border: "1px solid ",
            }}
          ></Div>
          {tab === 1 ? layoutDetail() : ""}
          {tab === 2 ? layuotSaldo() : ""}
          {tab === 3 ? layoutServie() : ""}
        </Div>
      </Card>
    </LayoutHome>
  );
};

export default Organization;
