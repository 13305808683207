import { config } from "../../../utils/StaticString";
import ObjToFormData from "../ObjToFormData";
import request from "../request";

const baseUrl = config.baseUrl + "master/setting/";

const _read = async (dispatch) => {
  const newPayload = ObjToFormData({});
  return await request(dispatch, newPayload, baseUrl + "read");
};
const _updateSocket = async (dispatch, payload) => {
  const newPayload = ObjToFormData(payload);
  return await request(dispatch, newPayload, baseUrl + "updateSocket");
};
const _updateSocketAll = async (dispatch, payload) => {
  const newPayload = ObjToFormData(payload);
  return await request(dispatch, newPayload, baseUrl + "updateSocketAll");
};
const _updatePriceOrg = async (dispatch, payload) => {
  const newPayload = ObjToFormData(payload);
  return await request(dispatch, newPayload, baseUrl + "updatePriceOrg");
};
const _updateStaticPrice = async (dispatch, payload) => {
  const newPayload = ObjToFormData(payload);
  return await request(dispatch, newPayload, baseUrl + "updateStaticPrice");
};
const _topupCoin = async (dispatch, payload) => {
  const newPayload = ObjToFormData(payload);
  return await request(dispatch, newPayload, baseUrl + "topupCoin");
};
const _getMonth = async (dispatch, payload) => {
  const newPayload = ObjToFormData(payload);
  return await request(dispatch, newPayload, baseUrl + "getMonth");
};
const _mutasiCoin = async (dispatch, payload) => {
  const newPayload = ObjToFormData(payload);
  return await request(dispatch, newPayload, baseUrl + "mutasiCoin");
};

export const setting = {
  _read,
  _updateSocket,
  _updateSocketAll,
  _updatePriceOrg,
  _updateStaticPrice,
  _topupCoin,
  _getMonth,
  _mutasiCoin,
};
