import { useDispatch, useSelector } from "react-redux";
import UseLocalStorage from "../../../utils/UseLocalStorage";
import { config, storage } from "../../../utils/StaticString";
import { useEffect, useState } from "react";
import { actions } from "../../../store/actions";
import { Helmet } from "react-helmet";
import LoadingOverlay from "react-loading-overlay";
import Div from "../../Div";
import { Breadcrumb, Card } from "react-bootstrap";
import Sidebar from "./Sidebar";
import Header from "./Header";
import { useNavigate } from "react-router-dom";
import { orgLogo } from "../../../assets";
import JWToken from "../../../utils/JWToken";
import CheckData from "../CheckData";

export default function LayoutHome({ children }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const globalReducer = useSelector((state) => state.globalReducer);
  const { loader, loaderText, dataOrg, accessMenu, dataGlobal } = globalReducer;

  const [listHarga] = UseLocalStorage(storage.priceList, false);
  const [localDataUser] = UseLocalStorage(storage.dataUser, false);
  const [localDataOrg] = UseLocalStorage(storage.dataOrg, false);
  const [localDataMenus] = UseLocalStorage(storage.accessMenu, false);
  const [localDataThemes] = UseLocalStorage(storage.themes, false);

  const [styleApp, setStyleApp] = useState(false);

  useEffect(() => {
    setSidebar(styleApp);
  }, [styleApp]);

  const actSidebar = () => {
    setStyleApp((styleApp) => !styleApp);
  };

  useEffect(() => {
    if (accessMenu) {
      actions.global.setGlobalLayout(dispatch, { headerName: setHeaderName() });
      actions.global.setGlobalLayout(dispatch, {
        setHeaderName: setHeaderName,
      });
      accessCRUD();
    }
  }, [accessMenu]);

  useEffect(() => {
    updateGlobalReducer();
  }, []);

  const setSidebar = (sidebarOpen) => {
    const { innerWidth, innerHeight } = window;
    if (innerWidth <= 1199) {
      if (sidebarOpen) {
        actions.global.setGlobalLayout(dispatch, {
          styleApp: "app sidebar-hidden",
        });
        actions.global.setGlobalLayout(dispatch, {
          styleIconDark: { display: "none" },
        });
      } else {
        actions.global.setGlobalLayout(dispatch, { styleApp: "app" });
        setTimeout(() => {
          actions.global.setGlobalLayout(dispatch, {
            styleIconDark: { display: "block" },
          });
        }, 200);
      }
    }
  };

  const navigateReplaceTrue = (path) => {
    navigate(path, { replace: true });
  };

  const updateGlobalReducer = async () => {
    actions.global.setDefault(dispatch);
    actions.global.setNavigate(dispatch, navigateReplaceTrue);
    actions.global.setDataUser(dispatch, localDataUser);
    actions.global.setDataOrg(dispatch, localDataOrg);
    actions.global.setAccessMenu(dispatch, localDataMenus);
    actions.global.setListHarga(dispatch, listHarga);

    actions.global.setGlobalLayout(dispatch, {
      styleIconDark: { display: "block" },
    });
    actions.global.setGlobalLayout(dispatch, { actSidebar: actSidebar });
    actions.global.setGlobalLayout(dispatch, { sidebarOpen: false });
    actions.global.setGlobalLayout(dispatch, { colorTheme: localDataThemes });
    actions.global.setGlobalLayout(dispatch, { styleApp: "app" });
  };

  const setHeaderName = () => {
    const pathname = window.location.pathname;
    for (let index = 0; index < accessMenu.length; index++) {
      const item = accessMenu[index];
      if (item.path === pathname) {
        return item.label.toUpperCase();
      }
    }
  };

  const accessCRUD = () => {
    const pathname = window.location.pathname;
    let actCreated = false;
    let actUpdate = false;
    let actDelete = false;

    let permission = [];
    for (let index = 0; index < accessMenu.length; index++) {
      const item = accessMenu[index];
      if (item.path === pathname) {
        permission = item.permission;
      }
    }

    for (let index = 0; index < permission.length; index++) {
      const access = permission[index];
      if (index === 0) {
        actCreated = Boolean(access);
      } else if (index === 1) {
        actUpdate = Boolean(access);
      } else if (index === 2) {
        actDelete = Boolean(access);
      }
    }

    const crud = {
      accCreated: actCreated,
      accUpdate: actUpdate,
      accDelete: actDelete,
    };
    actions.global.setAccessCRUD(dispatch, crud);
  };
  const accessPage = () => {
    if (accessMenu !== "") {
      const pathname = window.location.pathname;
      if (accessMenu) {
        for (let index = 0; index < accessMenu.length; index++) {
          const item = accessMenu[index];
          if (item.path === pathname) {
            return true;
          }
        }
      } else {
        return false;
      }
    } else {
      return false;
    }
  };
  const getSlugPath = () => {
    const protocol = window.location.protocol;
    const host = window.location.host;
    const baseUrl = protocol + "//" + host;
    var pathname = window.location.pathname;
    const myArray = pathname.split("/");
    if (myArray.length > 2) {
      const rowLen = myArray.length;
      function slug() {
        return myArray.map((item, i) => {
          if (rowLen === i + 1) {
            return <Breadcrumb.Item active>{item}</Breadcrumb.Item>;
          } else {
            return (
              <Breadcrumb.Item href={baseUrl + pathname}>
                {item}
              </Breadcrumb.Item>
            );
          }
        });
      }
      return <Breadcrumb>{slug()}</Breadcrumb>;
    }
    return "";
  };
  const accessToken = () => {
    return new JWToken()._getStatusAccessToken();
  };

  const cekAccessToken = accessToken();
  const cekAccessPage = accessPage();
  if (cekAccessToken) {
    if (cekAccessPage) {
      return (
        <>
          <Helmet>
            <title>
              {dataGlobal.headerName + " | "}
              {dataOrg
                ? dataOrg.orgName
                  ? dataOrg.orgName
                  : config.orgName
                : config.orgName}
            </title>
            <link
              rel="icon"
              href={
                dataOrg
                  ? dataOrg.orgIcon
                    ? dataOrg.orgIcon
                    : orgLogo
                  : orgLogo
              }
            />
          </Helmet>
          <LoadingOverlay
            active={loader}
            spinner
            text={loaderText}
            styles={{
              wrapper: {
                overflow: "none",
              },
            }}
          />
          <Div className={dataGlobal.styleApp}>
            <Div className="hide-app-sidebar-mobile" onClick={actSidebar}></Div>
            <Div className="app-sidebar">
              <Sidebar />
            </Div>
            <Div className="app-container">
              <Div className="layout-header">
                <Header />
              </Div>
              <Div className="app-content">
                <Div style={{ margin: "10px" }}>{getSlugPath()}</Div>
                {children}
              </Div>
            </Div>
          </Div>
        </>
      );
    } else {
      return "";
    }
  } else {
    return <CheckData />;
  }
}
