import { config } from "../../../utils/StaticString";
import ObjToFormData from "../ObjToFormData";
import request from "../request";

const baseUrl = config.baseUrl + "home/";

const _read = async (dispatch) => {
  const newPayload = ObjToFormData({});
  return await request(dispatch, newPayload, baseUrl + "read");
};

export const home = {
  _read,
};
