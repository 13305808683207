import kabkota from "./kabkota.json";
class _IdnKab {
  getAllData = () => {
    kabkota.map((item) => {
      item.value = item.indID;
      item.label = item.indName;
    });
    return kabkota;
  };
  getById = (indID) => {
    for (let index = 0; index < kabkota.length; index++) {
      const el = kabkota[index];
      if (el.indID === indID) {
        return el;
      }
    }
  };
}

export default _IdnKab;
