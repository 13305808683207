import List from "./../../components/List";
import Span from "../Span";
import Icon from "../Icon";
import Div from "../Div";
import CriptoJS from "../../utils/CriptoJS";
import UseLocalStorage from "../../utils/UseLocalStorage";
import { storage } from "../../utils/StaticString";

import { useState } from "react";

const cekPathActive = (label, children) => {
  const pathname = window.location.pathname;
  if (children) {
    if (children.length > 0) {
      for (let index = 0; index < children.length; index++) {
        const el = children[index];
        if (el.props.children) {
          return cekPathActive(label, el.props.children);
        } else {
          if (pathname === el.props.path) {
            return true;
          }
        }
      }
    } else {
      if (children.props.children) {
        return cekPathActive(label, children.props.children);
      }
      if (pathname === children.props.path) {
        return true;
      }
      return false;
    }
  }
};

const cekPathAccess = (label, children) => {
  const [accessMenu] = UseLocalStorage(storage.accessMenu, "");
  const access = new CriptoJS().decript(accessMenu);

  if (children) {
    if (children.length > 0) {
      for (let index = 0; index < children.length; index++) {
        const el = children[index];
        if (el.props.children) {
          return cekPathAccess(label, el.props.children);
        } else {
          for (let a = 0; a < access.length; a++) {
            const item = access[a];
            if (item.path === el.props.path) {
              return true;
            }
          }
        }
      }
    } else {
      if (children.props.children) {
        return cekPathAccess(label, children.props.children);
      } else {
        for (let a = 0; a < access.length; a++) {
          const item = access[a];
          if (item.path === children.props.path) {
            return true;
          }
        }
        return false;
      }
    }
  } else {
    return false;
  }
};

const MenuColapse = ({ label, icon, children }) => {
  let active = cekPathActive(label, children);
  let access = cekPathAccess(label, children);
  const [hideChild, setHideChild] = useState(!active);
  return (
    <List.li hidden={access ? false : true}>
      <Div
        onClick={() => setHideChild(!hideChild)}
        className={active ? "active" : ""}
      >
        {icon !== undefined ? <Icon>{icon}</Icon> : ""}
        <Span>{label}</Span>
      </Div>
      <List.ul hidden={hideChild}>{children}</List.ul>
    </List.li>
  );
};

export default MenuColapse;
