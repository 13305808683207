import { config } from "../../../utils/StaticString";
import ObjToFormData from "../ObjToFormData";
import request from "../request";

const baseUrl = config.baseUrl + "user/";

const _read = async (dispatch) => {
  const newPayload = ObjToFormData({});
  return await request(dispatch, newPayload, baseUrl + "read");
};
const _update = async (dispatch, payload) => {
  const newPayload = ObjToFormData(payload);
  return await request(dispatch, newPayload, baseUrl + "update");
};
const _profile = async (dispatch, payload) => {
  const newPayload = ObjToFormData(payload);
  return await request(dispatch, newPayload, baseUrl + "profile");
};
const _newUserLogin = async (dispatch, payload) => {
  const newPayload = ObjToFormData(payload);
  return await request(dispatch, newPayload, baseUrl + "newUserLogin");
};
const _newPasswordLogin = async (dispatch, payload) => {
  const newPayload = ObjToFormData(payload);
  return await request(dispatch, newPayload, baseUrl + "newPasswordLogin");
};
const _createdNewLogin = async (dispatch, payload) => {
  const newPayload = ObjToFormData(payload);
  return await request(dispatch, newPayload, baseUrl + "createdNewLogin");
};

export const user = {
  _read,
  _update,
  _profile,
  _newUserLogin,
  _newPasswordLogin,
  _createdNewLogin,
};
