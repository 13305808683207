import axios from "axios";

export default async function GetLocalConfig() {
  const protocol = window.location.protocol;
  const host = window.location.host;
  const baseUrl = protocol + "//" + host + "/baseConfig.json";
  try {
    const req = await axios.get(baseUrl);
    return req;
  } catch (e) {
    console.log(e);
  }
}
