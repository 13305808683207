const actionType = {
  setArray1: "actions.primary.org.setArray1",
  addArray1: "actions.primary.org.addArray1",
  updateArray1: "actions.primary.org.updateArray1",
  deleteArray1: "actions.primary.org.deleteArray1",

  setArray2: "actions.primary.org.setArray2",
  addArray2: "actions.primary.org.addArray2",
  updateArray2: "actions.primary.org.updateArray2",
  deleteArray2: "actions.primary.org.deleteArray2",

  setArray3: "actions.primary.org.setArray3",
  addArray3: "actions.primary.org.addArray3",
  updateArray3: "actions.primary.org.updateArray3",
  deleteArray3: "actions.primary.org.deleteArray3",

  setObject1: "actions.primary.org.setObject1",
  updateObject1: "actions.primary.org.updateObject1",

  setOther1: "actions.primary.org.other1",
};
const setArray1 = async (dispatch, payload) => {
  dispatch({ type: actionType.setArray1, payload: payload });
};
const addArray1 = async (dispatch, payload) => {
  dispatch({ type: actionType.addArray1, payload: payload });
};
const updateArray1 = async (dispatch, payload) => {
  dispatch({ type: actionType.updateArray1, payload: payload });
};
const deleteArray1 = async (dispatch, payload) => {
  dispatch({ type: actionType.deleteArray1, payload: payload });
};

const setArray2 = async (dispatch, payload) => {
  dispatch({ type: actionType.setArray2, payload: payload });
};
const addArray2 = async (dispatch, payload) => {
  dispatch({ type: actionType.addArray2, payload: payload });
};
const updateArray2 = async (dispatch, payload) => {
  dispatch({ type: actionType.updateArray2, payload: payload });
};
const deleteArray2 = async (dispatch, payload) => {
  dispatch({ type: actionType.deleteArray2, payload: payload });
};

const setArray3 = async (dispatch, payload) => {
  dispatch({ type: actionType.setArray3, payload: payload });
};
const addArray3 = async (dispatch, payload) => {
  dispatch({ type: actionType.addArray3, payload: payload });
};
const updateArray3 = async (dispatch, payload) => {
  dispatch({ type: actionType.updateArray3, payload: payload });
};
const deleteArray3 = async (dispatch, payload) => {
  dispatch({ type: actionType.deleteArray3, payload: payload });
};

const setObject1 = async (dispatch, payload) => {
  dispatch({ type: actionType.setObject1, payload: payload });
};
const updateObject1 = async (dispatch, payload) => {
  dispatch({ type: actionType.updateObject1, payload: payload });
};
const setOther1 = async (dispatch, payload) => {
  dispatch({ type: actionType.setOther1, payload: payload });
};

export const org = {
  actionType,
  setArray1,
  addArray1,
  updateArray1,
  deleteArray1,
  setArray2,
  addArray2,
  updateArray2,
  deleteArray2,
  setArray3,
  addArray3,
  updateArray3,
  deleteArray3,
  setObject1,
  updateObject1,
  setOther1,
};
