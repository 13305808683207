import React from "react";
import { Image } from "react-bootstrap";
import Div from "../../Div";
import Span from "../../Span";
import { useSelector } from "react-redux";
import { orgLogo, profile } from "../../../assets";
import { PageMenu } from "../../../route/PageMenu";

const User = () => {
  const globalReducer = useSelector((state) => state.globalReducer);
  const { dataUser, dataOrg, navigate, dataGlobal } = globalReducer;

  const openRoute = (slug) => {
    if (dataGlobal.sidebarOpen) {
      dataGlobal.actSidebar();
    }
    navigate(slug);
  };
  return (
    <>
      <Image
        className="logo-org"
        src={
          dataOrg
            ? dataOrg.orgIcon !== ""
              ? dataOrg.orgIcon
              : orgLogo
            : orgLogo
        }
        style={{ cursor: "pointer" }}
        onClick={() => {
          openRoute(PageMenu.d_home);
        }}
      />
      <Div
        className="user"
        style={{ cursor: "pointer" }}
        onClick={() => {
          openRoute(PageMenu.d_user);
        }}
      >
        <Image src={dataUser.profile === "" ? profile : dataUser.profile} />
        <Span className="activity-indicator"></Span>
        <Div className="user-info-text">
          <Span className="user-state-info">{dataUser.user}</Span>
          <br />
          <Span>{dataUser.name}</Span>
        </Div>
      </Div>
    </>
  );
};

export default User;
