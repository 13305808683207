const actionType = {
  setArray1: "actions.primary.user.setArray1",
  addArray1: "actions.primary.user.addArray1",
  updateArray1: "actions.primary.user.updateArray1",
  deleteArray1: "actions.primary.user.deleteArray1",

  setObject1: "actions.primary.user.setObject1",
  updateObject1: "actions.primary.user.updateObject1",

  setOther1: "actions.primary.user.other1",
  setOther2: "actions.primary.user.other2",
};
const setArray1 = async (dispatch, payload) => {
  dispatch({ type: actionType.setArray1, payload: payload });
};
const addArray1 = async (dispatch, payload) => {
  dispatch({ type: actionType.addArray1, payload: payload });
};
const updateArray1 = async (dispatch, payload) => {
  dispatch({ type: actionType.updateArray1, payload: payload });
};
const deleteArray1 = async (dispatch, payload) => {
  dispatch({ type: actionType.deleteArray1, payload: payload });
};

const setObject1 = async (dispatch, payload) => {
  dispatch({ type: actionType.setObject1, payload: payload });
};
const updateObject1 = async (dispatch, payload) => {
  dispatch({ type: actionType.updateObject1, payload: payload });
};
const setOther1 = async (dispatch, payload) => {
  dispatch({ type: actionType.setOther1, payload: payload });
};
const setOther2 = async (dispatch, payload) => {
  dispatch({ type: actionType.setOther2, payload: payload });
};
export const user = {
  actionType,
  setArray1,
  addArray1,
  updateArray1,
  deleteArray1,
  setObject1,
  updateObject1,
  setOther1,
  setOther2,
};
