const actionType = {
  setArray1: "actions.primary.pages.setArray1",
  addArray1: "actions.primary.pages.addArray1",
  updateArray1: "actions.primary.pages.updateArray1",
  deleteArray1: "actions.primary.pages.deleteArray1",

  setObject1: "actions.primary.pages.setObject1",
  updateObject1: "actions.primary.pages.updateObject1",
};
const setArray1 = async (dispatch, payload) => {
  dispatch({ type: actionType.setArray1, payload: payload });
};
const addArray1 = async (dispatch, payload) => {
  dispatch({ type: actionType.addArray1, payload: payload });
};
const updateArray1 = async (dispatch, payload) => {
  dispatch({ type: actionType.updateArray1, payload: payload });
};
const deleteArray1 = async (dispatch, payload) => {
  dispatch({ type: actionType.deleteArray1, payload: payload });
};

const setObject1 = async (dispatch, payload) => {
  dispatch({ type: actionType.setObject1, payload: payload });
};
const updateObject1 = async (dispatch, payload) => {
  dispatch({ type: actionType.updateObject1, payload: payload });
};

export const pages = {
  actionType,
  setArray1,
  addArray1,
  updateArray1,
  deleteArray1,
  setObject1,
  updateObject1,
};
