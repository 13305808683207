import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import Div from "../../Div";
import PageSidebar from "../../../route/PageSidebar";
import Span from "../../Span";
import JWToken from "../../../utils/JWToken";
import User from "./User";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { actions } from "../../../store/actions";

const Sidebar = () => {
  const { navigate, dataGlobal } = useSelector((state) => state.globalReducer);
  const dispatch = useDispatch();

  useEffect(() => {
    addEvents();
    return () => {
      removeEvents();
    };
  });

  const events = ["mousemove", "keydown"];
  const addEvents = () => {
    events.forEach((eventName) => {
      window.addEventListener(eventName, eventHandler);
    });
  };
  const removeEvents = () => {
    events.forEach((eventName) => {
      window.removeEventListener(eventName, eventHandler);
    });
  };
  const eventHandler = (eventType) => {
    if (!new JWToken()._getStatusAccessToken()) {
      logOut();
    }
  };
  const logOut = () => {
    if (new JWToken()._removeCookie()) {
      navigate("/");
    }
  };

  const openRoute = (slug) => {
    var pathname = window.location.pathname;
    if (slug !== pathname) {
      actions.global.setDefault(dispatch);
    }
    navigate(slug);
    dataGlobal.actSidebar();
    dataGlobal.setHeaderName();
  };
  const d = new Date();
  let year = d.getFullYear();
  return (
    <>
      <Div className="header">
        <User />
      </Div>
      <PerfectScrollbar style={{ paddingBottom: "20px" }}>
        <PageSidebar openRoute={openRoute} logOut={logOut} />
      </PerfectScrollbar>
      <Div className="footer">
        <Div className="version">
          <b>Version: </b> 1.0.5
        </Div>
        <Div className="copyright">
          <Span>{"© 2022 - " + year} </Span>
          <a href="#">MIK SIMP</a>.
        </Div>
      </Div>
    </>
  );
};

export default Sidebar;
