import JWToken from "../../utils/JWToken";
import axios from "axios";
import { actions } from "../actions";

const request = async (dispatch, payload, urlBackend) => {
  actions.global.setLoader(dispatch, true);
  try {
    payload.append("token", new JWToken()._getValueAccessToken());
    const req = await axios.post(urlBackend, payload);
    actions.global.setLoader(dispatch, false);
    return req.data;
  } catch (e) {
    actions.global.setLoader(dispatch, false);
    const errorData = { status: false, data: e };
    console.log(errorData);
    return errorData;
  }
};

export default request;
