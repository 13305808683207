import { config } from "../../../../utils/StaticString";
import ObjToFormData from "../../ObjToFormData";
import request from "../../request";

const baseUrl = config.baseUrl + "service/hotspot/voucher/";

const _read = async (dispatch, payload) => {
  const newPayload = ObjToFormData(payload);
  return await request(dispatch, newPayload, baseUrl + "read");
};
const _created = async (dispatch, payload) => {
  const newPayload = ObjToFormData(payload);
  return await request(dispatch, newPayload, baseUrl + "created");
};
const _update = async (dispatch, payload) => {
  const newPayload = ObjToFormData(payload);
  return await request(dispatch, newPayload, baseUrl + "update");
};
const _delete = async (dispatch, payload) => {
  const newPayload = ObjToFormData(payload);
  return await request(dispatch, newPayload, baseUrl + "delete");
};
const _sync = async (dispatch, payload) => {
  const newPayload = ObjToFormData(payload);
  return await request(dispatch, newPayload, baseUrl + "sync");
};
const _updateServer = async (dispatch, payload) => {
  const newPayload = ObjToFormData(payload);
  return await request(dispatch, newPayload, baseUrl + "updateServer");
};
const _getComment = async (dispatch, payload) => {
  const newPayload = ObjToFormData(payload);
  return await request(dispatch, newPayload, baseUrl + "getComment");
};
const _getListVoucher = async (dispatch, payload) => {
  const newPayload = ObjToFormData(payload);
  return await request(dispatch, newPayload, baseUrl + "getListVoucher");
};
const _getListServerHotspotMikrotik = async (dispatch, payload) => {
  const newPayload = ObjToFormData(payload);
  return await request(
    dispatch,
    newPayload,
    baseUrl + "getListServerHotspotMikrotik"
  );
};

export const voucher = {
  _read,
  _created,
  _update,
  _delete,
  _sync,
  _updateServer,
  _getComment,
  _getListVoucher,
  _getListServerHotspotMikrotik,
};
