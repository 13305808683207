import { useDispatch, useSelector } from "react-redux";
import LayoutHome from "../../components/Layout/Home";
import { useEffect, useRef, useState } from "react";
import Div from "../../components/Div";
import {
  Button,
  ButtonGroup,
  Card,
  Form,
  Image,
  Modal,
  Stack,
  Table,
  ToggleButton,
} from "react-bootstrap";
import { actions } from "../../store/actions";
import Tables from "../../components/Tables";
import { IdnKab, loading, profile } from "../../assets";
import { RWebShare } from "react-web-share";
import Icon from "../../components/Icon";
import { config } from "../../utils/StaticString";
import Swalert from "../../components/Swalert";
import JWToken from "../../utils/JWToken";
import { service } from "../../store/service";
import { PageMenu } from "../../route/PageMenu";
import InputLabel from "../../components/InputLabel";
import SelectLabel from "../../components/SelectLabel";
import PhoneLabel from "../../components/PhoneLabel";
import Span from "../../components/Span";
import EasyCrop from "../../components/Cropped/EasyCrop";
import LayoutModal from "../../components/LayoutModal";
import FilterListTable from "../../utils/FilterListTable";

const Users = () => {
  const dispatch = useDispatch();
  const globalReducer = useSelector((state) => state.globalReducer);
  const { accessMenu, accessCRUD, modalAction } = globalReducer;
  const { accCreated, accDelete, accUpdate } = accessCRUD;

  const mUserReducer = useSelector((state) => state.mUserReducer);
  const { object1, other1, other2, other3 } = mUserReducer;
  const { array1, array2, array3 } = mUserReducer;

  const [formValidate, setFormValidate] = useState(false);
  const [accessRowORG, setAccessRowORG] = useState(false);
  const [waValidate, setWaValidate] = useState(true);
  const [newOrg, setNewOrg] = useState(false);

  const [listTable, setListTable] = useState(array1);
  const [searchVal, setSearchVal] = useState("");

  const actCreated = "actCreated";
  const actUpdate = "actUpdate";
  const actProfile = "actProfile";

  const optionsGender = [
    { value: "male", label: "Laki Laki" },
    { value: "female", label: "Perempuan" },
  ];

  useEffect(() => {
    setListTable(array1);
  }, [array1]);

  useEffect(() => {
    setListTable(FilterListTable(searchVal, array1));
  }, [searchVal]);

  useEffect(() => {
    for (let index = 0; index < accessMenu.length; index++) {
      const item = accessMenu[index];
      if (item.path === PageMenu.d_menus) {
        setAccessRowORG(true);
      }
    }
  }, [accessMenu]);

  useEffect(() => {
    actions.master.user.setArray3(dispatch, new IdnKab().getAllData());
    if (new JWToken()._getStatusAccessToken()) {
      getFirtsData();
    }
  }, []);

  const getFirtsData = async () => {
    const result = await service.master.user._read(dispatch);
    if (result.status) {
      const listUser = result.data.listUser;
      const listAccess = result.data.listAccess;
      listAccess.map((item) => {
        item.value = item.id;
        item.label = item.accessName;
      });
      actions.master.user.setArray1(dispatch, listUser);
      actions.master.user.setArray2(dispatch, listAccess);
      return;
    }
    return new Swalert().error(result.data);
  };
  async function openModal(action, header, item) {
    if (item.id === "1") {
      return new Swalert().warning("Tidak Dapat Merubah Data User Ini.");
    }
    setNewOrg(false);
    actions.global.setModalShow(dispatch, true);
    actions.global.setModalAction(dispatch, action);
    actions.global.setModalTitleHeader(dispatch, header);
    actions.master.user.setObject1(dispatch, item);

    if (action !== actProfile) {
      if (item.whatsapp !== "") {
        setWaValidate(true);
      }
      array2.map((thisis) => {
        if (item.accessId === thisis.value) {
          actions.master.user.setOther1(dispatch, thisis);
        }
      });
      optionsGender.map((thisis) => {
        if (item.gender === thisis.value) {
          actions.master.user.setOther2(dispatch, thisis);
        }
      });

      array3.map((thisis) => {
        if (parseInt(item.temLahir) === thisis.indID) {
          actions.master.user.setOther3(dispatch, thisis);
        }
      });
    }
    return;
  }
  function handleChangeWA(value, country) {
    const contryCode = country.dialCode;
    if (value.length > contryCode.length) {
      if (value.slice(0, contryCode.length) === contryCode) {
        if (value.slice(contryCode.length, contryCode.length + 1) !== "0") {
          setWaValidate(true);
        } else {
          setWaValidate(false);
        }
      } else {
        setWaValidate(false);
      }
    } else {
      setWaValidate(false);
    }
    updateData("whatsapp", value);
  }
  const updateData = (name, value) => {
    setFormValidate(false);
    const newData = { [name]: value };
    actions.master.user.updateObject1(dispatch, newData);
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      submitHandler();
    }
  };
  const submitHandler = async () => {
    if (modalAction === actCreated) {
      createdNewUser();
    }
    if (modalAction === actUpdate) {
      updateDataDetail();
    }
    if (modalAction === actProfile) {
      updateDataProfile();
    }
  };
  async function createdNewUser() {
    if (
      object1.name === "" ||
      object1.tglLahir === "" ||
      object1.tglLahir === "0000-00-00"
    ) {
      return setFormValidate(true);
    }
    if (object1.accessId === "0") {
      return new Swalert().error("Berikan User Akses yang valid");
    }

    if (!waValidate) {
      return new Swalert().error("Berikan Nomor Whatsapp yang valid");
    }
    object1.newOrg = newOrg;
    const result = await service.master.user._created(dispatch, object1);
    if (result.status) {
      actions.global.setModalShow(dispatch, false);
      actions.master.user.addArray1(dispatch, result.data);
      return new Swalert().success("Tambah User Success");
    }
    return new Swalert().error(result.data);
  }
  async function updateDataDetail() {
    if (
      object1.name === "" ||
      object1.tglLahir === "" ||
      object1.tglLahir === "0000-00-00"
    ) {
      return setFormValidate(true);
    }

    if (object1.accessId === "0") {
      return new Swalert().error("Berikan User Akses yang valid");
    }

    if (!waValidate) {
      return new Swalert().error("Berikan Nomor Whatsapp yang valid");
    }
    object1.newOrg = newOrg ? 1 : 0;
    const result = await service.master.user._update(dispatch, object1);
    if (result.status) {
      actions.global.setModalShow(dispatch, false);
      actions.master.user.updateArray1(dispatch, result.data);
      return new Swalert().success("Update Data Success");
    }
    return new Swalert().error(result.data);
  }

  async function deleteItem(item) {
    const swal = await new Swalert().confirm("Delete User " + item.name, "Yes");
    if (swal) {
      const result = await service.master.user._delete(dispatch, item);
      if (result.status) {
        actions.global.setModalShow(dispatch, false);
        actions.master.user.deleteArray1(dispatch, item);
        return new Swalert().success("Delete " + item.name + "Berhasil");
      }
      return new Swalert().error(result.data);
    }
  }
  async function resetItem(item) {
    const swal = await new Swalert().confirm("Reset Login " + item.name, "Yes");
    if (swal) {
      const result = await service.master.user._reset(dispatch, item);
      if (result.status) {
        actions.global.setModalShow(dispatch, false);
        actions.master.user.updateArray1(dispatch, result.data);
        return new Swalert().success("Reset " + item.name + "Berhasil");
      }
      return new Swalert().error(result.data);
    }
  }
  async function lockedItem(item) {
    let valueLocked = parseInt(item.locked);

    let lockedNotify = "Locked";
    if (valueLocked === 1) {
      lockedNotify = "Unlock";
    }

    const swal = await new Swalert().confirm(
      lockedNotify + " Access Login " + item.name,
      "Yes"
    );

    if (swal) {
      if (valueLocked === 1) {
        item.locked = 0;
      } else {
        item.locked = 1;
      }
      const result = await service.master.user._locked(dispatch, item);
      if (result.status) {
        actions.global.setModalShow(dispatch, false);
        actions.master.user.updateArray1(dispatch, result.data);
        return new Swalert().success(
          lockedNotify + " " + item.name + "Berhasil"
        );
      }
      return new Swalert().error(result.data);
    }
  }
  const clickSelect = useRef();
  const clickCrop = useRef();
  async function updateDataProfile() {
    clickCrop.current?.childFunctionCrop();
  }
  const saveImageCrop = async ({ status, response }) => {
    if (!status) {
      return new Swalert().warning("Please, select new image");
    }
    const dataImage = { image: response, id: object1.id };
    const result = await service.master.user._profile(dispatch, dataImage);
    if (result.status) {
      actions.master.user.updateArray1(dispatch, result.data);
      actions.global.setModalShow(dispatch, false);
      return new Swalert().success("Update Success");
    }
    return new Swalert().error(result.data);
  };
  function modalFormProfile() {
    return (
      <Form noValidate validated={formValidate} style={{ textAlign: "center" }}>
        <Button
          style={{ marginBottom: "10px" }}
          onClick={() => {
            clickSelect.current?.childFunctionSelect();
          }}
        >
          <Span>Select File</Span>
        </Button>
        <EasyCrop
          image={
            object1.images === "" ? profile : config.baseUrl + object1.images
          }
          rHeight={4}
          rWidth={5}
          clickSelect={clickSelect}
          clickCrop={clickCrop}
          clickSave={saveImageCrop}
        />
      </Form>
    );
  }
  function modalFormDetail() {
    return (
      <Form noValidate validated={formValidate}>
        <InputLabel
          title="Nama Lengkap"
          name={"name"}
          value={object1.name}
          required
          onChange={(e) => updateData(e.target.name, e.target.value)}
          onKeyDown={handleKeyDown}
        />
        <SelectLabel
          title={"User Akses"}
          isSearchable={true}
          required
          defaultValue={other1}
          options={array2}
          onChange={(e) => updateData("accessId", e.value)}
        />
        <SelectLabel
          title={"Jenis Kelamin"}
          isSearchable={false}
          required
          defaultValue={other2}
          options={optionsGender}
          onChange={(e) => updateData("gender", e.value)}
        />
        <SelectLabel
          title={"Tempat"}
          isSearchable={true}
          required
          defaultValue={other3}
          options={array3}
          onChange={(e) => updateData("temLahir", e.value)}
        />
        <InputLabel
          title={"Tanggal Lahir"}
          type="date"
          name={"tglLahir"}
          required
          value={object1.tglLahir}
          onChange={(e) => updateData(e.target.name, e.target.value)}
          onKeyDown={handleKeyDown}
        />
        <PhoneLabel
          title="Nomor Whatsapp"
          value={object1.whatsapp}
          onChange={handleChangeWA}
          onKeyDown={handleKeyDown}
        />
        {!accessRowORG ? (
          ""
        ) : (
          <Div style={{ marginLeft: "20px" }}>
            <Form.Check
              inline
              label="User ini untuk organisasi baru"
              name="newOrg"
              type={"checkbox"}
              defaultChecked={newOrg}
              onChange={(e) => setNewOrg(e.target.checked)}
            />
          </Div>
        )}
      </Form>
    );
  }
  function setLayoutTable() {
    function image(item) {
      return (
        <Image
          src={item.images === "" ? profile : config.baseUrl + item.images}
          alt="img"
          style={{ width: "30px", cursor: "pointer" }}
        />
      );
    }
    function action(item) {
      const protocol = window.location.protocol;
      const host = window.location.host;
      const localhost = protocol + "//" + host + "/";

      var disabledShare = item.userName === "" ? false : true;
      if (parseInt(item.id) === 1) {
        disabledShare = true;
      }
      var disabledReset = item.userName === "" ? true : false;
      if (parseInt(item.id) === 1) {
        disabledReset = true;
      }

      if (parseInt(item.locked)) {
        disabledShare = true;
        disabledReset = true;
      }
      var disabledLocked = false;
      if (parseInt(item.id) === 1) {
        disabledLocked = true;
      }
      var disabledDelete = false;
      if (parseInt(item.id) === 1) {
        disabledDelete = true;
      }

      return (
        <ButtonGroup>
          {disabledReset ? (
            <RWebShare
              data={{
                title: item.name,
                url: localhost + "sigIn/" + item.refferal,
              }}
            >
              <ToggleButton
                disabled={disabledShare}
                variant={disabledShare ? "outline" : "outline-primary"}
                style={{ padding: "3px 3px 3px 5px" }}
              >
                <Icon style={{ fontSize: "20px", marginLeft: "0px" }}>
                  share
                </Icon>
              </ToggleButton>
            </RWebShare>
          ) : (
            ""
          )}

          {accUpdate ? (
            <>
              {!disabledReset ? (
                <ToggleButton
                  disabled={disabledReset}
                  variant={disabledReset ? "outline" : "outline-success"}
                  style={{ padding: "3px 3px 3px 5px" }}
                  onClick={() => resetItem(item)}
                >
                  <Icon style={{ fontSize: "20px", marginLeft: "0px" }}>
                    lock_reset
                  </Icon>
                </ToggleButton>
              ) : (
                ""
              )}

              <ToggleButton
                disabled={disabledLocked}
                variant={disabledLocked ? "outline" : "outline-warning"}
                style={{ padding: "3px 3px 3px 5px" }}
                onClick={() => lockedItem(item)}
              >
                <Icon style={{ fontSize: "20px", marginLeft: "0px" }}>
                  {parseInt(item.locked) === 0 ? "lock_open" : "lock"}
                </Icon>
              </ToggleButton>
            </>
          ) : (
            ""
          )}

          {accDelete ? (
            <ToggleButton
              disabled={disabledDelete}
              variant={disabledDelete ? "outline" : "outline-danger"}
              style={{ padding: "3px 3px 3px 5px" }}
              onClick={() => deleteItem(item)}
            >
              <Icon style={{ fontSize: "20px", marginLeft: "0px" }}>
                delete
              </Icon>
            </ToggleButton>
          ) : (
            ""
          )}
        </ButtonGroup>
      );
    }
    function capitalizeFirstLetter(str) {
      const arr = str.split(" ");
      for (var i = 0; i < arr.length; i++) {
        arr[i] =
          arr[i].slice(0, 1).toUpperCase() + arr[i].slice(1).toLowerCase();
      }
      const str2 = arr.join(" ");
      return str2;
    }
    function dateFormat(date) {
      const today = new Date(date);
      const yyyy = today.getFullYear();
      let mm = today.getMonth() + 1; // Months start at 0!
      let dd = today.getDate();

      if (dd < 10) dd = "0" + dd;
      if (mm < 10) mm = "0" + mm;

      const formattedToday = dd + "-" + mm + "-" + yyyy;
      return formattedToday;
    }
    function openDataModal(item) {
      openModal(actUpdate, "Update User", item);
    }

    function dataBody() {
      if (!listTable) {
        return;
      }
      return listTable.map((item, index) => {
        return (
          <Tables.Tr style={{ cursor: "pointer" }}>
            <Tables.Td>{index + 1}</Tables.Td>
            {accessRowORG ? <Tables.Td>{item.orgName}</Tables.Td> : ""}

            <Tables.Td
              style={{ cursor: "default" }}
              onClick={() =>
                openModal(actProfile, "Update Profile " + item.name, item)
              }
            >
              {image(item)}
            </Tables.Td>
            <Tables.Td onClick={() => openDataModal(item)}>
              {capitalizeFirstLetter(item.name)}
            </Tables.Td>
            <Tables.Td onClick={() => openDataModal(item)}>
              {array2.map((access) => {
                if (item.accessId === access.value) {
                  return access.label;
                }
              })}
            </Tables.Td>
            <Tables.Td onClick={() => openDataModal(item)}>
              {optionsGender.map((itemG) => {
                if (item.gender === itemG.value) {
                  return itemG.label;
                }
              })}
            </Tables.Td>
            <Tables.Td onClick={() => openDataModal(item)}>
              {array3.map((itemK) => {
                if (itemK.indID === parseInt(item.temLahir)) {
                  return capitalizeFirstLetter(
                    itemK.indName.replace("KOTA ", "").replace("KAB. ", "")
                  );
                }
              })}
              {", "}
              <br></br>
              {dateFormat(item.tglLahir)}
            </Tables.Td>
            <Tables.Td onClick={() => openDataModal(item)}>
              {item.whatsapp}
            </Tables.Td>
            <Tables.Td style={{ cursor: "default" }}>{action(item)}</Tables.Td>
          </Tables.Tr>
        );
      });
    }

    return (
      <Tables.Table>
        <Tables.Head>
          <Tables.Tr>
            <Tables.Th>#</Tables.Th>
            {accessRowORG ? <Tables.Th>ORGANIZATION</Tables.Th> : ""}
            <Tables.Th>PROFILE</Tables.Th>
            <Tables.Th>NAMA</Tables.Th>
            <Tables.Th>ACCESS</Tables.Th>
            <Tables.Th>JENIS KELAMIN</Tables.Th>
            <Tables.Th>TANGGAL LAHIR</Tables.Th>
            <Tables.Th>WHATSAPP</Tables.Th>
            <Tables.Th>ACTION</Tables.Th>
          </Tables.Tr>
        </Tables.Head>
        <Tables.Body>{dataBody()}</Tables.Body>
      </Tables.Table>
    );
  }
  const item = {
    accessId: "0",
    userName: "",
    name: "",
    gender: "male",
    refferal: "",
    temLahir: "1101",
    tglLahir: "",
    whatsapp: "",
    images: "",
    locked: "0",
  };
  return (
    <LayoutHome>
      <LayoutModal onSave={submitHandler}>
        {modalAction === actCreated ? modalFormDetail() : ""}
        {modalAction === actUpdate ? modalFormDetail() : ""}
        {modalAction === actProfile ? modalFormProfile() : ""}
      </LayoutModal>
      <Card>
        <Stack direction="horizontal" style={{ marginBottom: "5px" }} gap={2}>
          <Div>
            {accCreated ? (
              <Button
                variant="outline-primary"
                size="sm"
                onClick={() => openModal(actCreated, "Created User", item)}
              >
                ADD
              </Button>
            ) : (
              ""
            )}
          </Div>
          <Div
            className="ms-auto"
            style={{ alignSelf: "flex-end", width: "120px" }}
          >
            <Form.Control
              type={"text"}
              placeholder={"Cari nama . . ."}
              onChange={(e) => setSearchVal(e.target.value)}
            />
          </Div>
        </Stack>
        {setLayoutTable()}
      </Card>
    </LayoutHome>
  );
};

export default Users;
