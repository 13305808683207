const actionType = {
  setArray1: "actions.service.hotspot.history.setArray1",
  addArray1: "actions.service.hotspot.history.addArray1",
  updateArray1: "actions.service.hotspot.history.updateArray1",
  deleteArray1: "actions.service.hotspot.history.deleteArray1",

  setArray3: "actions.service.hotspot.history.setArray3",
  addArray3: "actions.service.hotspot.history.addArray3",
  updateArray3: "actions.service.hotspot.history.updateArray3",
  deleteArray3: "actions.service.hotspot.history.deleteArray3",

  setArray4: "actions.service.hotspot.history.setArray4",
  addArray4: "actions.service.hotspot.history.addArray4",
  updateArray4: "actions.service.hotspot.history.updateArray4",
  deleteArray4: "actions.service.hotspot.history.deleteArray4",

  setArray5: "actions.service.hotspot.history.setArray5",
  addArray5: "actions.service.hotspot.history.addArray5",
  updateArray5: "actions.service.hotspot.history.updateArray5",
  deleteArray5: "actions.service.hotspot.history.deleteArray5",

  setObject1: "actions.service.hotspot.history.setObject1",
  updateObject1: "actions.service.hotspot.history.updateObject1",
};
const setArray1 = async (dispatch, payload) => {
  dispatch({ type: actionType.setArray1, payload: payload });
};
const addArray1 = async (dispatch, payload) => {
  dispatch({ type: actionType.addArray1, payload: payload });
};
const updateArray1 = async (dispatch, payload) => {
  dispatch({ type: actionType.updateArray1, payload: payload });
};
const deleteArray1 = async (dispatch, payload) => {
  dispatch({ type: actionType.deleteArray1, payload: payload });
};
const setArray3 = async (dispatch, payload) => {
  dispatch({ type: actionType.setArray3, payload: payload });
};
const addArray3 = async (dispatch, payload) => {
  dispatch({ type: actionType.addArray3, payload: payload });
};
const updateArray3 = async (dispatch, payload) => {
  dispatch({ type: actionType.updateArray3, payload: payload });
};
const deleteArray3 = async (dispatch, payload) => {
  dispatch({ type: actionType.deleteArray3, payload: payload });
};

const setArray4 = async (dispatch, payload) => {
  dispatch({ type: actionType.setArray4, payload: payload });
};
const addArray4 = async (dispatch, payload) => {
  dispatch({ type: actionType.addArray4, payload: payload });
};
const updateArray4 = async (dispatch, payload) => {
  dispatch({ type: actionType.updateArray4, payload: payload });
};
const deleteArray4 = async (dispatch, payload) => {
  dispatch({ type: actionType.deleteArray4, payload: payload });
};

const setArray5 = async (dispatch, payload) => {
  dispatch({ type: actionType.setArray5, payload: payload });
};
const addArray5 = async (dispatch, payload) => {
  dispatch({ type: actionType.addArray5, payload: payload });
};
const updateArray5 = async (dispatch, payload) => {
  dispatch({ type: actionType.updateArray5, payload: payload });
};
const deleteArray5 = async (dispatch, payload) => {
  dispatch({ type: actionType.deleteArray5, payload: payload });
};

const setObject1 = async (dispatch, payload) => {
  dispatch({ type: actionType.setObject1, payload: payload });
};
const updateObject1 = async (dispatch, payload) => {
  dispatch({ type: actionType.updateObject1, payload: payload });
};

export const history = {
  actionType,
  setArray1,
  addArray1,
  updateArray1,
  deleteArray1,
  setArray3,
  addArray3,
  updateArray3,
  deleteArray3,
  setArray4,
  addArray4,
  updateArray4,
  deleteArray4,
  setArray5,
  addArray5,
  updateArray5,
  deleteArray5,
  setObject1,
  updateObject1,
};
