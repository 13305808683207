function randNumb() {
  return Math.floor(Math.random() * 1000000);
}
const Span = ({ children, ...props }) => {
  const random = randNumb();
  return (
    <span key={random} {...props}>
      {children}
    </span>
  );
};

export default Span;
