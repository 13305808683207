import { useDispatch, useSelector } from "react-redux";
import LayoutHome from "../../components/Layout/Home";
import { useContext, useEffect, useState } from "react";
import Div from "../../components/Div";
import {
  Accordion,
  AccordionContext,
  Card,
  Form,
  Stack,
  Table,
  useAccordionButton,
} from "react-bootstrap";
import { actions } from "../../store/actions";
import Tables from "../../components/Tables";
import Swalert from "../../components/Swalert";
import JWToken from "../../utils/JWToken";
import { service } from "../../store/service";
import InputLabel from "../../components/InputLabel";
import SelectLabel from "../../components/SelectLabel";
import LayoutModal from "../../components/LayoutModal";
import FilterListTable from "../../utils/FilterListTable";

const HotspotSetting = () => {
  const dispatch = useDispatch();

  const shSettingReducer = useSelector((state) => state.shSettingReducer);
  const { array1, array2, object1 } = shSettingReducer;

  const [formValidate, setFormValidate] = useState(false);
  const [listTable, setListTable] = useState(array1);
  const [searchVal, setSearchVal] = useState("");
  const [optionAdmin, setOptionAdmin] = useState([]);
  const [optionReseller, setOptionReseller] = useState([]);
  const [statusAccording, setStatusAccording] = useState(false);

  useEffect(() => {
    setListTable(FilterListTable(searchVal, array1));
  }, [searchVal]);

  useEffect(() => {
    setListTable(array1);
  }, [array1]);

  useEffect(() => {
    if (new JWToken()._getStatusAccessToken()) {
      getFirtsData();
    }
  }, []);

  const getFirtsData = async () => {
    const result = await service.layanan.hotspot.setting._read(dispatch);
    if (result.status) {
      result.data.users.map((item) => {
        item.value = item.id;
        item.label = item.name;
      });

      result.data.mikrotik.map((item) => {
        const arrOptionAdmin = [];
        const newArrAdmin = JSON.parse(item.adminHotspot);
        newArrAdmin.map((idUser) => {
          for (let index = 0; index < result.data.users.length; index++) {
            const el = result.data.users[index];
            if (el.id === idUser) {
              arrOptionAdmin.push(el);
              index = result.data.users.length;
            }
          }
        });
        const arrOptionReseller = [];
        const newArrReseller = JSON.parse(item.resellerHotspot);
        newArrReseller.map((idUser) => {
          for (let index = 0; index < result.data.users.length; index++) {
            const el = result.data.users[index];
            if (el.id === idUser) {
              arrOptionReseller.push(el);
              index = result.data.users.length;
            }
          }
        });

        item.adminHotspot = arrOptionAdmin;
        item.resellerHotspot = arrOptionReseller;
      });

      actions.service.hotspot.setting.setArray1(dispatch, result.data.mikrotik);
      actions.service.hotspot.setting.setArray2(dispatch, result.data.users);
      return;
    }
    return new Swalert().error(result.data);
  };
  async function openModal(header, item) {
    actions.global.setModalShow(dispatch, true);
    actions.global.setModalTitleHeader(dispatch, header);
    actions.service.hotspot.setting.setObject1(dispatch, item);
    setNewOptions(item.id, item.adminHotspot, item.resellerHotspot);
    return;
  }

  function setNewOptions(id, adminHotspot, resellerHotspot) {
    const newOptionAdmin = [];
    const newOptionReseller = [];

    //skip users karna sudah ada di mikrotik lain
    const skipUsers = [];
    array1.map((mik) => {
      if (mik.id !== id) {
        mik.adminHotspot.map((user) => {
          skipUsers.push(user);
        });
        mik.resellerHotspot.map((user) => {
          skipUsers.push(user);
        });
      }
    });

    //list user yang belum punya akses, dan akses di mikrotik di pilih
    const listUsers = [];
    array2.map((luser) => {
      var addToList = true;
      for (let index = 0; index < skipUsers.length; index++) {
        const suser = skipUsers[index];
        if (luser.id === suser.id) {
          addToList = false;
          index = skipUsers.length;
        }
      }
      if (addToList) {
        listUsers.push(luser);
      }
    });

    const skipAdmin = [];
    adminHotspot.map((user) => {
      skipAdmin.push(user);
      newOptionAdmin.push(user);
    });
    const skipReseller = [];
    resellerHotspot.map((user) => {
      skipReseller.push(user);
      newOptionReseller.push(user);
    });

    listUsers.map((luser) => {
      var addToList = true;
      for (let index = 0; index < skipAdmin.length; index++) {
        const suser = skipAdmin[index];
        if (luser.id === suser.id) {
          addToList = false;
          index = skipAdmin.length;
        }
      }
      for (let index = 0; index < skipReseller.length; index++) {
        const suser = skipReseller[index];
        if (luser.id === suser.id) {
          addToList = false;
          index = skipReseller.length;
        }
      }
      if (addToList) {
        newOptionAdmin.push(luser);
        newOptionReseller.push(luser);
      }
    });

    setOptionAdmin(newOptionAdmin);
    setOptionReseller(newOptionReseller);
  }

  const updateData = (name, value) => {
    if (name === "dnsName") {
      value = value.toLowerCase();
    }
    setFormValidate(false);
    const newData = { [name]: value };
    actions.service.hotspot.setting.updateObject1(dispatch, newData);

    if (name === "adminHotspot") {
      setNewOptions(object1.id, value, object1.resellerHotspot);
    }
    if (name === "resellerHotspot") {
      setNewOptions(object1.id, object1.adminHotspot, value);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      submitHandler();
    }
  };
  const submitHandler = async () => {
    updateDataItem();
  };

  async function updateDataItem() {
    if (object1.adminPercen < 0 || object1.adminPercen > 50) {
      return setFormValidate(true);
    }
    if (object1.resellerPercen < 0 || object1.resellerPercen > 50) {
      return setFormValidate(true);
    }
    const adminHotspot = [];
    const resellerHotspot = [];

    object1.adminHotspot.map((user) => {
      adminHotspot.push(user.id);
    });
    object1.resellerHotspot.map((user) => {
      resellerHotspot.push(user.id);
    });

    const newData = {
      id: object1.id,
      adminPercen: object1.adminPercen,
      resellerPercen: object1.resellerPercen,
      adminHotspot: JSON.stringify(adminHotspot),
      resellerHotspot: JSON.stringify(resellerHotspot),
    };

    const result = await service.layanan.hotspot.setting._update(
      dispatch,
      newData
    );
    if (result.status) {
      actions.global.setModalShow(dispatch, false);
      actions.service.hotspot.setting.updateArray1(dispatch, object1);
      return new Swalert().success("Update Data Success");
    }
    return new Swalert().error(result.data);
  }

  function modalFormDetail() {
    return (
      <Form noValidate validated={formValidate}>
        <h2>
          <b>ADMIN</b>
        </h2>
        <InputLabel
          title={"Bagi Hasil Admin Hotspot (%)"}
          type={"number"}
          name={"adminPercen"}
          error={"bagi hasil maksimal 50%"}
          max={50}
          min={0}
          defaultValue={object1.adminPercen}
          required
          onChange={(e) => updateData(e.target.name, e.target.value)}
          onKeyDown={handleKeyDown}
        />
        <SelectLabel
          title={"Pilih Admin"}
          options={optionAdmin}
          isMulti
          defaultValue={object1.adminHotspot}
          onChange={(e) => updateData("adminHotspot", e)}
          getOptionLabel={(option) => `${option.whatsapp} : ${option.label}`}
        />
        <h2 style={{ paddingTop: "30px" }}>
          <b>RESELLER</b>
        </h2>
        <InputLabel
          title={"Potongan Reseller (%)"}
          type={"number"}
          name={"resellerPercen"}
          error={"Potongan maksimal 50%"}
          max={50}
          min={0}
          defaultValue={object1.resellerPercen}
          required
          onChange={(e) => updateData(e.target.name, e.target.value)}
          onKeyDown={handleKeyDown}
        />
        <SelectLabel
          title={"Pilih Reseller"}
          options={optionReseller}
          isMulti
          defaultValue={object1.resellerHotspot}
          onChange={(e) => updateData("resellerHotspot", e)}
          getOptionLabel={(option) => `${option.whatsapp} : ${option.label}`}
        />
      </Form>
    );
  }
  function setLayoutTable() {
    function openFormModal(item) {
      openModal("Update Seller", item);
    }

    function dataBody() {
      if (!listTable) {
        return;
      }
      return listTable.map((item, index) => {
        function admin() {
          var users = "";
          item.adminHotspot.map((user) => {
            users += user.name + ", ";
          });
          return users;
        }
        function reseller() {
          var users = "";
          item.resellerHotspot.map((user) => {
            users += user.name + ", ";
          });
          return users;
        }
        return (
          <Tables.Tr style={{ cursor: "pointer" }}>
            <Tables.Td>{index + 1}</Tables.Td>
            <Tables.Td onClick={() => openFormModal(item)}>
              {item.name}
            </Tables.Td>
            <Tables.Td onClick={() => openFormModal(item)}>
              {item.adminPercen + "%"}
            </Tables.Td>
            <Tables.Td onClick={() => openFormModal(item)}>
              {item.resellerPercen + "%"}
            </Tables.Td>
            <Tables.Td onClick={() => openFormModal(item)}>{admin()}</Tables.Td>
            <Tables.Td onClick={() => openFormModal(item)}>
              {reseller()}
            </Tables.Td>
          </Tables.Tr>
        );
      });
    }

    return (
      <Tables.Table>
        <Tables.Head>
          <Tables.Tr>
            <Tables.Th>#</Tables.Th>
            <Tables.Th>ROUTER MIKROTIK</Tables.Th>
            <Tables.Th>PERCEN ADMIN</Tables.Th>
            <Tables.Th>PERCEN RESELLER</Tables.Th>
            <Tables.Th>USERS ADMIN</Tables.Th>
            <Tables.Th>USERS RESELLER</Tables.Th>
          </Tables.Tr>
        </Tables.Head>
        <Tables.Body>{dataBody()}</Tables.Body>
      </Tables.Table>
    );
  }

  function ContextAwareToggle({ children, eventKey, callback }) {
    const { activeEventKey } = useContext(AccordionContext);
    setStatusAccording(Boolean(activeEventKey));

    const decoratedOnClick = useAccordionButton(
      eventKey,
      () => callback && callback(eventKey)
    );

    return <Div onClick={decoratedOnClick}>{children}</Div>;
  }
  return (
    <LayoutHome>
      <LayoutModal onSave={submitHandler}>{modalFormDetail()}</LayoutModal>
      <Card>
        <Stack direction="horizontal" style={{ marginBottom: "5px" }} gap={2}>
          <Div className="me-auto" style={{ width: "100%" }}>
            <Accordion>
              <Accordion.Item eventKey="1">
                <Accordion.Header>
                  <ContextAwareToggle>KETERANGAN</ContextAwareToggle>
                </Accordion.Header>
                <Accordion.Body>
                  <Table style={{ margin: "0px" }}>
                    <Tables.Body>
                      <Tables.Tr>
                        <Tables.Td style={{ textAlign: "left" }}>
                          <strong>Admin : </strong>
                          <br></br>
                          <span>Rekap setiap awal bulan</span>
                          <br></br>
                          <span>
                            hasil dari penjualan voucher akan di rekap setiap
                            awal bulan, admin akan mendapatkan notifikasi
                            whatsapp rincian transaksi di bulan sebelumnya.
                            <br></br>
                            total penjualan akan di masukkan sebagai kredit
                            (pengeluaran dari admin tersebut), sedangkan bagi
                            hasil akan masuk ke keuangan admin sebagai debet
                            (pemasukan dari admin tersebut), maka kondisi
                            keuangan pada admin akan menjadi minus sebelum admin
                            menyerahkan laba kotor (omset dikurangi bagi hasil)
                            dari bagi hasil dengan cara melakukan input debet
                            (pemasukan) di menu finace users (setor), maka
                            otomatis finace organization akan bertransaksi
                            sesuai nominal yang di masukkan.
                          </span>
                        </Tables.Td>
                      </Tables.Tr>
                      <Tables.Tr>
                        <Tables.Td style={{ textAlign: "left" }}>
                          <strong>Reseller : </strong>
                          <br></br>
                          <span>Rekap saat generate voucher</span>
                          <br></br>
                          <span>
                            harga reseller akan di masukkan ke keuangan
                            organisasi (finance organization) sebagai pemasukan
                            dari jual voucher ke reseller berdasarkan persentase
                            dan waktu saat generate.
                            <br></br>
                            otomatis mengirimkan notifikasi (invoice) kepada
                            nomor reseller berdasarkan nominal yang harus di
                            bayarkan oleh reseller.
                          </span>
                        </Tables.Td>
                      </Tables.Tr>
                    </Tables.Body>
                  </Table>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </Div>
          {statusAccording ? (
            ""
          ) : (
            <Div
              className="ms-auto"
              style={{ alignSelf: "flex-end", width: "120px" }}
            >
              <Form.Control
                type={"text"}
                placeholder={"Cari nama . . ."}
                onChange={(e) => setSearchVal(e.target.value)}
              />
            </Div>
          )}
        </Stack>
        {setLayoutTable()}
      </Card>
    </LayoutHome>
  );
};

export default HotspotSetting;
