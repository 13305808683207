import { useDispatch, useSelector } from "react-redux";
import LayoutHome from "../../components/Layout/Home";
import { useEffect, useState } from "react";
import Div from "../../components/Div";
import { ButtonGroup, Card, Form, ToggleButton } from "react-bootstrap";
import { actions } from "../../store/actions";
import Tables from "../../components/Tables";
import Swalert from "../../components/Swalert";
import JWToken from "../../utils/JWToken";
import { service } from "../../store/service";
import SelectLabel from "../../components/SelectLabel";
import LayoutModal from "../../components/LayoutModal";
import InputLabel from "../../components/InputLabel";
import Terbilang from "../../utils/Terbilang";

const Organization = () => {
  const dispatch = useDispatch();
  const globalReducer = useSelector((state) => state.globalReducer);
  const { accessCRUD, modalAction } = globalReducer;
  const { accCreated, accDelete, accUpdate } = accessCRUD;

  const fOrgReducer = useSelector((state) => state.fOrgReducer);
  const { array1, array2, array3, object1, object2 } = fOrgReducer;

  const [formValidate, setFormValidate] = useState(false);
  const [optionTransaksi, setOptionTransaksi] = useState([]);

  const actTransaksiKas = "actTransaksiKas";
  const actMutasiKas = "actMutasiKas";

  useEffect(() => {
    if (new JWToken()._getStatusAccessToken()) {
      getFirstData();
    }
  }, []);

  async function getFirstData() {
    const result = await service.finance.org._read(dispatch);
    if (result.status) {
      result.data.map((item) => {
        item.value = item.dateMonth;
        item.label = item.dateMonth;
      });

      actions.finance.org.setArray1(dispatch, result.data);
      return;
    }

    return new Swalert().error(result.data);
  }

  async function mutasiKas(month) {
    if (!month) {
      return new Swalert().warning("Select Month");
    }
    if (month.value === "") {
      return new Swalert().warning("Select Month");
    }
    const newData = {
      month: month.value,
    };
    const result = await service.finance.org._mutasiKas(dispatch, newData);
    if (result.status) {
      actions.global.setModalShow(dispatch, false);
      actions.finance.org.setArray2(dispatch, result.data);
      return;
    }
    return new Swalert().warning(result.data);
  }
  async function transaksiKas() {
    if (!object2) {
      return new Swalert().warning("Please provide a valid");
    }
    if (object2.type.value === "") {
      return new Swalert().warning("Select Type");
    }
    if (parseInt(object2.nominal) <= 0) {
      return setFormValidate(true);
    }
    if (object2.type.value !== "setoran") {
      if (object2.keterangan === "") {
        return setFormValidate(true);
      }
    }

    const newData = {
      type: object2.type.value,
      nominal: object2.nominal,
      keterangan: object2.keterangan,
    };
    const result = await service.finance.org._transaksi(dispatch, newData);
    if (result.status) {
      result.data.month.map((item) => {
        item.value = item.dateMonth;
        item.label = item.dateMonth;
      });

      actions.finance.org.setArray1(dispatch, result.data.month);

      actions.global.setModalShow(dispatch, false);
      actions.finance.org.setArray2(dispatch, result.data.mutasi);
      return new Swalert().success("Transaction Success");
    }
    return new Swalert().warning(result.data);
  }

  async function openModal(action, header, item) {
    actions.global.setModalShow(dispatch, true);
    actions.global.setModalAction(dispatch, action);
    actions.global.setModalTitleHeader(dispatch, header);
    if (item) {
      actions.finance.org.setObject1(dispatch, item);
    }
    if (action === actTransaksiKas) {
      const listTrans = [];
      var obj = { value: "pemasukan", label: "Pemasukan" };
      listTrans.push(obj);
      obj = { value: "pengeluaran", label: "Pengeluaran" };
      listTrans.push(obj);
      setOptionTransaksi(listTrans);
      if (!object2) {
        updateData2("type", { value: "", label: "" });
      }
      updateData2("nominal", "0");
      updateData2("keterangan", "");
    }
    return;
  }
  const updateData2 = async (name, value) => {
    setFormValidate(false);
    const newData = { [name]: value };
    actions.finance.org.updateObject2(dispatch, newData);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      submitHandler();
    }
  };

  const submitHandler = async () => {
    if (modalAction === actMutasiKas) {
      mutasiKas(object1.month);
    }
    if (modalAction === actTransaksiKas) {
      transaksiKas();
    }
  };

  function modalFormTransaksi() {
    function terbilang() {
      return Terbilang(object2.nominal) + " Rupiah";
    }
    return (
      <Form noValidate validated={formValidate}>
        <SelectLabel
          title={"Select Type"}
          isSearchable={false}
          options={optionTransaksi}
          value={object2.type}
          onChange={(e) => updateData2("type", e)}
        />
        <InputLabel
          title="Nominal"
          name={"nominal"}
          type={"number"}
          min={1}
          value={object2.nominal}
          required
          notify={terbilang()}
          onChange={(e) => updateData2(e.target.name, e.target.value)}
          onKeyDown={handleKeyDown}
        />
        <InputLabel
          required
          style={{ marginBottom: "10px" }}
          title="Keterangan"
          name={"keterangan"}
          value={object2.keterangan}
          onChange={(e) => updateData2(e.target.name, e.target.value)}
          onKeyDown={handleKeyDown}
        />
        <span>
          <b>noted :</b>
          <br></br>
          <span style={{ color: "red" }}>
            Transaksi ini tidak dapat diedit / dihapus.
          </span>
        </span>
      </Form>
    );
  }

  function setLayoutTableMutasi() {
    function dataBodyMutasi() {
      if (!array2.mutasi) {
        return;
      }
      var sum = parseInt(array2.saldoAwal);
      return array2.mutasi.map((item, index) => {
        var debet = "";
        var kredit = "";
        sum += parseInt(item.nominal);
        if (parseInt(item.nominal) > 0) {
          debet = setRupiah(item.nominal);
        } else {
          kredit = setRupiah(item.nominal);
        }
        return (
          <Tables.Tr style={{ cursor: "pointer" }}>
            <Tables.Td>{index + 2}</Tables.Td>
            <Tables.Td>{item.dateTime}</Tables.Td>
            <Tables.Td>{item.noTransaksi}</Tables.Td>
            <Tables.Td>{item.addket}</Tables.Td>
            <Tables.Td>{item.keterangan}</Tables.Td>
            <Tables.Td>{debet}</Tables.Td>
            <Tables.Td>{kredit}</Tables.Td>
            <Tables.Td>
              <span style={sum < 0 ? { color: "red" } : {}}>
                {setRupiah(sum)}
              </span>
            </Tables.Td>
          </Tables.Tr>
        );
      });
    }
    return (
      <Tables.Table>
        <Tables.Head>
          <Tables.Tr>
            <Tables.Th>#</Tables.Th>
            <Tables.Th>DATE</Tables.Th>
            <Tables.Th>INVOICE</Tables.Th>
            <Tables.Th>TYPE</Tables.Th>
            <Tables.Th>DETAIL</Tables.Th>
            <Tables.Th>DEBET</Tables.Th>
            <Tables.Th>KREDIT</Tables.Th>
            <Tables.Th>SALDO</Tables.Th>
          </Tables.Tr>
        </Tables.Head>
        <Tables.Body>
          {array2.saldoAwal ? (
            <Tables.Tr style={{ cursor: "pointer" }}>
              <Tables.Td>1</Tables.Td>
              <Tables.Td colspan={6}>Saldo Awal</Tables.Td>
              <Tables.Td>{setRupiah(array2.saldoAwal)}</Tables.Td>
            </Tables.Tr>
          ) : (
            ""
          )}
          {dataBodyMutasi()}
        </Tables.Body>
      </Tables.Table>
    );
  }

  function setRupiah(value) {
    return new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
      minimumFractionDigits: 0,
    }).format(value);
  }

  return (
    <LayoutHome>
      <LayoutModal onSave={submitHandler}>{modalFormTransaksi()}</LayoutModal>
      <Card>
        <Div style={{ margin: "5px" }}>
          {accCreated ? (
            <ButtonGroup style={{ marginBottom: "10px", marginTop: "10px" }}>
              <ToggleButton
                variant="outline-primary"
                size="sm"
                onClick={() =>
                  openModal(actTransaksiKas, "Transaksi Kas", false)
                }
              >
                TRANSAKSI
              </ToggleButton>
            </ButtonGroup>
          ) : (
            ""
          )}

          <SelectLabel
            title={"Select Month"}
            isSearchable={true}
            options={array1}
            value={object1.month}
            onChange={(e) => mutasiKas(e)}
          />
          {setLayoutTableMutasi()}
        </Div>
      </Card>
    </LayoutHome>
  );
};

export default Organization;
