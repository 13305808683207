import { config } from "../../../../utils/StaticString";
import ObjToFormData from "../../ObjToFormData";
import request from "../../request";

const baseUrl = config.baseUrl + "service/hotspot/online/";

const _read = async (dispatch) => {
  const newPayload = ObjToFormData({});
  return await request(dispatch, newPayload, baseUrl + "read");
};
const _active = async (dispatch, payload) => {
  const newPayload = ObjToFormData(payload);
  return await request(dispatch, newPayload, baseUrl + "active");
};

export const online = {
  _read,
  _active,
};
