import { setting } from "./setting";
import { profile } from "./profile";
import { voucher } from "./voucher";
import { active } from "./active";
import { online } from "./online";
import { template } from "./template";
import { history } from "./history";

const hotspot = {
  setting,
  profile,
  voucher,
  active,
  online,
  template,
  history,
};

export default hotspot;
