import { useDispatch, useSelector } from "react-redux";
import LayoutHome from "../../components/Layout/Home";
import { useEffect, useRef, useState } from "react";
import Div from "../../components/Div";
import {
  Button,
  ButtonGroup,
  Card,
  Form,
  Image,
  Modal,
  Overlay,
  OverlayTrigger,
  Popover,
  Stack,
  Table,
  ToggleButton,
  Tooltip,
} from "react-bootstrap";
import { actions } from "../../store/actions";
import Tables from "../../components/Tables";
import { IdnKab, loading, profile } from "../../assets";
import { RWebShare } from "react-web-share";
import Icon from "../../components/Icon";
import { config } from "../../utils/StaticString";
import Swalert from "../../components/Swalert";
import JWToken from "../../utils/JWToken";
import { service } from "../../store/service";
import { PageMenu } from "../../route/PageMenu";
import InputLabel from "../../components/InputLabel";
import SelectLabel from "../../components/SelectLabel";
import PhoneLabel from "../../components/PhoneLabel";
import Span from "../../components/Span";
import EasyCrop from "../../components/Cropped/EasyCrop";
import FilterListTable from "../../utils/FilterListTable";
import LayoutModal from "../../components/LayoutModal";

const Mikrotik = () => {
  const dispatch = useDispatch();
  const { listHarga } = useSelector((state) => state.globalReducer);
  const { modalAction } = useSelector((state) => state.globalReducer);

  const mMikrotikReducer = useSelector((state) => state.mMikrotikReducer);
  const { array1, array2, object1 } = mMikrotikReducer;

  const [formValidate, setFormValidate] = useState(false);
  const [listTable, setListTable] = useState(array1);
  const [searchVal, setSearchVal] = useState("");

  const actCreated = "actCreated";
  const actUpdate = "actUpdate";

  const [serviceHotspot, setServiceHotspot] = useState({});
  const [serviceMikrotik, setServiceMikrotik] = useState({});

  useEffect(() => {
    for (let index = 0; index < listHarga.length; index++) {
      const element = listHarga[index];
      if (element.service === "serviceHotspot") {
        setServiceHotspot(element);
      }
      if (element.service === "serviceMikrotik") {
        setServiceMikrotik(element);
      }
    }
  }, [listHarga]);

  useEffect(() => {
    setListTable(FilterListTable(searchVal, array1));
  }, [searchVal]);

  useEffect(() => {
    setListTable(array1);
  }, [array1]);

  useEffect(() => {
    if (new JWToken()._getStatusAccessToken()) {
      getFirtsData();
    }
  }, []);

  const getFirtsData = async () => {
    const result = await service.master.mikrotik._read(dispatch);
    if (result.status) {
      result.data.users.map((item) => {
        item.value = item.id;
        item.label = item.name;
      });
      actions.master.mikrotik.setArray1(dispatch, result.data.mikrotik);
      actions.master.mikrotik.setArray2(dispatch, result.data.users);
      return;
    }
    return new Swalert().error(result.data);
  };
  async function openModal(action, header, item) {
    actions.global.setModalShow(dispatch, true);
    actions.global.setModalAction(dispatch, action);
    actions.global.setModalTitleHeader(dispatch, header);

    item.passCript = atob(item.pass);
    actions.master.mikrotik.setObject1(dispatch, item);

    return;
  }
  const updateData = (name, value) => {
    if (name === "dnsName") {
      value = value.toLowerCase();
    }
    setFormValidate(false);
    const newData = { [name]: value };
    actions.master.mikrotik.updateObject1(dispatch, newData);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      submitHandler();
    }
  };
  const submitHandler = async () => {
    if (modalAction === actCreated) {
      createdNewData();
    }
    if (modalAction === actUpdate) {
      updateDataItem();
    }
  };

  async function createdNewData() {
    if (
      object1.host === "" ||
      object1.name === "" ||
      object1.port === "" ||
      object1.user === "" ||
      object1.passCript === ""
    ) {
      return setFormValidate(true);
    }
    object1.pass = btoa(object1.passCript);
    const result = await service.master.mikrotik._created(dispatch, object1);
    if (result.status) {
      actions.global.setModalShow(dispatch, false);
      actions.master.mikrotik.addArray1(dispatch, result.data);
      return new Swalert().success("Tambah Data Success");
    }
    return new Swalert().error(result.data);
  }
  async function updateDataItem() {
    if (
      object1.host === "" ||
      object1.name === "" ||
      object1.port === "" ||
      object1.user === "" ||
      object1.passCript === ""
    ) {
      return setFormValidate(true);
    }
    object1.pass = btoa(object1.passCript);
    delete object1.passCript;

    const result = await service.master.mikrotik._update(dispatch, object1);
    if (result.status) {
      actions.global.setModalShow(dispatch, false);
      actions.master.mikrotik.updateArray1(dispatch, result.data);
      return new Swalert().success("Update Data Success");
    }
    return new Swalert().error(result.data);
  }
  async function deleteItem(item) {
    const swal = await new Swalert().confirm(
      "Berhenti Layanan Mikrotik " + item.name,
      "Yes"
    );
    if (swal) {
      const result = await service.master.mikrotik._delete(dispatch, item);
      if (result.status) {
        actions.global.setModalShow(dispatch, false);
        actions.master.mikrotik.deleteArray1(dispatch, item);
        return new Swalert().success("Delete " + item.name + " Berhasil");
      }
      return new Swalert().error(result.data);
    }
  }

  async function pingItem(item) {
    const swal = await new Swalert().confirm("Ping " + item.name + " ?", "Yes");
    if (swal) {
      const result = await service.master.mikrotik._pingMokrotik(
        dispatch,
        item
      );
      if (result.status) {
        if (result.data) {
          return new Swalert().success("Mikrotik " + item.name + " Terhubung");
        } else {
          return new Swalert().warning(
            "Mikrotik " + item.name + " Tidak Dapat Terhubung"
          );
        }
      }
      return new Swalert().error(result.data);
    }
  }

  function modalFormDetail() {
    return (
      <Form noValidate validated={formValidate}>
        <InputLabel
          title="Nama"
          name={"name"}
          defaultValue={object1.name}
          required
          onChange={(e) => updateData(e.target.name, e.target.value)}
          onKeyDown={handleKeyDown}
        />
        <InputLabel
          title="DNS Name"
          name={"dnsName"}
          value={object1.dnsName}
          placeholder={"example.com"}
          required
          onChange={(e) => updateData(e.target.name, e.target.value)}
          onKeyDown={handleKeyDown}
        />
        <InputLabel
          title="IP / Host"
          name={"host"}
          defaultValue={object1.host}
          required
          onChange={(e) => updateData(e.target.name, e.target.value)}
          onKeyDown={handleKeyDown}
        />
        <InputLabel
          title="Port"
          type={"number"}
          name={"port"}
          defaultValue={object1.port}
          required
          onChange={(e) => updateData(e.target.name, e.target.value)}
          onKeyDown={handleKeyDown}
        />
        <InputLabel
          title="User"
          name={"user"}
          defaultValue={object1.user}
          required
          onChange={(e) => updateData(e.target.name, e.target.value)}
          onKeyDown={handleKeyDown}
        />
        <InputLabel
          title="Passsword"
          type={"password"}
          name={"passCript"}
          defaultValue={object1.passCript}
          required
          onChange={(e) => updateData(e.target.name, e.target.value)}
          onKeyDown={handleKeyDown}
        />

        {modalAction === actCreated ? (
          array1.length <= 0 ? (
            <>
              <Div>Noted :</Div>
              <Div>{"Anda akan berlangganan " + serviceHotspot.name}</Div>
              <Div>
                {"dengan biaya "}
                <del>{setRupiah(serviceHotspot.cost)}</del>
                <b style={{ fontSize: "14px" }}>
                  {" " + setRupiah(serviceHotspot.promo) + " / hari"}
                </b>
              </Div>
            </>
          ) : (
            <>
              <Div>Noted :</Div>
              <Div>{"Anda akan tambah " + serviceMikrotik.name}</Div>
              <Div>
                {"dengan biaya "}
                <del>{setRupiah(serviceMikrotik.cost)}</del>
                <b style={{ fontSize: "14px" }}>
                  {" " + setRupiah(serviceMikrotik.promo) + " / hari"}
                </b>
              </Div>
            </>
          )
        ) : (
          ""
        )}
      </Form>
    );
  }
  function setLayoutTable() {
    function pingDataItem(item) {
      return (
        <ButtonGroup>
          <ToggleButton
            variant={"outline-success"}
            style={{ padding: "3px 3px 3px 5px" }}
            onClick={() => pingItem(item)}
          >
            <Icon style={{ fontSize: "20px", marginLeft: "0px" }}>
              network_ping
            </Icon>
          </ToggleButton>
        </ButtonGroup>
      );
    }
    function deleteDataItem(item, index) {
      return (
        <ButtonGroup>
          {index === 0 ? (
            <ToggleButton
              variant={"outline"}
              style={{ padding: "3px 3px 3px 5px" }}
              disabled={true}
            >
              <Icon style={{ fontSize: "20px", marginLeft: "0px" }}>
                delete
              </Icon>
            </ToggleButton>
          ) : (
            <ToggleButton
              variant={"outline-danger"}
              style={{ padding: "3px 3px 3px 5px" }}
              onClick={() => deleteItem(item)}
            >
              <Icon style={{ fontSize: "20px", marginLeft: "0px" }}>
                delete
              </Icon>
            </ToggleButton>
          )}
        </ButtonGroup>
      );
    }
    function capitalizeFirstLetter(str) {
      const arr = str.split(" ");
      for (var i = 0; i < arr.length; i++) {
        arr[i] =
          arr[i].slice(0, 1).toUpperCase() + arr[i].slice(1).toLowerCase();
      }
      const str2 = arr.join(" ");
      return str2;
    }
    function openFormModal(item) {
      openModal(actUpdate, "Update Router", item);
    }

    function dataBody() {
      if (!listTable) {
        return;
      }
      return listTable.map((item, index) => {
        return (
          <Tables.Tr style={{ cursor: "pointer" }}>
            <Tables.Td>{index + 1}</Tables.Td>
            <Tables.Td style={{ cursor: "default" }}>
              {pingDataItem(item)}
            </Tables.Td>
            <Tables.Td onClick={() => openFormModal(item)}>
              {item.name}
            </Tables.Td>
            <Tables.Td onClick={() => openFormModal(item)}>
              {item.dnsName}
            </Tables.Td>
            <Tables.Td onClick={() => openFormModal(item)}>
              {capitalizeFirstLetter(item.host)}
            </Tables.Td>
            <Tables.Td onClick={() => openFormModal(item)}>
              {item.port}
            </Tables.Td>
            <Tables.Td style={{ cursor: "default" }}>
              {deleteDataItem(item, index)}
            </Tables.Td>
          </Tables.Tr>
        );
      });
    }

    return (
      <Tables.Table>
        <Tables.Head>
          <Tables.Tr>
            <Tables.Th>#</Tables.Th>
            <Tables.Th>PING</Tables.Th>
            <Tables.Th>NAMA</Tables.Th>
            <Tables.Th>DNS Name</Tables.Th>
            <Tables.Th>HOST</Tables.Th>
            <Tables.Th>PORT</Tables.Th>
            <Tables.Th>ACTION</Tables.Th>
          </Tables.Tr>
        </Tables.Head>
        <Tables.Body>{dataBody()}</Tables.Body>
      </Tables.Table>
    );
  }
  function setRupiah(value) {
    return new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
      minimumFractionDigits: 0,
    }).format(value);
  }
  return (
    <LayoutHome>
      <LayoutModal onSave={submitHandler}>{modalFormDetail()}</LayoutModal>
      <Card>
        <Stack direction="horizontal" style={{ marginBottom: "5px" }} gap={2}>
          <Div>
            <Button
              variant="outline-primary"
              size="sm"
              onClick={() =>
                openModal(actCreated, "Created User", {
                  orgId: "",
                  name: "",
                  dnsName: "",
                  host: "",
                  port: "0",
                  user: "",
                  pass: "",
                })
              }
            >
              ADD ROUTER
            </Button>
          </Div>
          <Div
            className="ms-auto"
            style={{ alignSelf: "flex-end", width: "120px" }}
          >
            <Form.Control
              type={"text"}
              placeholder={"Cari nama . . ."}
              onChange={(e) => setSearchVal(e.target.value)}
            />
          </Div>
        </Stack>
        {setLayoutTable()}
      </Card>
    </LayoutHome>
  );
};

export default Mikrotik;
