import Access from "./Access";
import Organization from "./Organization";
import Whatsapp from "./Whatsapp";
import Message from "./Message";
import Mikrotik from "./Mikrotik";
import Users from "./Users";
import Setting from "./Setting";

const Master = {
  Access,
  Setting,
  Organization,
  Whatsapp,
  Message,
  Mikrotik,
  Users,
};

export default Master;
