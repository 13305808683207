import { access } from "./access";
import { mikrotik } from "./mikrotik";
import { org } from "./org";
import { setting } from "./setting";
import { message } from "./message";
import { user } from "./user";
import { whatsapp } from "./whatsapp";

const master = { access, org, user, whatsapp, setting, message, mikrotik };

export { master };
