import { useDispatch, useSelector } from "react-redux";
import LayoutHome from "../../components/Layout/Home";
import { useEffect, useState } from "react";
import Div from "../../components/Div";
import {
  ButtonGroup,
  Card,
  Form,
  Stack,
  Table,
  ToggleButton,
} from "react-bootstrap";
import { actions } from "../../store/actions";
import Tables from "../../components/Tables";
import Swalert from "../../components/Swalert";
import JWToken from "../../utils/JWToken";
import { service } from "../../store/service";
import SelectLabel from "../../components/SelectLabel";
import LayoutModal from "../../components/LayoutModal";
import { config } from "../../utils/StaticString";
import Icon from "../../components/Icon";
import InputLabel from "../../components/InputLabel";
import Terbilang from "../../utils/Terbilang";

const User = () => {
  const dispatch = useDispatch();
  const globalReducer = useSelector((state) => state.globalReducer);
  const { accessCRUD, modalAction } = globalReducer;
  const { accCreated, accDelete, accUpdate } = accessCRUD;

  const fUserReducer = useSelector((state) => state.fUserReducer);
  const { array1, array2, array3, object1, object2 } = fUserReducer;

  useEffect(() => {
    console.log(fUserReducer);
  }, [fUserReducer]);

  const [formValidate, setFormValidate] = useState(false);
  const [optionMonthYear, setOptionMonthYear] = useState([]);

  const [optionTransaksi, setOptionTransaksi] = useState([]);
  const [optionUserTransaksi, setOptionUserTransaksi] = useState([]);

  const [fullAccess, setFullAccess] = useState(true);
  const [layoutTable, setLayoutTable] = useState("");

  const actTransaksiKas = "actTransaksiKas";
  const actMutasiKas = "actMutasiKas";

  useEffect(() => {
    if (new JWToken()._getStatusAccessToken()) {
      getFirstData();
    }
  }, []);

  async function getFirstData() {
    const result = await service.finance.user._read(dispatch);
    if (result.status) {
      result.data.users.map((item) => {
        item.value = item.id;
        item.label = item.name;
      });

      setFullAccess(result.data.fullAccess);
      if (!result.data.fullAccess) {
        const user = result.data.users[0];
        updateData1("users", user);
      }
      actions.finance.user.setArray1(dispatch, result.data.users);
      return;
    }

    return new Swalert().error(result.data);
  }

  async function mutasiKas(month) {
    if (!object1.users) {
      return new Swalert().warning("Select User");
    }
    if (!month) {
      return new Swalert().warning("Select Month");
    }
    if (month.value === "") {
      return new Swalert().warning("Select Month");
    }
    const newData = {
      idUser: object1.users.id,
      seller: object1.users.seller,
      name: object1.users.name,
      month: month.value,
    };
    const result = await service.finance.user._mutasiKas(dispatch, newData);
    if (result.status) {
      setLayoutTable(result.data.seller);
      actions.global.setModalShow(dispatch, false);
      actions.finance.user.setArray2(dispatch, result.data);
      return;
    }
    return new Swalert().warning(result.data);
  }
  async function transaksiKas() {
    if (!object2) {
      return new Swalert().warning("Please provide a valid");
    }
    if (object2.type.value === "") {
      return new Swalert().warning("Select Type");
    }
    if (object2.user.id === "") {
      return new Swalert().warning("Select User");
    }
    if (parseInt(object2.nominal) <= 0) {
      return setFormValidate(true);
    }
    if (object2.type.value !== "setoran") {
      if (object2.keterangan === "") {
        return setFormValidate(true);
      }
    }

    const newData = {
      type: object2.type.value,
      idUser: object2.user.id,
      name: object2.user.name,
      seller: object2.user.seller,
      nominal: object2.nominal,
      keterangan: object2.keterangan,
    };
    const result = await service.finance.user._transaksi(dispatch, newData);
    if (result.status) {
      const month = result.data.month;
      updateData1("users", object2.user);
      const obj = { dateMonth: month, label: month, value: month };
      updateData1("month", obj);
      setLayoutTable(result.data.seller);
      actions.global.setModalShow(dispatch, false);
      actions.finance.user.setArray2(dispatch, result.data.mutasi);
      return new Swalert().success("Transaction Success");
    }
    return new Swalert().warning(result.data);
  }

  async function openModal(action, header, item) {
    actions.global.setModalShow(dispatch, true);
    actions.global.setModalAction(dispatch, action);
    actions.global.setModalTitleHeader(dispatch, header);
    if (item) {
      actions.finance.user.setObject1(dispatch, item);
    }
    if (action === actTransaksiKas) {
      const listTrans = [];
      var obj = { value: "setoran", label: "Setoran Admin" };
      listTrans.push(obj);
      obj = { value: "pinjaman", label: "Pinjaman User" };
      listTrans.push(obj);
      obj = { value: "pengembalian", label: "Pengembalian User" };
      listTrans.push(obj);
      setOptionTransaksi(listTrans);
      if (!object2) {
        updateData2("type", { value: "", label: "" });
      }
      updateData2("nominal", "0");
      updateData2("keterangan", "");
    }
    return;
  }
  const updateData1 = async (name, value) => {
    setFormValidate(false);
    const newData = { [name]: value };
    actions.finance.user.updateObject1(dispatch, newData);
    if (name === "users") {
      const obj = { dateMonth: "", label: "", value: "" };
      updateData1("month", obj);
      const result = await service.finance.user._getMonth(dispatch, {
        idUser: value.id,
        seller: value.seller,
      });
      if (result.status) {
        result.data.map((item) => {
          item.value = item.dateMonth;
          item.label = item.dateMonth;
        });
        setOptionMonthYear(result.data);
      }
    }
  };
  const updateData2 = async (name, value) => {
    setFormValidate(false);
    const newData = { [name]: value };
    actions.finance.user.updateObject2(dispatch, newData);

    if (name === "type") {
      updateData2("user", {
        id: "",
        label: "",
        value: "",
        name: "",
        seller: "",
      });
      updateData2("nominal", "0");
      updateData2("keterangan", "");
      const newoption = [];
      if (value.value === "setoran") {
        array1.map((item) => {
          if (item.seller === "Admin") {
            newoption.push(item);
          }
        });
      } else {
        array1.map((item) => {
          if (item.seller !== "Reseller") {
            const data = {
              id: item.id,
              label: item.label,
              value: item.value,
              name: item.name,
              seller: item.seller,
            };
            newoption.push(data);
          }
        });
        newoption.map((item) => {
          if (item.seller !== "") {
            item.label = item.seller + " : " + item.name;
          }
        });
      }
      setOptionUserTransaksi(newoption);
    }
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      submitHandler();
    }
  };

  const submitHandler = async () => {
    if (modalAction === actMutasiKas) {
      mutasiKas(object1.month);
    }
    if (modalAction === actTransaksiKas) {
      transaksiKas();
    }
  };

  function modalFormMutasiKas() {
    return (
      <Form noValidate validated={formValidate}>
        <SelectLabel
          title={"Select User"}
          isSearchable={true}
          options={array1}
          value={object1.users}
          onChange={(e) => updateData1("users", e)}
          getOptionLabel={(o) =>
            o.seller === "" ? o.label : o.seller + " : " + o.label
          }
        />
        <SelectLabel
          title={"Select Month"}
          isSearchable={true}
          options={optionMonthYear}
          value={object1.month}
          onChange={(e) => updateData1("month", e)}
        />
      </Form>
    );
  }
  function modalFormTransaksi() {
    function terbilang() {
      return Terbilang(object2.nominal) + " Rupiah";
    }
    function keterangan(type, user) {
      if (user === "") {
        return;
      }
      if (type === "setoran") {
        return (
          <>
            <span>
              <b>{object2.user.label}</b> Akan melakukan transaksi{" "}
              <b>Setoran</b> kepada Organisasi. aksi ini juga akan otomatis
              melakukan transaksi di keuangan organisasi sebagai{" "}
              <b>Kas Masuk</b> (Setoran) dari <b>{object2.user.label}</b>{" "}
              sebesar <b>{setRupiah(object2.nominal)}</b>.
            </span>
          </>
        );
      }
      if (type === "pinjaman") {
        return (
          <>
            <span>
              <b>{object2.user.label}</b> Akan melakukan <b>Pinjaman</b> kepada
              Organisasi. aksi ini juga akan otomatis melakukan transaksi di
              keuangan organisasi sebagai <b>Kas Keluar</b> (Pinjaman) ke{" "}
              <b>{object2.user.label}</b> sebesar{" "}
              <b>{setRupiah(object2.nominal)}</b>. dengan keterangan{" "}
              {object2.keterangan}.
            </span>
          </>
        );
      }
      if (type === "pengembalian") {
        return (
          <>
            <span>
              <b>{object2.user.label}</b> Akan melakukan transaksi{" "}
              <b>Pengembalian</b> kepada Organisasi. aksi ini juga akan otomatis
              melakukan transaksi di keuangan organisasi sebagai{" "}
              <b>Kas Masuk</b> (Pengembalian) dari <b>{object2.user.label}</b>{" "}
              sebesar <b>{setRupiah(object2.nominal)}</b>. dengan keterangan{" "}
              {object2.keterangan}.
            </span>
          </>
        );
      }
    }
    return (
      <Form noValidate validated={formValidate}>
        <SelectLabel
          title={"Select Type"}
          isSearchable={false}
          options={optionTransaksi}
          value={object2.type}
          onChange={(e) => updateData2("type", e)}
        />
        <SelectLabel
          title={
            object2.type
              ? object2.type.value === "setoran"
                ? "Select Admin"
                : "Select User"
              : "Select"
          }
          isSearchable={true}
          options={optionUserTransaksi}
          value={object2.user}
          onChange={(e) => updateData2("user", e)}
        />
        <InputLabel
          title="Nominal"
          name={"nominal"}
          type={"number"}
          min={1}
          value={object2.nominal}
          required
          notify={terbilang()}
          onChange={(e) => updateData2(e.target.name, e.target.value)}
          onKeyDown={handleKeyDown}
        />
        {object2.type ? (
          object2.type.value !== "setoran" ? (
            <InputLabel
              required
              style={{ marginBottom: "10px" }}
              title="Keterangan"
              name={"keterangan"}
              value={object2.keterangan}
              onChange={(e) => updateData2(e.target.name, e.target.value)}
              onKeyDown={handleKeyDown}
            />
          ) : (
            ""
          )
        ) : (
          ""
        )}

        {object2 ? keterangan(object2.type.value, object2.user.value) : ""}
      </Form>
    );
  }

  function setLayoutTableMutasi() {
    function dataBodyMutasi() {
      if (!array2.mutasi) {
        return;
      }
      var sum = parseInt(array2.saldoAwal);
      return array2.mutasi.map((item, index) => {
        var debet = "";
        var kredit = "";
        sum += parseInt(item.nominal);
        if (parseInt(item.nominal) > 0) {
          debet = setRupiah(item.nominal);
        } else {
          kredit = setRupiah(item.nominal);
        }
        return (
          <Tables.Tr style={{ cursor: "pointer" }}>
            <Tables.Td>{index + 2}</Tables.Td>
            <Tables.Td>{item.dateTime}</Tables.Td>
            <Tables.Td>{item.noTransaksi}</Tables.Td>
            <Tables.Td>{item.keterangan}</Tables.Td>
            <Tables.Td>{debet}</Tables.Td>
            <Tables.Td>{kredit}</Tables.Td>
            <Tables.Td>
              <span style={sum < 0 ? { color: "red" } : {}}>
                {setRupiah(sum)}
              </span>
            </Tables.Td>
          </Tables.Tr>
        );
      });
    }
    return (
      <>
        <InputLabel
          readOnly={true}
          value={array2.name}
          style={{ marginBottom: "0px" }}
        />
        <Tables.Table>
          <Tables.Head>
            <Tables.Tr>
              <Tables.Th>#</Tables.Th>
              <Tables.Th>DATE</Tables.Th>
              <Tables.Th>INVOICE</Tables.Th>
              <Tables.Th>DETAIL</Tables.Th>
              <Tables.Th>DEBET</Tables.Th>
              <Tables.Th>KREDIT</Tables.Th>
              <Tables.Th>SALDO</Tables.Th>
            </Tables.Tr>
          </Tables.Head>
          <Tables.Body>
            {array2.saldoAwal ? (
              <Tables.Tr style={{ cursor: "pointer" }}>
                <Tables.Td>1</Tables.Td>
                <Tables.Td colspan={5}>Saldo Awal</Tables.Td>
                <Tables.Td>{setRupiah(array2.saldoAwal)}</Tables.Td>
              </Tables.Tr>
            ) : (
              ""
            )}
            {dataBodyMutasi()}
          </Tables.Body>
        </Tables.Table>
      </>
    );
  }

  const openInNewTab = (accessCode) => {
    const slug = "files/invoice.php?type=reseller&access=" + accessCode;
    window.open(config.baseUrl + slug, "_blank", "noreferrer");
  };
  function setLayoutTableReseller() {
    if (!array2.mutasi) {
      return;
    }
    function dataBodyMutasi() {
      return array2.mutasi.map((item, index) => {
        function priceWithPPN() {
          return (
            parseInt(item.hargaSeller) +
            (parseInt(item.hargaSeller) * parseInt(item.ppn)) / 100
          );
        }
        return (
          <Tables.Tr style={{ cursor: "pointer" }}>
            <Tables.Td>{index + 1}</Tables.Td>
            <Tables.Td>{item.dateTime}</Tables.Td>
            <Tables.Td>{item.invoice}</Tables.Td>
            <Tables.Td>{"Voucher " + item.profile}</Tables.Td>
            <Tables.Td>{item.totalVoucher}</Tables.Td>
            <Tables.Td>
              {parseInt(item.ppn) > 0
                ? setRupiah(priceWithPPN())
                : setRupiah(item.hargaSeller)}
            </Tables.Td>
            <Tables.Td>
              <ToggleButton
                variant={"outline-primary"}
                style={{ padding: "3px 3px 3px 7px" }}
                onClick={() => openInNewTab(item.codeAccess)}
              >
                <Icon style={{ fontSize: "20px", marginLeft: "0px" }}>
                  description
                </Icon>
              </ToggleButton>
            </Tables.Td>
          </Tables.Tr>
        );
      });
    }
    return (
      <>
        <InputLabel
          readOnly={true}
          value={array2.name}
          style={{ marginBottom: "0px" }}
        />
        <Tables.Table>
          <Tables.Head>
            <Tables.Tr>
              <Tables.Th>#</Tables.Th>
              <Tables.Th>DATE</Tables.Th>
              <Tables.Th>INVOICE</Tables.Th>
              <Tables.Th>DETAIL</Tables.Th>
              <Tables.Th>QTY</Tables.Th>
              <Tables.Th>PRICE</Tables.Th>
              <Tables.Th>PRINT</Tables.Th>
            </Tables.Tr>
          </Tables.Head>
          <Tables.Body>{dataBodyMutasi()}</Tables.Body>
        </Tables.Table>
      </>
    );
  }
  function setRupiah(value) {
    return new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
      minimumFractionDigits: 0,
    }).format(value);
  }

  const styleLeft = {
    textAlign: "left",
    padding: "3px",
  };
  const styleRight = {
    textAlign: "right",
    padding: "3px",
  };
  return (
    <LayoutHome>
      <LayoutModal onSave={submitHandler}>
        {modalAction === actMutasiKas ? modalFormMutasiKas() : ""}
        {modalAction === actTransaksiKas ? modalFormTransaksi() : ""}
      </LayoutModal>
      <Card>
        <Div style={{ margin: "5px" }}>
          {accCreated ? (
            <Stack
              direction="horizontal"
              style={{ marginBottom: "5px" }}
              gap={2}
            >
              <Div style={{ margin: "10px" }}>
                <ButtonGroup>
                  <ToggleButton
                    variant="outline-primary"
                    size="sm"
                    onClick={() =>
                      openModal(actTransaksiKas, "Transaksi Kas", false)
                    }
                  >
                    TRANSAKSI KAS USER
                  </ToggleButton>
                  <ToggleButton
                    variant="outline-success"
                    size="sm"
                    onClick={() => openModal(actMutasiKas, "Mutasi Kas", false)}
                  >
                    MUTASI KAS USER
                  </ToggleButton>
                </ButtonGroup>
                <Table style={{ marginTop: "10px", marginBottom: "20px" }}>
                  <Tables.Body>
                    {array1.map((item) => {
                      if (parseInt(item.saldo) !== 0) {
                        return (
                          <Tables.Tr>
                            <Tables.Td style={styleLeft}>{item.name}</Tables.Td>
                            <Tables.Td style={styleRight}>
                              {parseInt(item.saldo) < 0 ? (
                                <span style={{ color: "red" }}>
                                  {setRupiah(item.saldo)}
                                </span>
                              ) : (
                                setRupiah(item.saldo)
                              )}
                            </Tables.Td>
                          </Tables.Tr>
                        );
                      }
                    })}
                  </Tables.Body>
                </Table>
              </Div>
            </Stack>
          ) : (
            ""
          )}
          {!fullAccess ? (
            <SelectLabel
              title={"Select Month"}
              isSearchable={true}
              options={optionMonthYear}
              value={object2.month}
              onChange={(e) => mutasiKas(e)}
            />
          ) : (
            ""
          )}

          {object1
            ? layoutTable === "Reseller"
              ? setLayoutTableReseller()
              : setLayoutTableMutasi()
            : ""}
        </Div>
      </Card>
    </LayoutHome>
  );
};

export default User;
