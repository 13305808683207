import { Button, Card, Form, Modal } from "react-bootstrap";
import Div from "../../components/Div";
import LayoutHome from "../../components/Layout/Home";
import Tables from "../../components/Tables";
import { useEffect, useState } from "react";
import JWToken from "../../utils/JWToken";
import { useDispatch, useSelector } from "react-redux";
import Icon from "../../components/Icon";
import { PageMenu } from "../../route/PageMenu";
import Swalert from "../../components/Swalert";
import InputLabel from "../../components/InputLabel";
import { service } from "../../store/service";
import { actions } from "../../store/actions";
import LayoutModal from "../../components/LayoutModal";

const MenuPages = () => {
  const dispatch = useDispatch();
  const { modalAction } = useSelector((state) => state.globalReducer);
  const { array1, object1 } = useSelector((state) => state.pPagesReducer);

  const [formValidate, setFormValidate] = useState(false);

  const actCreatedData = "actCreatedData";
  const actUpdateData = "actUpdateData";

  useEffect(() => {
    if (new JWToken()._getStatusAccessToken()) {
      readData();
    }
  }, []);

  async function readData() {
    const result = await service.primary.pages._read(dispatch);
    if (result.status) {
      return actions.primary.pages.setArray1(dispatch, result.data);
    }
    return new Swalert().error(result.data);
  }
  async function openModal(action, header, item) {
    actions.global.setModalShow(dispatch, true);
    actions.global.setModalAction(dispatch, action);
    actions.global.setModalTitleHeader(dispatch, header);
    await actions.primary.pages.setObject1(dispatch, item);
    return;
  }
  const updateData = (name, value) => {
    setFormValidate(false);
    const newData = { [name]: value };
    actions.primary.pages.updateObject1(dispatch, newData);
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      submitHandler();
    }
  };
  const submitHandler = async () => {
    if (!object1.label || !object1.path) {
      return setFormValidate(true);
    }
    actions.global.setModalShow(dispatch, false);
    if (modalAction === actCreatedData) {
      const result = await service.primary.pages._created(dispatch, object1);
      if (result.status) {
        actions.primary.pages.addArray1(dispatch, result.data);
        return new Swalert().success("Insert Data Success");
      }
      return new Swalert().error(result.data);
    }
    if (modalAction === actUpdateData) {
      const result = await service.primary.pages._update(dispatch, object1);
      if (result.status) {
        actions.primary.pages.updateArray1(dispatch, object1);
        return new Swalert().success("Update Data Success");
      }
      return new Swalert().error(result.data);
    }
  };

  async function deleteItem(item) {
    const swal = await new Swalert().confirm("Delete " + item.label, "Yes");
    if (swal) {
      const result = await service.primary.pages._delete(dispatch, item);
      if (result.status) {
        actions.primary.pages.deleteArray1(dispatch, item);
        return new Swalert().success("Delete Success");
      }
      return new Swalert().warning("Delete Failed");
    }
  }
  async function updateUrutPageMenu(event, item) {
    if (event.key === "Enter") {
      item.urut = event.target.value;
      const result = await service.primary.pages._update(dispatch, item);
      if (result.status) {
        actions.primary.pages.updateArray1(dispatch, item);
        return new Swalert().success("Update Urut Success");
      }
      return new Swalert().warning("Update Urut Failed");
    }
  }

  function setLayoutTable() {
    function icon(micon) {
      if (micon !== "") {
        return <Icon style={{ fontSize: "30px" }}>{micon}</Icon>;
      }
    }
    function action(item) {
      let disableButton = true;
      let outlineButton = "outline";
      if (
        item.path !== PageMenu.d_user &&
        item.path !== PageMenu.d_home &&
        item.path !== PageMenu.d_menus &&
        item.path !== PageMenu.m_access
      ) {
        outlineButton = "outline-danger";
        disableButton = false;
      }

      return (
        <Button
          disabled={disableButton}
          onClick={() => deleteItem(item)}
          variant={outlineButton}
          size="sm"
          style={{ padding: "5px 5px 5px 15px" }}
        >
          <Icon style={{ fontSize: "20px" }}>delete</Icon>
        </Button>
      );
    }
    function cekEditItem(item) {
      if (
        item.path !== PageMenu.d_user &&
        item.path !== PageMenu.d_home &&
        item.path !== PageMenu.d_menus &&
        item.path !== PageMenu.m_access
      ) {
        openModal(actUpdateData, "Update Menu", item);
      } else {
        new Swalert().warning(item.label + " ini paten");
      }
    }
    function edutUrutLayout(item) {
      if (
        item.path !== PageMenu.d_user &&
        item.path !== PageMenu.d_home &&
        item.path !== PageMenu.d_menus &&
        item.path !== PageMenu.m_access
      ) {
        return (
          <Form.Control
            style={{ width: "75px" }}
            type={"number"}
            defaultValue={item.urut}
            onKeyDown={(event) => updateUrutPageMenu(event, item)}
          />
        );
      } else {
        return (
          <Form.Control
            style={{ width: "75px" }}
            type={"number"}
            value={item.urut}
            readOnly
          />
        );
      }
    }
    function dataBody() {
      return array1.map((item, index) => {
        return (
          <Tables.Tr>
            <Tables.Td>{index + 1}</Tables.Td>
            <Tables.Td>{edutUrutLayout(item)}</Tables.Td>
            <Tables.Td>{icon(item.icon)}</Tables.Td>
            <Tables.Td
              style={{ cursor: "pointer" }}
              onClick={() => cekEditItem(item)}
            >
              {item.label}
            </Tables.Td>
            <Tables.Td style={{ fontAlign: "left" }}>{item.path}</Tables.Td>
            <Tables.Td>{action(item)}</Tables.Td>
          </Tables.Tr>
        );
      });
    }
    return (
      <Tables.Table>
        <Tables.Head>
          <Tables.Tr>
            <Tables.Th>#</Tables.Th>
            <Tables.Th>URUT</Tables.Th>
            <Tables.Th>ICON</Tables.Th>
            <Tables.Th>LABEL</Tables.Th>
            <Tables.Th>PATH</Tables.Th>
            <Tables.Th>ACTION</Tables.Th>
          </Tables.Tr>
        </Tables.Head>
        <Tables.Body>{dataBody()}</Tables.Body>
      </Tables.Table>
    );
  }
  function setLayoutModal() {
    return (
      <Form noValidate validated={formValidate}>
        <InputLabel
          title="Material Icon"
          name={"icon"}
          value={object1.icon}
          onChange={(e) => updateData(e.target.name, e.target.value)}
          onKeyDown={handleKeyDown}
        />
        <InputLabel
          title="Label"
          name={"label"}
          value={object1.label}
          required
          onChange={(e) => updateData(e.target.name, e.target.value)}
          onKeyDown={handleKeyDown}
        />
        <InputLabel
          title="Path"
          name={"path"}
          value={object1.path}
          required
          onChange={(e) => updateData(e.target.name, e.target.value)}
          onKeyDown={handleKeyDown}
        />
      </Form>
    );
  }

  return (
    <LayoutHome>
      <Card>
        <LayoutModal onSave={submitHandler}>{setLayoutModal()}</LayoutModal>
        <Div style={{ margin: "10px" }}>
          <Button
            variant="outline-primary"
            size="sm"
            onClick={() => openModal(actCreatedData, "Input Data", {})}
          >
            TAMBAH MENU
          </Button>
        </Div>
        {setLayoutTable()}
      </Card>
    </LayoutHome>
  );
};

export default MenuPages;
