import { config } from "../../../../utils/StaticString";
import ObjToFormData from "../../ObjToFormData";
import request from "../../request";

const baseUrl = config.baseUrl + "service/hotspot/history/";

const _read = async (dispatch) => {
  const newPayload = ObjToFormData({});
  return await request(dispatch, newPayload, baseUrl + "read");
};
const _showHistory = async (dispatch, payload) => {
  const newPayload = ObjToFormData(payload);
  return await request(dispatch, newPayload, baseUrl + "showHistory");
};

export const history = {
  _read,
  _showHistory,
};
