import CriptoJS from "../../utils/CriptoJS";
import { storage } from "../../utils/StaticString";
import UseLocalStorage from "../../utils/UseLocalStorage";
import List from "./../../components/List";
import Span from "../Span";
import Icon from "../Icon";
import Div from "../Div";

const MenuItem = ({ path, route }) => {
  const pathname = window.location.pathname;
  const [accessMenu] = UseLocalStorage(storage.accessMenu, "");
  const access = new CriptoJS().decript(accessMenu);
  for (let index = 0; index < access.length; index++) {
    const item = access[index];
    if (item.path === path) {
      const newList = (
        <List.li>
          <Div
            className={pathname === path ? "active" : ""}
            onClick={() => {
              route(item.path);
            }}
          >
            {item.icon !== "" ? <Icon>{item.icon}</Icon> : ""}
            <Span>{item.label}</Span>
          </Div>
        </List.li>
      );
      return newList;
    }
  }
};

export default MenuItem;
