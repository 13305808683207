import { actions } from "../actions";

const initialState = {
  loader: false,
  loaderText: "",
  navigate: false,
  dataUser: false,
  dataOrg: false,
  accessMenu: false,
  accessCRUD: { accCreated: false, accUpdate: false, accDelete: false },
  dataGlobal: {},
  listHarga: [],
  modalAction: false,
  modalShow: false,
  modalTitleHeader: false,
  modalBtnClose: true,
};

const actionType = actions.global.actionType;
const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionType.setLoader:
      return {
        ...state,
        loader: payload,
      };
    case actionType.setLoaderText:
      return {
        ...state,
        loaderText: payload,
      };
    case actionType.setNavigate:
      return {
        ...state,
        navigate: payload,
      };
    case actionType.setDataUser:
      return {
        ...state,
        dataUser: payload,
      };
    case actionType.setDataOrg:
      return {
        ...state,
        dataOrg: payload,
      };
    case actionType.setAccessMenu:
      return {
        ...state,
        accessMenu: payload,
      };
    case actionType.setAccessCRUD:
      return {
        ...state,
        accessCRUD: payload,
      };
    case actionType.setGlobalLayout:
      const keyPayload = Object.keys(payload)[0];
      const valuesPayload = Object.values(payload)[0];

      return {
        ...state,
        dataGlobal: { ...state.dataGlobal, [keyPayload]: valuesPayload },
      };
    case actionType.setFormValidate:
      return { ...state, formValidate: payload };

    case actionType.setListHarga:
      return {
        ...state,
        listHarga: payload,
      };

    case actionType.setDefault:
      return {
        ...state,
        modalAction: false,
        modalShow: false,
        modalTitleHeader: false,
        modalBtnClose: true,
      };

    case actionType.setModalAction:
      return { ...state, modalAction: payload };
    case actionType.setModalShow:
      return { ...state, modalShow: payload };
    case actionType.setModalBtnClose:
      return { ...state, modalBtnClose: payload };
    case actionType.setModalTitleHeader:
      return { ...state, modalTitleHeader: payload };

    case actionType.setObject1:
      return {
        ...state,
        object1: payload,
      };
    case actionType.updateObject1:
      return {
        ...state,
        object1: {
          ...state.object1,
          [Object.keys(payload)[0]]: Object.values(payload)[0],
        },
      };

    case actionType.setObject2:
      return {
        ...state,
        object2: payload,
      };
    case actionType.updateObject2:
      return {
        ...state,
        object2: {
          ...state.object2,
          [Object.keys(payload)[0]]: Object.values(payload)[0],
        },
      };

    case actionType.setObject3:
      return {
        ...state,
        object3: payload,
      };
    case actionType.updateObject3:
      return {
        ...state,
        object3: {
          ...state.object3,
          [Object.keys(payload)[0]]: Object.values(payload)[0],
        },
      };

    case actionType.setObject4:
      return {
        ...state,
        object4: payload,
      };
    case actionType.updateObject4:
      return {
        ...state,
        object4: {
          ...state.object4,
          [Object.keys(payload)[0]]: Object.values(payload)[0],
        },
      };

    case actionType.setObject5:
      return {
        ...state,
        object5: payload,
      };
    case actionType.updateObject5:
      return {
        ...state,
        object5: {
          ...state.object5,
          [Object.keys(payload)[0]]: Object.values(payload)[0],
        },
      };

    case actionType.setOther1:
      return {
        ...state,
        other1: payload,
      };
    case actionType.setOther2:
      return {
        ...state,
        other2: payload,
      };
    case actionType.setOther3:
      return {
        ...state,
        other3: payload,
      };
    case actionType.setOther4:
      return {
        ...state,
        other4: payload,
      };
    case actionType.setOther5:
      return {
        ...state,
        other5: payload,
      };

    default:
      return {
        ...state,
      };
  }
};
export default reducer;
