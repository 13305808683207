import Cookies from "js-cookie";
class JWToken {
  constructor() {
    this.access = "tokenAccess";
    this.refresh = "tokenRefresh";
  }

  _getStatusAccessToken() {
    if (Cookies.get(this.access)) {
      return true;
    }
    return false;
  }
  _getStatusRefreshToken() {
    if (Cookies.get(this.refresh)) {
      return true;
    }
    return false;
  }
  _getValueAccessToken() {
    return Cookies.get(this.access);
  }
  _getValueRefreshToken() {
    return Cookies.get(this.refresh);
  }
  _setJWToken(token) {
    var expiredAccess = new Date(token.expiredAccess);
    var expiredRefresh = new Date(token.expiredRefresh);

    Cookies.set(this.access, token.tokenAccess, {
      expires: expiredAccess,
    });
    Cookies.set("expiredAccess", expiredAccess, {
      expires: expiredAccess,
    });

    Cookies.set(this.refresh, token.tokenRefresh, {
      expires: expiredRefresh,
    });
    Cookies.set("expiredRefresh", expiredRefresh, {
      expires: expiredRefresh,
    });
  }
  _removeCookie = () => {
    Cookies.remove(this.access);
    Cookies.remove(this.refresh);
    Cookies.remove("expiredAccess");
    Cookies.remove("expiredRefresh");
    return true;
  };
}

export default JWToken;
