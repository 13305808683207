import { useDispatch, useSelector } from "react-redux";
import LayoutHome from "../../components/Layout/Home";
import { useEffect, useState } from "react";
import Div from "../../components/Div";
import {
  Alert,
  Button,
  ButtonGroup,
  Card,
  Col,
  Container,
  Form,
  Row,
  Stack,
  ToggleButton,
} from "react-bootstrap";
import { actions } from "../../store/actions";
import Tables from "../../components/Tables";
import Icon from "../../components/Icon";
import Swalert from "../../components/Swalert";
import JWToken from "../../utils/JWToken";
import { service } from "../../store/service";
import InputLabel from "../../components/InputLabel";
import SelectLabel from "../../components/SelectLabel";
import PhoneLabel from "../../components/PhoneLabel";
import Span from "../../components/Span";
import EasyCrop from "../../components/Cropped/EasyCrop";
import Terbilang from "../../utils/Terbilang";
import LayoutModal from "../../components/LayoutModal";
import FilterListTable from "../../utils/FilterListTable";

const HotspotProfile = () => {
  const dispatch = useDispatch();
  const { modalAction, accessCRUD, loader } = useSelector(
    (state) => state.globalReducer
  );
  const { accCreated, accDelete, accUpdate } = accessCRUD;

  const shProfileReducer = useSelector((state) => state.shProfileReducer);
  const { array1, array2, object1 } = shProfileReducer;

  const [formValidate, setFormValidate] = useState(false);
  const [listTable, setListTable] = useState(array1);
  const [searchVal, setSearchVal] = useState("");
  const [alert, setAlert] = useState("");

  const actCreated = "actCreated";
  const actUpdate = "actUpdate";
  const actSync = "actSync";

  const [optionMik, setOptionMik] = useState([]);
  const [optionPool, setOptionPool] = useState([]);
  const [optionQue, setOptionQue] = useState([]);

  const optionByte = [
    { value: "k", label: "Kbps" },
    { value: "M", label: "Mbps" },
  ];
  const optionValidity = [
    { value: "h", label: "Jam" },
    { value: "d", label: "Hari" },
    { value: "s", label: "Unlimited" },
  ];
  const optionLockUser = [
    { value: "enable", label: "enable" },
    { value: "disable", label: "disable" },
  ];

  useEffect(() => {
    for (let index = 0; index < listTable.length; index++) {
      const element = listTable[index];
      if (element.messageErr !== "") {
        setAlert("error in row " + (index + 1) + " : " + element.messageErr);
        index = listTable.length;
      } else {
        setAlert("");
      }
    }
  }, [listTable]);

  useEffect(() => {
    setListTable(FilterListTable(searchVal, array1));
  }, [searchVal]);

  useEffect(() => {
    setListTable(array1);
  }, [array1]);

  useEffect(() => {
    if (new JWToken()._getStatusAccessToken()) {
      getFirtsData();
    }
  }, []);

  const getFirtsData = async () => {
    const result = await service.layanan.hotspot.profile._read(dispatch);
    if (result.status) {
      actions.service.hotspot.profile.setArray1(
        dispatch,
        result.data.listProfile
      );
      actions.service.hotspot.profile.setArray2(
        dispatch,
        result.data.listMikrotik
      );
      return;
    }
    return new Swalert().error(result.data);
  };

  async function openModal(action, header, item) {
    actions.global.setModalShow(dispatch, true);
    actions.global.setModalAction(dispatch, action);
    actions.global.setModalTitleHeader(dispatch, header);
    actions.service.hotspot.profile.setObject1(dispatch, item);
    if (action === actCreated) {
      array2.map((val) => {
        val.value = val.id;
        val.label = val.name;
      });
      setOptionMik(array2);
      return;
    }
    if (action === actUpdate) {
      const newOption = [];
      item.idParent.value = item.idParent.id;
      item.idParent.label = item.idParent.name;
      newOption.push(item.idParent);
      setOptionMik(newOption);
      const payload = { id: item.idParent.id };
      getDataQueuePool(payload);
      return;
    }
    if (action === actSync) {
      array2.map((val) => {
        val.value = val.id;
        val.label = val.name;
      });
      setOptionMik(array2);
      return;
    }
  }
  const updateData = (name, value) => {
    setFormValidate(false);
    const newData = { [name]: value };
    actions.service.hotspot.profile.updateObject1(dispatch, newData);
    if (name === "idParent") {
      const payload = { id: value.id };
      getDataQueuePool(payload);
    }
  };
  async function getDataQueuePool(payload) {
    const result = await service.layanan.hotspot.profile._getPool(
      dispatch,
      payload
    );
    if (result.status) {
      const optPoll = [];
      const arrP = result.data.listPool;
      arrP.map((item) => {
        const obj = { value: item, label: item };
        optPoll.push(obj);
      });
      const optQueue = [];
      const arrQ = result.data.listQueue;
      arrQ.map((item) => {
        const obj = { value: item, label: item };
        optQueue.push(obj);
      });
      setOptionPool(optPoll);
      setOptionQue(optQueue);
      return;
    }
    const alert = await new Swalert().error(result.data);
    if (alert) {
      actions.global.setModalShow(dispatch, false);
    }
  }

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      submitHandler();
    }
  };
  const submitHandler = async () => {
    if (modalAction === actCreated) {
      createdNewData();
    }
    if (modalAction === actUpdate) {
      updateDataItem();
    }
    if (modalAction === actSync) {
      syctMikrotik();
    }
  };

  async function createdNewData() {
    if (object1.name === "") {
      return setFormValidate(true);
    }
    const upload = object1.upload + object1.uploadEnd.value;
    const download = object1.download + object1.downloadEnd.value;

    var price = object1.price;
    var validity = object1.validity;
    if (object1.validityEnd.value === "u") {
      price = "0";
      validity = "0";
    }

    const newData = {
      idParent: object1.idParent.value,
      name: object1.name,
      sharedUser: object1.sharedUser,
      price: price,
      rateLimit: upload + "/" + download,
      limit: validity + object1.validityEnd.value,
      lockUser: object1.lockUser.value,
      addressPool: object1.addressPool.value,
      parentQueue: object1.parentQueue.value,
    };

    const result = await service.layanan.hotspot.profile._created(
      dispatch,
      newData
    );
    if (result.status) {
      actions.global.setModalShow(dispatch, false);
      actions.service.hotspot.profile.addArray1(dispatch, result.data);
      return new Swalert().success("Update Data Success");
    }
    return new Swalert().error(result.data);
  }
  async function updateDataItem() {
    if (object1.name === "") {
      return setFormValidate(true);
    }
    const upload = object1.upload + object1.uploadEnd.value;
    const download = object1.download + object1.downloadEnd.value;

    var price = object1.price;
    var validity = object1.validity;
    if (object1.validityEnd.value === "u") {
      price = "0";
      validity = "0";
    }
    const newData = {
      id: object1.id,
      idParent: object1.idParent.value,
      name: object1.name,
      sharedUser: object1.sharedUser,
      price: price,
      rateLimit: upload + "/" + download,
      limit: validity + object1.validityEnd.value,
      lockUser: object1.lockUser.value,
      addressPool: object1.addressPool.value,
      parentQueue: object1.parentQueue.value,
    };

    const result = await service.layanan.hotspot.profile._update(
      dispatch,
      newData
    );
    if (result.status) {
      actions.global.setModalShow(dispatch, false);
      actions.service.hotspot.profile.updateArray1(dispatch, result.data);
      return new Swalert().success("Update Data Success");
    }
    return new Swalert().error(result.data);
  }
  async function syctMikrotik() {
    const newData = {
      id: object1.idParent.id,
    };

    const result = await service.layanan.hotspot.profile._sync(
      dispatch,
      newData
    );
    if (result.status) {
      actions.global.setModalShow(dispatch, false);
      actions.service.hotspot.profile.setArray1(dispatch, result.data);
      return new Swalert().success("Update Data Success");
    }
    return new Swalert().error(result.data);
  }
  async function deleteItem(item) {
    const swal = await new Swalert().confirm("Delete User " + item.name, "Yes");
    if (swal) {
      item.idParent = item.idParent.id;
      const result = await service.layanan.hotspot.profile._delete(
        dispatch,
        item
      );
      if (result.status) {
        actions.global.setModalShow(dispatch, false);
        actions.service.hotspot.profile.deleteArray1(dispatch, item);
        return new Swalert().success("Delete " + item.name + " Berhasil");
      }
      return new Swalert().error(result.data);
    }
  }

  function terbilang() {
    return Terbilang(object1.price) + " Rupiah";
  }
  function modalFormDetail() {
    return (
      <Form noValidate validated={formValidate}>
        <Container fluid>
          <Row>
            <Col>
              <SelectLabel
                title={"Mikrotik"}
                disabled={true}
                defaultValue={object1.idParent}
                options={modalAction === actCreated ? optionMik : optionMik}
                onChange={(e) => updateData("idParent", e)}
              />
              {object1.idParent !== "0"
                ? loader
                  ? "menghubungkan ke mikrotik"
                  : ""
                : ""}
            </Col>
          </Row>
          {object1.idParent !== "0" ? (
            <Div>
              <Row>
                <Col>
                  <InputLabel
                    title="Nama Profile"
                    name={"name"}
                    defaultValue={object1.name}
                    required
                    onChange={(e) => updateData(e.target.name, e.target.value)}
                    onKeyDown={handleKeyDown}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={8}>
                  <InputLabel
                    title="Upload"
                    name={"upload"}
                    type={"number"}
                    defaultValue={object1.upload}
                    required
                    onChange={(e) => updateData(e.target.name, e.target.value)}
                    onKeyDown={handleKeyDown}
                  />
                </Col>
                <Col>
                  <SelectLabel
                    title="."
                    defaultValue={object1.uploadEnd}
                    options={optionByte}
                    onChange={(e) => updateData("uploadEnd", e)}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={8}>
                  <InputLabel
                    title="Download"
                    name={"download"}
                    type={"number"}
                    defaultValue={object1.download}
                    required
                    onChange={(e) => updateData(e.target.name, e.target.value)}
                    onKeyDown={handleKeyDown}
                  />
                </Col>
                <Col>
                  <SelectLabel
                    title="."
                    defaultValue={object1.downloadEnd}
                    options={optionByte}
                    onChange={(e) => updateData("downloadEnd", e)}
                  />
                </Col>
              </Row>
              <Row>
                <Col xs={8}>
                  <InputLabel
                    title="Limit"
                    name={"validity"}
                    type={"number"}
                    readOnly={
                      object1.validityEnd
                        ? object1.validityEnd.value === "s"
                          ? true
                          : false
                        : false
                    }
                    value={
                      object1.validityEnd
                        ? object1.validityEnd.value === "s"
                          ? "0"
                          : object1.validity
                        : object1.validity
                    }
                    required
                    onChange={(e) => updateData(e.target.name, e.target.value)}
                    onKeyDown={handleKeyDown}
                  />
                </Col>
                <Col>
                  <SelectLabel
                    title="."
                    defaultValue={object1.validityEnd}
                    options={optionValidity}
                    onChange={(e) => updateData("validityEnd", e)}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <InputLabel
                    title="Harga"
                    name={"price"}
                    type={"number"}
                    readOnly={
                      object1.validityEnd
                        ? object1.validityEnd.value === "s"
                          ? true
                          : false
                        : false
                    }
                    value={
                      object1.validityEnd
                        ? object1.validityEnd.value === "s"
                          ? "0"
                          : object1.price
                        : object1.price
                    }
                    required
                    notify={terbilang()}
                    onChange={(e) => updateData(e.target.name, e.target.value)}
                    onKeyDown={handleKeyDown}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <SelectLabel
                    title="Address Poll"
                    defaultValue={object1.addressPool}
                    options={optionPool}
                    onChange={(e) => updateData("addressPool", e)}
                  />
                </Col>
              </Row>
              <Row>
                <Col>
                  <SelectLabel
                    title="Parent Queue"
                    defaultValue={object1.parentQueue}
                    options={optionQue}
                    onChange={(e) => updateData("parentQueue", e)}
                  />
                </Col>
              </Row>
            </Div>
          ) : (
            ""
          )}
        </Container>
      </Form>
    );
  }
  function modalFormSync() {
    return (
      <Form noValidate validated={formValidate}>
        <Container fluid>
          <Row>
            <Col>
              <SelectLabel
                title={"Mikrotik"}
                disabled={true}
                defaultValue={object1.idParent}
                options={modalAction === actCreated ? optionMik : optionMik}
                onChange={(e) => updateData("idParent", e)}
              />
              {object1.idParent !== "0"
                ? loader
                  ? "menghubungkan ke mikrotik"
                  : "anda akan menyinkronkan semua profile ke " +
                    object1.idParent.label +
                    "?"
                : ""}
            </Col>
          </Row>
        </Container>
      </Form>
    );
  }
  function setLayoutTable() {
    function deleteDataItem(item) {
      return (
        <ButtonGroup>
          <ToggleButton
            variant={"outline-danger"}
            style={{ padding: "3px 3px 3px 5px" }}
            onClick={() => deleteItem(item)}
          >
            <Icon style={{ fontSize: "20px", marginLeft: "0px" }}>delete</Icon>
          </ToggleButton>
        </ButtonGroup>
      );
    }
    function capitalizeFirstLetter(str) {
      const arr = str.split(" ");
      for (var i = 0; i < arr.length; i++) {
        arr[i] =
          arr[i].slice(0, 1).toUpperCase() + arr[i].slice(1).toLowerCase();
      }
      const str2 = arr.join(" ");
      return str2;
    }
    function openModalForm(item) {
      openModal(actUpdate, "Update User", item);
    }

    function isPureObject(input) {
      return (
        null !== input &&
        typeof input === "object" &&
        Object.getPrototypeOf(input).isPrototypeOf(Object)
      );
    }
    function dataBody() {
      if (!listTable) {
        return;
      }
      return listTable.map((item, index) => {
        if (item.idParent) {
          if (!isPureObject(item.idParent)) {
            for (let index = 0; index < array2.length; index++) {
              const element = array2[index];
              if (element.id === item.idParent) {
                item.idParent = element;
                index = array2.length;
              }
            }
          }
        }
        if (item.rateLimit) {
          if (!isPureObject(item.rateLimit)) {
            const myArray = item.rateLimit.split("/");
            let up = myArray[0];
            let down = myArray[1];

            const upload = up.slice(0, -1);
            const download = down.slice(0, -1);

            const upEnd = up.slice(-1);
            const downEnd = down.slice(-1);

            item.upload = upload;
            item.download = download;

            optionByte.map((val) => {
              if (upEnd === val.value) {
                item.uploadEnd = val;
              }
              if (downEnd === val.value) {
                item.downloadEnd = val;
              }
            });
          }
        }
        if (item.limit) {
          if (!isPureObject(item.limit)) {
            const limit = item.limit.slice(0, -1);
            const limitEnd = item.limit.slice(-1);
            optionValidity.map((val) => {
              if (limitEnd === val.value) {
                item.validityEnd = val;
              }
            });
            item.validity = limit;
          }
        }
        if (item.lockUser) {
          if (!isPureObject(item.lockUser)) {
            item.lockUser = {
              label: item.lockUser,
              value: item.lockUser,
            };
          }
        }
        if (item.addressPool) {
          if (!isPureObject(item.addressPool)) {
            item.addressPool = {
              label: item.addressPool,
              value: item.addressPool,
            };
          }
        }
        if (item.parentQueue) {
          if (!isPureObject(item.parentQueue)) {
            item.parentQueue = {
              label: item.parentQueue,
              value: item.parentQueue,
            };
          }
        }

        return (
          <Tables.Tr style={{ cursor: "pointer" }}>
            <Tables.Td>{index + 1}</Tables.Td>
            <Tables.Td>{item.idParent.name}</Tables.Td>
            {item.messageErr === "" ? (
              <>
                <Tables.Td onClick={() => openModalForm(item)}>
                  {capitalizeFirstLetter(item.name)}
                </Tables.Td>
                <Tables.Td onClick={() => openModalForm(item)}>
                  {item.upload + item.uploadEnd.label}
                </Tables.Td>
                <Tables.Td onClick={() => openModalForm(item)}>
                  {item.download + item.downloadEnd.label}
                </Tables.Td>
                <Tables.Td onClick={() => openModalForm(item)}>
                  {item.price}
                </Tables.Td>
                <Tables.Td onClick={() => openModalForm(item)}>
                  {item.addressPool.label}
                </Tables.Td>
                <Tables.Td onClick={() => openModalForm(item)}>
                  {item.parentQueue.label}
                </Tables.Td>
              </>
            ) : (
              <Tables.Td colspan={6} onClick={() => openModalForm(item)}>
                <span style={{ color: "red" }}>
                  {"error : " + item.messageErr}
                </span>
              </Tables.Td>
            )}

            {accDelete ? (
              <Tables.Td style={{ cursor: "default" }}>
                {deleteDataItem(item)}
              </Tables.Td>
            ) : (
              ""
            )}
          </Tables.Tr>
        );
      });
    }

    return (
      <Tables.Table>
        <Tables.Head>
          <Tables.Tr>
            <Tables.Th>#</Tables.Th>
            <Tables.Th>MIKROTIK</Tables.Th>
            <Tables.Th>PROFILE</Tables.Th>
            <Tables.Th>UPLOAD</Tables.Th>
            <Tables.Th>DOWNLOAD</Tables.Th>
            <Tables.Th>HARGA</Tables.Th>
            <Tables.Th>POOL</Tables.Th>
            <Tables.Th>QUEUE</Tables.Th>
            {accDelete ? <Tables.Th>ACTION</Tables.Th> : ""}
          </Tables.Tr>
        </Tables.Head>
        <Tables.Body>{dataBody()}</Tables.Body>
      </Tables.Table>
    );
  }
  const createdData = (act) => {
    const firstData = {
      name: "",
      idParent: "0",
      sharedUser: "1",
      upload: "1",
      uploadEnd: { value: "M", label: "Mbps" },
      download: "1",
      downloadEnd: { value: "M", label: "Mbps" },
      validity: "1",
      validityEnd: { value: "h", label: "Jam" },
      price: "2000",
      lockUser: { value: "enable", label: "enable" },
      addressPool: { value: "none", label: "none" },
      parentQueue: { value: "none", label: "none" },
    };
    if (act === actCreated) {
      return openModal(act, "Created Profile", firstData);
    }
    return openModal(act, "Sync Profile", firstData);
  };
  return (
    <LayoutHome>
      <LayoutModal onSave={submitHandler}>
        {modalAction === actCreated ? modalFormDetail() : ""}
        {modalAction === actUpdate ? modalFormDetail() : ""}
        {modalAction === actSync ? modalFormSync() : ""}
      </LayoutModal>
      {alert !== "" ? <Alert variant={"danger"}>{alert}</Alert> : ""}
      <Card>
        <Stack direction="horizontal" style={{ marginBottom: "5px" }} gap={2}>
          <Div>
            <ButtonGroup>
              {accCreated ? (
                <ToggleButton
                  variant={"outline-primary"}
                  style={{ padding: "3px 3px 3px 7px" }}
                  onClick={() => createdData(actCreated)}
                >
                  <Icon style={{ fontSize: "20px", marginLeft: "0px" }}>
                    add_circle
                  </Icon>
                </ToggleButton>
              ) : (
                ""
              )}

              {accUpdate || accCreated || accDelete ? (
                <ToggleButton
                  variant={"outline-success"}
                  style={{ padding: "3px 3px 3px 7px" }}
                  onClick={() => createdData(actSync)}
                >
                  <Icon style={{ fontSize: "20px", marginLeft: "0px" }}>
                    sync
                  </Icon>
                </ToggleButton>
              ) : (
                ""
              )}
            </ButtonGroup>
          </Div>
          <Div
            className="ms-auto"
            style={{ alignSelf: "flex-end", width: "120px" }}
          >
            <Form.Control
              type={"text"}
              placeholder={"Cari profile . . ."}
              onChange={(e) => setSearchVal(e.target.value)}
            />
          </Div>
        </Stack>
        {setLayoutTable()}
      </Card>
    </LayoutHome>
  );
};

export default HotspotProfile;
