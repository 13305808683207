import { Card, Nav } from "react-bootstrap";
import Div from "../../Div";
import Icon from "../../Icon";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { actions } from "../../../store/actions";
import UseLocalStorage from "../../../utils/UseLocalStorage";
import { storage } from "../../../utils/StaticString";
import Theme from "./../../../utils/Theme";
import User from "./User";
const Header = () => {
  const dispatch = useDispatch();
  const { dataGlobal } = useSelector((state) => state.globalReducer);

  const [a, setLocalColorTheme] = UseLocalStorage(storage.themes, false);

  useEffect(() => {
    updateTheme(dataGlobal.colorTheme);
  }, []);

  const updateTheme = async (colorTheme) => {
    if (colorTheme === "dark_mode") {
      setDataThemes(Theme.Dark_Mode(), <Icon>light_mode</Icon>);
    } else {
      setDataThemes(
        Theme.Light_Mode(),
        <Icon addClass={"-two-tone"}>dark_mode</Icon>
      );
    }
  };
  const setDataThemes = (cssThemes, iconTheme) => {
    actions.global.setGlobalLayout(dispatch, { cssThemes: cssThemes });
    actions.global.setGlobalLayout(dispatch, { iconTheme: iconTheme });
  };

  const setTheme = (colorTheme) => {
    updateTheme(colorTheme);
    setLocalColorTheme(colorTheme);
    actions.global.setGlobalLayout(dispatch, { colorTheme: colorTheme });
  };
  const updateThemeSql = async (theme) => {
    actions.global.setThemeToSQL(dispatch, { themes: theme });
  };
  return (
    <>
      {dataGlobal.cssThemes}
      <Div className="users">
        <Card>
          <User />
        </Card>
      </Div>
      <Div className="title">
        <Card>
          <Nav as="ul" className="navbar-nav">
            <Nav.Item
              as="li"
              className="nav-item"
              style={dataGlobal.styleIconDark}
            >
              <Icon addClass=" icon" onClick={dataGlobal.actSidebar}>
                last_page
              </Icon>
              <Div className="header">
                <b
                  style={{ cursor: "pointer" }}
                  onClick={dataGlobal.actSidebar}
                >
                  {dataGlobal.headerName}
                </b>
              </Div>
            </Nav.Item>
          </Nav>
          <Nav as="ul" className="navbar-nav ms-auto">
            <Nav.Item as="li" className="nav-item">
              <Div
                className="icon-dark"
                style={dataGlobal.styleIconDark}
                onClick={() => {
                  if (typeof setTheme === "function") {
                    if (dataGlobal.colorTheme === "light_mode") {
                      setTheme("dark_mode");
                      updateThemeSql("dark_mode");
                    } else {
                      setTheme("light_mode");
                      updateThemeSql("light_mode");
                    }
                  }
                }}
              >
                {dataGlobal.iconTheme}
              </Div>
            </Nav.Item>
          </Nav>
        </Card>
      </Div>
    </>
  );
};

export default Header;
