import { useDispatch, useSelector } from "react-redux";
import LayoutHome from "../../components/Layout/Home";
import { useEffect, useState } from "react";
import Div from "../../components/Div";
import {
  Button,
  Card,
  Form,
  InputGroup,
  Modal,
  Table,
  ToggleButton,
} from "react-bootstrap";
import JWToken from "../../utils/JWToken";
import { service } from "../../store/service";
import { actions } from "../../store/actions";
import Swalert from "../../components/Swalert";
import Tables from "../../components/Tables";
import { PageMenu } from "../../route/PageMenu";
import Icon from "../../components/Icon";
import LayoutModal from "../../components/LayoutModal";

const Access = () => {
  const dispatch = useDispatch();
  const { modalAction, accessCRUD } = useSelector(
    (state) => state.globalReducer
  );
  const { accCreated, accDelete } = accessCRUD;
  const { array1, array2, object1 } = useSelector(
    (state) => state.mAccessReducer
  );

  const [formValidate, setFormValidate] = useState(false);

  const actCreatedData = "actCreatedData";
  const actUpdateData = "actUpdateData";

  useEffect(() => {
    if (new JWToken()._getStatusAccessToken()) {
      readData();
    }
  }, []);

  async function readData() {
    const result = await service.master.access._read(dispatch);
    if (result.status) {
      const menu = result.data.menu;
      const access = result.data.access;

      menu.map((item) => {
        item.id = parseInt(item.id);
      });

      actions.master.access.setArray2(dispatch, menu);
      actions.master.access.setArray1(dispatch, access);
      return;
    }
    return new Swalert().error(result.data);
  }

  function setValueModalData(item) {
    item.checkAll = true;
    array2.map((menus) => {
      menus.checked = false;
      menus.permission = [0, 0, 0];
      if (menus.path === PageMenu.d_user || menus.path === PageMenu.d_home) {
        menus.checked = true;
        menus.permission = [1, 1, 1];
      }

      if (item.accessName === "Developer") {
        if (
          menus.path === PageMenu.d_menus ||
          menus.path === PageMenu.m_access
        ) {
          menus.checked = true;
          menus.permission = [1, 1, 1];
        }
      }

      const jsonMenu = item.jsonMenu;
      for (let index = 0; index < jsonMenu.length; index++) {
        const element = jsonMenu[index];
        if (parseInt(element.id) === parseInt(menus.id)) {
          menus.checked = true;
          menus.permission = element.permission;
          index = jsonMenu.length;
        }
      }
      if (menus.path !== PageMenu.d_user || menus.path !== PageMenu.d_home) {
        if (menus.accessName === "Developer") {
          if (
            menus.path !== PageMenu.d_menus ||
            menus.path !== PageMenu.m_access
          ) {
            if (!menus.checked) {
              item.checkAll = false;
            }
          }
        } else {
          if (!menus.checked) {
            item.checkAll = false;
          }
        }
      }
    });
    item.listMenus = array2;

    return item;
  }
  async function openModal(action, header, item) {
    actions.global.setModalShow(dispatch, true);
    actions.global.setModalAction(dispatch, action);
    actions.global.setModalTitleHeader(dispatch, header);
    item = setValueModalData(item);
    actions.master.access.setObject1(dispatch, item);
    return;
  }
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      submitHandler();
    }
  };
  const submitHandler = async () => {
    const jsonMenu = [];
    for (let index = 0; index < object1.listMenus.length; index++) {
      const element = object1.listMenus[index];
      if (element.checked) {
        const newObj = {
          id: parseInt(element.id),
          permission: element.permission,
        };
        jsonMenu.push(newObj);
      }
    }
    if (object1.accessName === "") {
      return new Swalert().error("Nama access tidak boleh kosong");
    }
    const newData = {
      id: object1.id,
      jsonMenu: JSON.stringify(jsonMenu),
      accessName: object1.accessName,
    };

    if (modalAction === actCreatedData) {
      const result = await service.master.access._created(dispatch, newData);
      if (result.status) {
        actions.global.setModalShow(dispatch, false);
        actions.master.access.addArray1(dispatch, result.data);
        return new Swalert().success("Insert Data Success");
      }
      return new Swalert().error(result.data);
    } else if (modalAction === actUpdateData) {
      const result = await service.master.access._update(dispatch, newData);
      if (result.status) {
        actions.global.setModalShow(dispatch, false);
        actions.master.access.updateArray1(dispatch, result.data);
        return new Swalert().success("Update Data Success");
      }
      return new Swalert().error(result.data);
    }
  };

  async function deleteItem(item) {
    const swal = await new Swalert().confirm(
      "Delete " + item.accessName,
      "Yes"
    );
    if (swal) {
      const result = await service.master.access._delete(dispatch, item);
      if (result.status) {
        actions.master.access.deleteArray1(dispatch, item);
        return new Swalert().success("Delete Data Success");
      }
      return new Swalert().error(result.data);
    }
  }

  function setLayoutModal() {
    function cekTrueCUD(item) {
      if (
        item.path === PageMenu.m_org ||
        item.path === PageMenu.m_mikrotik ||
        item.path === PageMenu.m_whatsapp ||
        item.path === PageMenu.sh_active ||
        item.path === PageMenu.sh_online ||
        item.path === PageMenu.sh_history
      ) {
        return true;
      }
      return false;
    }
    function checkedAllItemRead() {
      const checked = !object1.checkAll;
      object1.checkAll = checked;
      object1.listMenus.map((item) => {
        item.checked = checked;
        if (object1.accessName === "Developer") {
          if (
            item.path === PageMenu.d_menus ||
            item.path === PageMenu.m_access
          ) {
            item.checked = true;
            item.permission = [1, 1, 1];
          }
        } else {
          if (item.path === PageMenu.d_menus) {
            item.checked = false;
          }
        }
        if (cekTrueCUD(item)) {
          item.permission = [1, 1, 1];
        }
      });
      actions.master.access.setObject1(dispatch, object1);
    }
    function chekedItemRead(e, item) {
      if (cekTrueCUD(item)) {
        item.permission = [1, 1, 1];
      }
      const checked = e.target.checked;
      item.checked = checked;
      object1.listMenus.map((menus) => (menus.id === item.id ? item : menus));
      actions.master.access.updateObject1(dispatch, {
        listMenus: object1.listMenus,
      });
    }
    function chekedItemAccess(e, item, no) {
      const checked = e.target.checked;
      const newData = [];

      for (let index = 0; index < object1.listMenus.length; index++) {
        const menus = object1.listMenus[index];
        const newObj = {
          id: menus.id,
          icon: menus.icon,
          label: menus.label,
          path: menus.path,
          checked: menus.checked,
          urut: menus.urut,
          permission: menus.permission,
        };
        if (menus.id === item.id) {
          const arrPerm = [];
          menus.permission.map((eccessId, index) => {
            if (index === no) {
              arrPerm.push(checked ? 1 : 0);
            } else {
              arrPerm.push(eccessId);
            }
          });
          newObj.permission = arrPerm;
        }
        newData.push(newObj);
      }

      actions.master.access.updateObject1(dispatch, { listMenus: newData });
    }
    function changeAccessName(e) {
      const newName = e.target.value;
      if (newName === "Developer") {
        actions.global.setModalShow(dispatch, false);
        new Swalert().warning("Nama Access sudah ada.");
      } else {
        actions.master.access.updateObject1(dispatch, { accessName: newName });
      }
    }
    function dataBody() {
      if (!object1.listMenus) {
        return "";
      }

      return object1.listMenus.map((item) => {
        if (
          item.path !== PageMenu.d_user &&
          item.path !== PageMenu.d_home &&
          item.path !== PageMenu.d_menus
        ) {
          let layoutCreated = "";
          let layoutUpdate = "";
          let layoutDelete = "";
          let layoutRead = (
            <InputGroup.Checkbox
              className="form-check-input-success"
              defaultChecked={item.checked}
              onChange={(e) => chekedItemRead(e, item)}
            />
          );

          if (item.checked) {
            item.permission.map((eccessId, index) => {
              if (index === 0) {
                layoutCreated = (
                  <InputGroup.Checkbox
                    className="form-check-input-primary"
                    defaultChecked={eccessId}
                    onChange={(e) => chekedItemAccess(e, item, 0)}
                  />
                );
              }
              if (index === 1) {
                layoutUpdate = (
                  <InputGroup.Checkbox
                    className="form-check-input-warning"
                    defaultChecked={eccessId}
                    onChange={(e) => chekedItemAccess(e, item, 1)}
                  />
                );
              }
              if (index === 2) {
                layoutDelete = (
                  <InputGroup.Checkbox
                    className="form-check-input-danger"
                    defaultChecked={eccessId}
                    onChange={(e) => chekedItemAccess(e, item, 2)}
                  />
                );
              }
            });
          }

          if (object1.accessName === "Developer") {
            if (item.path === PageMenu.m_access) {
              item.permission = [1, 1, 1];
              layoutRead = (
                <InputGroup.Checkbox
                  className="form-check-input-success"
                  defaultChecked={true}
                  disabled
                />
              );
              layoutCreated = (
                <InputGroup.Checkbox
                  className="form-check-input-primary"
                  defaultChecked={true}
                  disabled
                />
              );
              layoutUpdate = (
                <InputGroup.Checkbox
                  className="form-check-input-warning"
                  defaultChecked={true}
                  disabled
                />
              );
              layoutDelete = (
                <InputGroup.Checkbox
                  className="form-check-input-danger"
                  defaultChecked={true}
                  disabled
                />
              );
            }
          }

          if (cekTrueCUD(item)) {
            layoutCreated = ".";
            layoutUpdate = ".";
            layoutDelete = ".";
          }
          return (
            <Tables.Tr>
              <Tables.Td style={{ textAlign: "left" }}>{item.label}</Tables.Td>
              <Tables.Td style={{ textAlign: "left" }}>{item.path}</Tables.Td>
              <Tables.Td>{layoutRead}</Tables.Td>
              <Tables.Td>{layoutCreated}</Tables.Td>
              <Tables.Td>{layoutUpdate}</Tables.Td>
              <Tables.Td>{layoutDelete}</Tables.Td>
            </Tables.Tr>
          );
        }
      });
    }
    return (
      <Form noValidate validated={formValidate}>
        <Form.Group className="mb-3">
          <Form.Label>Access Name</Form.Label>
          <Form.Control
            type="text"
            readOnly={object1.accessName === "Developer" ? true : false}
            defaultValue={object1.accessName}
            placeholder="Enter Access Name"
            onChange={changeAccessName}
            onKeyDown={handleKeyDown}
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Tables.Table>
            <Tables.Head>
              <Tables.Tr>
                <Tables.Th>LABEL</Tables.Th>
                <Tables.Th>PATH</Tables.Th>
                <Tables.Th>
                  <ToggleButton
                    type="checkbox"
                    id="toggle-check"
                    variant="outline-success"
                    checked={object1.checkAll}
                    onClick={checkedAllItemRead}
                    style={{
                      padding: "2px",
                      height: "24px",
                      width: "30px",
                    }}
                  >
                    <Icon
                      style={{
                        fontSize: "18px",
                        marginLeft: "3px",
                        marginRight: "3px",
                        lineHeight: "15px",
                      }}
                    >
                      visibility
                    </Icon>
                  </ToggleButton>
                </Tables.Th>
                <Tables.Th>
                  <ToggleButton
                    variant="outline-primary"
                    style={{
                      cursor: "text",
                      padding: "2px",
                      height: "24px",
                      width: "30px",
                    }}
                  >
                    <Icon
                      style={{
                        fontSize: "18px",
                        marginLeft: "3px",
                        marginRight: "3px",
                        lineHeight: "15px",
                      }}
                    >
                      add_circle
                    </Icon>
                  </ToggleButton>
                </Tables.Th>
                <Tables.Th>
                  <ToggleButton
                    variant="outline-warning"
                    style={{
                      cursor: "text",
                      padding: "2px",
                      height: "24px",
                      width: "30px",
                    }}
                  >
                    <Icon
                      style={{
                        fontSize: "18px",
                        marginLeft: "3px",
                        marginRight: "3px",
                        lineHeight: "15px",
                      }}
                    >
                      edit
                    </Icon>
                  </ToggleButton>
                </Tables.Th>
                <Tables.Th>
                  <ToggleButton
                    variant="outline-danger"
                    style={{
                      cursor: "text",
                      padding: "2px",
                      height: "24px",
                      width: "30px",
                    }}
                  >
                    <Icon
                      style={{
                        fontSize: "18px",
                        marginLeft: "3px",
                        marginRight: "3px",
                        lineHeight: "15px",
                      }}
                    >
                      delete
                    </Icon>
                  </ToggleButton>
                </Tables.Th>
              </Tables.Tr>
            </Tables.Head>
            <Tables.Body>{dataBody()}</Tables.Body>
          </Tables.Table>
          {modalAction === actUpdateData ? (
            <Div style={{ textAlign: "center", color: "red" }}>
              <br></br>
              Untuk penerapan, silahkan Logout & Login kembali yang memiliki
              akses {object1.accessName}
            </Div>
          ) : (
            ""
          )}
        </Form.Group>
      </Form>
    );
  }

  function setLayoutTable() {
    function action(item) {
      let disableButton = true;
      let outlineButton = "outline";
      if (item.accessName !== "Developer") {
        outlineButton = "outline-danger";
        disableButton = false;
      }

      return (
        <Button
          disabled={disableButton}
          variant={outlineButton}
          size="sm"
          style={{ padding: "5px 5px 5px 15px" }}
          onClick={() => deleteItem(item)}
        >
          <i className="material-icons" style={{ fontSize: "20px" }}>
            delete
          </i>
        </Button>
      );
    }
    function setTableMenu(item) {
      const styleMenu = {
        backgroundColor: "#ffffff00",
        textAlign: "left",
        padding: "1px",
      };
      return item.jsonMenu.map((access) => {
        function iconAccess() {
          return access.permission.map((access, index) => {
            if (index === 0) {
              const addClass = access === 0 ? "" : " text-primary";
              return (
                <Icon
                  addClass={addClass}
                  style={{ fontSize: "18px", marginLeft: "3px" }}
                >
                  add_circle
                </Icon>
              );
            }
            if (index === 1) {
              const addClass = access === 0 ? "" : " text-warning";
              return (
                <Icon
                  addClass={addClass}
                  style={{ fontSize: "18px", marginLeft: "3px" }}
                >
                  edit
                </Icon>
              );
            }
            if (index === 2) {
              const addClass = access === 0 ? "" : " text-danger";
              return (
                <Icon
                  addClass={addClass}
                  style={{ fontSize: "18px", marginLeft: "3px" }}
                >
                  delete
                </Icon>
              );
            }
          });
        }
        return array2.map((itemMenu) => {
          if (itemMenu.id === access.id) {
            if (
              itemMenu.path !== PageMenu.d_user &&
              itemMenu.path !== PageMenu.d_home &&
              itemMenu.path !== PageMenu.d_menus
            ) {
              return (
                <tr style={styleMenu}>
                  <td style={styleMenu}>{itemMenu.path}</td>
                  <td style={styleMenu}>{iconAccess()}</td>
                </tr>
              );
            }
          }
        });
      });
    }
    function dataBody() {
      return array1.map((item, index) => {
        return (
          <>
            <Tables.Tr>
              <Tables.Td>{index + 1}</Tables.Td>
              <Tables.Td
                style={{ cursor: "pointer" }}
                onClick={() => openModal(actUpdateData, "Update Access", item)}
              >
                {item.accessName}
              </Tables.Td>
              <Tables.Td
                style={{ cursor: "pointer", textAlign: "left" }}
                onClick={() => openModal(actUpdateData, "Update Access", item)}
              >
                <Table style={{ margin: "0px" }}>
                  <Tables.Body>{setTableMenu(item)}</Tables.Body>
                </Table>
              </Tables.Td>
              {accDelete ? <Tables.Td>{action(item)}</Tables.Td> : ""}
            </Tables.Tr>
          </>
        );
      });
    }

    return (
      <Tables.Table>
        <Tables.Head>
          <Tables.Tr>
            <Tables.Th>#</Tables.Th>
            <Tables.Th>NAMA</Tables.Th>
            <Tables.Th>MENU</Tables.Th>
            {accDelete ? <Tables.Th>ACTION</Tables.Th> : ""}
          </Tables.Tr>
        </Tables.Head>
        <Tables.Body>{dataBody()}</Tables.Body>
      </Tables.Table>
    );
  }

  const item = { id: null, accessName: "", jsonMenu: [] };
  return (
    <LayoutHome>
      <LayoutModal onSave={submitHandler}>{setLayoutModal()}</LayoutModal>
      <Card>
        {accCreated ? (
          <Div style={{ margin: "10px" }}>
            <Button
              variant="outline-primary"
              size="sm"
              onClick={() => openModal(actCreatedData, "Add Access", item)}
            >
              Tambah Access
            </Button>
          </Div>
        ) : (
          ""
        )}
        {setLayoutTable()}
      </Card>
    </LayoutHome>
  );
};

export default Access;
