import axios from "axios";
import { config } from "../../../utils/StaticString";
import ObjToFormData from "../../../utils/ObjToFormData";
import { actions } from "../../actions";

const baseUrl = config.baseUrl + "auth/";

const signUserPass = async (dispatch, payload) => {
  const newPayload = ObjToFormData(payload);
  return await actionPost(dispatch, newPayload, baseUrl + "sigInUserPass");
};

const signEmail = async (dispatch, payload) => {
  const newPayload = ObjToFormData(payload);
  return await actionPost(dispatch, newPayload, baseUrl + "sigInEmail");
};
const signRefferal = async (dispatch, payload) => {
  const newPayload = ObjToFormData(payload);
  return await actionPost(dispatch, newPayload, baseUrl + "sigInRefferal");
};

const actionPost = async (dispatch, payload, urlBackend) => {
  actions.global.setLoader(dispatch, true);
  try {
    const req = await axios.post(urlBackend, payload);
    actions.global.setLoader(dispatch, false);
    return req.data;
  } catch (e) {
    actions.global.setLoader(dispatch, false);
    return { status: "network_error", error: e };
  }
};

export const sign = {
  signRefferal,
  signUserPass,
  signEmail,
};
