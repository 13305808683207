import { Helmet } from "react-helmet";

const Dark_Mode = () => {
  const protocol = window.location.protocol;
  const host = window.location.host;
  const themeCSS = protocol + "//" + host + "/css/darkTheme.css";

  const theme = window.document.head.querySelector('link[name="lightThemes"]');
  if (theme) {
    theme.remove();
  }
  return (
    <Helmet>
      <link name="darkTheme" href={themeCSS} rel="stylesheet" />
    </Helmet>
  );
};

const Light_Mode = () => {
  const protocol = window.location.protocol;
  const host = window.location.host;
  const themeCSS = protocol + "//" + host + "/css/lightThemes.css";

  const theme = window.document.head.querySelector('link[name="darkTheme"]');
  if (theme) {
    theme.remove();
  }
  return (
    <Helmet>
      <link name="lightThemes" href={themeCSS} rel="stylesheet" />
    </Helmet>
  );
};

const Theme = {
  Dark_Mode: Dark_Mode,
  Light_Mode: Light_Mode,
};

export default Theme;
