import Icon from "../components/Icon";
import Div from "../components/Div";
import MenuColapse from "../components/Sidebar/MenuColapse";
import MenuItem from "../components/Sidebar/MenuItem";
import Span from "../components/Span";
import List from "../components/List";
import { PageMenu } from "./PageMenu";
import { useSelector } from "react-redux";
import { useEffect } from "react";

const PageSidebar = ({ openRoute, logOut }) => {
  const { dataOrg } = useSelector((state) => state.globalReducer);
  function listMenu() {
    return (
      <>
        <MenuItem path={PageMenu.d_user} route={openRoute} />
        <MenuItem path={PageMenu.d_home} route={openRoute} />
        <MenuColapse label={"Master"} icon={"filter_tilt_shift"}>
          <MenuItem path={PageMenu.m_access} route={openRoute} />
          <MenuItem path={PageMenu.m_settings} route={openRoute} />
          <MenuItem path={PageMenu.m_org} route={openRoute} />
          <MenuItem path={PageMenu.m_mikrotik} route={openRoute} />
          <MenuItem path={PageMenu.m_whatsapp} route={openRoute} />
          <MenuItem path={PageMenu.m_message} route={openRoute} />
          <MenuItem path={PageMenu.m_users} route={openRoute} />
        </MenuColapse>
        <MenuColapse label={"Service"} icon={"subject"}>
          <MenuColapse label={"Hotspot"}>
            <MenuItem path={PageMenu.sh_setting} route={openRoute} />
            <MenuItem path={PageMenu.sh_profile} route={openRoute} />
            <MenuItem path={PageMenu.sh_voucher} route={openRoute} />
            <MenuItem path={PageMenu.sh_active} route={openRoute} />
            <MenuItem path={PageMenu.sh_online} route={openRoute} />
            <MenuItem path={PageMenu.sh_history} route={openRoute} />
            <MenuItem path={PageMenu.sh_template} route={openRoute} />
          </MenuColapse>
          <MenuColapse label={"Static"}>
            <MenuItem path={PageMenu.ss_profile} route={openRoute} />
          </MenuColapse>
          <MenuColapse label={"PPoE"}>
            <MenuItem path={PageMenu.sp_profile} route={openRoute} />
          </MenuColapse>
        </MenuColapse>
        <MenuColapse label={"Finance"} icon={"monetization_on"}>
          <MenuItem path={PageMenu.f_org} route={openRoute} />
          <MenuItem path={PageMenu.f_user} route={openRoute} />
        </MenuColapse>
      </>
    );
  }
  return (
    <List.ul className="accordion-menu">
      <Div className="sidebar-title">Menu</Div>
      {dataOrg.orgName !== "" ? (
        listMenu()
      ) : (
        <List.li>
          <Div>
            <Span style={{ color: "red" }}>
              {
                "silahkan isi nama organisasi dan whatsaap bot untuk menampilkan semua menu"
              }
            </Span>
          </Div>
        </List.li>
      )}
      <Div className="sidebar-title">Other</Div>
      <MenuItem path={PageMenu.d_menus} route={openRoute} />
      <List.li>
        <Div onClick={logOut}>
          <Icon>logout</Icon>
          <Span>Logout</Span>
        </Div>
      </List.li>
    </List.ul>
  );
};

export default PageSidebar;
