import { config } from "../../../utils/StaticString";
import ObjToFormData from "../ObjToFormData";
import request from "../request";

const baseUrl = config.baseUrl + "master/whatsapp/";

const _read = async (dispatch) => {
  const newPayload = ObjToFormData({});
  return await request(dispatch, newPayload, baseUrl + "read");
};
const _update = async (dispatch, payload) => {
  const newPayload = ObjToFormData(payload);
  return await request(dispatch, newPayload, baseUrl + "update");
};
const _sendMessage = async (dispatch, payload) => {
  const newPayload = ObjToFormData(payload);
  return await request(dispatch, newPayload, baseUrl + "sendMessage");
};
const _getAutoReply = async (dispatch, payload) => {
  const newPayload = ObjToFormData(payload);
  return await request(dispatch, newPayload, baseUrl + "getAutoReply");
};
const _setAutoReply = async (dispatch, payload) => {
  const newPayload = ObjToFormData(payload);
  return await request(dispatch, newPayload, baseUrl + "setAutoReply");
};

export const whatsapp = {
  _read,
  _update,
  _sendMessage,
  _getAutoReply,
  _setAutoReply,
};
