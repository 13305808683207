import LayoutHome from "../../components/Layout/Home";
import Div from "../../components/Div";
import {
  Button,
  ButtonGroup,
  Card,
  Col,
  Container,
  Form,
  Row,
  Stack,
  Table,
  ToggleButton,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import JWToken from "../../utils/JWToken";
import { service } from "../../store/service";
import { actions } from "../../store/actions";
import Swalert from "../../components/Swalert";
import SelectLabel from "../../components/SelectLabel";
import LayoutModal from "../../components/LayoutModal";
import InputLabel from "../../components/InputLabel";
import Tables from "../../components/Tables";
import Icon from "../../components/Icon";
import FilterListTable from "../../utils/FilterListTable";

const HotspotHistory = () => {
  const dispatch = useDispatch();

  const shHistoryReducer = useSelector((state) => state.shHistoryReducer);
  const { array1, array3, array4, array5, object1 } = shHistoryReducer;

  const [listTable, setListTable] = useState(array1);
  const [searchVal, setSearchVal] = useState("");

  const [optionAdmin, setOptionAdmin] = useState([]);
  const [seller, setSeller] = useState(false);

  const [omset, setOmset] = useState("0");
  const [orgn, setOrgn] = useState("0");
  const [sharing, setSharing] = useState("0");

  const optionType = [
    { value: "daily", label: "Daily" },
    { value: "monthly", label: "Monthly" },
  ];
  const monthly = [
    "jan",
    "feb",
    "mar",
    "apr",
    "may",
    "jun",
    "jul",
    "aug",
    "sep",
    "oct",
    "nov",
    "dec",
  ];
  useEffect(() => {
    profitSharing(listTable);
  }, [listTable]);

  useEffect(() => {
    setListTable(array1);
  }, [array1]);

  useEffect(() => {
    setListTable(FilterListTable(searchVal, array1));
  }, [searchVal]);

  useEffect(() => {
    if (new JWToken()._getStatusAccessToken()) {
      readData();
    }
  }, []);

  async function readData() {
    const result = await service.layanan.hotspot.history._read(dispatch);
    if (result.status) {
      result.data.mikrotiks.map((item) => {
        item.label = item.name;
        item.value = item.id;
      });
      result.data.users.map((item) => {
        item.label = item.name;
        item.value = item.id;
      });
      result.data.months.map((item) => {
        item.label =
          monthly[parseInt(item.dateshort.substring(5, 7)) - 1] +
          ", " +
          item.dateshort.substring(0, 4);
        item.value = item.dateshort;
      });

      var crd = new Date();
      var y = crd.getFullYear();
      var m = crd.getMonth() + 1;
      var d = crd.getDate();
      if (m < 10) {
        m = "0" + m;
      }
      if (d < 10) {
        d = "0" + d;
      }
      var datetime = y + "-" + m + "-" + d;
      for (let index = 0; index < result.data.months.length; index++) {
        const element = result.data.months[index];
        updateData("selectMonth", element);
        index = result.data.months.length;
      }
      updateData("valueDay", datetime);
      updateData("selectType", { value: "monthly", label: "Monthly" });

      if (result.data.mikrotiks.length > 0) {
        if (result.data.seller) {
          if (result.data.users.length === 1) {
            updateData("selectMik", result.data.mikrotiks[0]);
            updateData("selectUser", result.data.users[0]);
          }
        } else {
          updateData("selectMik", result.data.mikrotiks[0]);
          setNewOptionAdmin(result.data.mikrotiks[0], result.data.users);
        }
      }

      actions.service.hotspot.history.setArray3(
        dispatch,
        result.data.mikrotiks
      );
      actions.service.hotspot.history.setArray4(dispatch, result.data.months);
      actions.service.hotspot.history.setArray5(dispatch, result.data.users);

      return;
    }
    return new Swalert().error(result.data);
  }
  function setNewOptionAdmin(mik, users) {
    const newListAdmin = [];

    for (let a = 0; a < users.length; a++) {
      const item = users[a];
      if (item.id === "admin") {
        newListAdmin.push(item);
      }
      if (item.id === "reseller") {
        newListAdmin.push(item);
      }
      if (a > 1) {
        a = users.length;
      }
    }

    for (let index = 0; index < mik.adminHotspot.length; index++) {
      const idAdmin = mik.adminHotspot[index];
      for (let a = 0; a < users.length; a++) {
        const item = users[a];
        if (item.id === idAdmin) {
          newListAdmin.push(item);
          a = users.length;
        }
      }
    }
    for (let index = 0; index < mik.resellerHotspot.length; index++) {
      const idAdmin = mik.resellerHotspot[index];
      for (let a = 0; a < users.length; a++) {
        const item = users[a];
        if (item.id === idAdmin) {
          newListAdmin.push(item);
          a = users.length;
        }
      }
    }

    updateData("selectUser", newListAdmin[0]);
    setOptionAdmin(newListAdmin);
  }
  function profitSharing(listTable) {
    var omset = 0;
    var sharing = 0;
    listTable.map((item) => {
      var shared = (parseInt(item.harga) * parseInt(item.percen)) / 100;
      omset = omset + parseInt(item.harga);
      if (isNaN(shared)) {
        shared = 0;
      }
      sharing = sharing + parseInt(shared);
    });
    setOmset(setRupiah(omset));
    setOrgn(setRupiah(omset - sharing));
    setSharing(setRupiah(sharing));
  }
  async function openModal(action, header) {
    actions.global.setModalShow(dispatch, true);
    actions.global.setModalAction(dispatch, action);
    actions.global.setModalTitleHeader(dispatch, header);

    return;
  }
  const updateData = (name, value) => {
    const newData = { [name]: value };
    actions.service.hotspot.history.updateObject1(dispatch, newData);
    if (name === "selectMik") {
      console.log(value);
      if (array5.length > 0) {
        setNewOptionAdmin(value, array5);
      }
    }
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      submitHandler();
    }
  };
  const submitHandler = async () => {
    if (!object1) {
      return new Swalert().error("select a valid value");
    }
    if (!object1.selectUser) {
      return new Swalert().error("select a valid value");
    }
    if (!object1.selectType) {
      return new Swalert().error("select a valid value");
    }
    var valueMonth = "";
    if (object1.selectType.value === "daily") {
      if (!object1.valueDay) {
        return new Swalert().error("select a valid value");
      }
    } else {
      if (!object1.selectMonth) {
        return new Swalert().error("select a valid value");
      }
      valueMonth = object1.selectMonth.value;
    }

    const payload = {
      mik: object1.selectMik.value,
      user: object1.selectUser.value,
      type: object1.selectType.value,
      monthly: valueMonth,
      daily: object1.valueDay,
    };
    const result = await service.layanan.hotspot.history._showHistory(
      dispatch,
      payload
    );
    if (result.status) {
      setSeller(result.data.seller);
      result.data.voucher.map((item) => {
        item.name = item.voucher;
      });
      actions.global.setModalShow(dispatch, false);
      actions.service.hotspot.history.setArray1(dispatch, result.data.voucher);
      return;
    }
    return new Swalert().error(result.data);
  };

  function modalForm() {
    return (
      <Form>
        <Row>
          {array5.length !== 1 ? (
            <>
              <SelectLabel
                title={"Select Mikrotik"}
                isSearchable={false}
                options={array3}
                value={object1.selectMik}
                onChange={(e) => updateData("selectMik", e)}
              />
              <SelectLabel
                title={"Select Seller"}
                isSearchable={false}
                options={optionAdmin}
                value={object1.selectUser}
                onChange={(e) => updateData("selectUser", e)}
                getOptionLabel={(option) =>
                  `${option.seller} : ${option.label}`
                }
              />
            </>
          ) : (
            ""
          )}

          <SelectLabel
            title={"Type"}
            isSearchable={false}
            options={optionType}
            value={object1.selectType}
            onChange={(e) => updateData("selectType", e)}
          />
          {object1.selectType ? (
            object1.selectType.value === "daily" ? (
              <InputLabel
                title="Select Date"
                type={"date"}
                name={"valueDay"}
                value={object1.valueDay}
                required
                onChange={(e) => updateData(e.target.name, e.target.value)}
                onKeyDown={handleKeyDown}
              />
            ) : (
              <SelectLabel
                title={"Select Month"}
                isSearchable={false}
                options={array4}
                value={object1.selectMonth}
                onChange={(e) => updateData("selectMonth", e)}
              />
            )
          ) : (
            ""
          )}
        </Row>
      </Form>
    );
  }
  function setLayoutTable() {
    function dataBody() {
      return listTable.map((item, index) => {
        return (
          <Tables.Tr>
            <Tables.Td>{index + 1}</Tables.Td>
            <Tables.Td>{item.datetime}</Tables.Td>
            <Tables.Td>{item.voucher}</Tables.Td>
            <Tables.Td>{item.password}</Tables.Td>
            <Tables.Td>{setRupiah(item.harga)}</Tables.Td>
            <Tables.Td>{item.limitVoucher}</Tables.Td>
          </Tables.Tr>
        );
      });
    }
    return (
      <Tables.Table>
        <Tables.Head>
          <Tables.Tr>
            <Tables.Th>#</Tables.Th>
            <Tables.Th>DATE_LOGIN</Tables.Th>
            <Tables.Th>VOUCHER</Tables.Th>
            <Tables.Th>PASSWORD</Tables.Th>
            <Tables.Th>PRICE</Tables.Th>
            <Tables.Th>DATE_EXPIRED</Tables.Th>
          </Tables.Tr>
        </Tables.Head>
        <Tables.Body>{dataBody()}</Tables.Body>
      </Tables.Table>
    );
  }
  function setRupiah(value) {
    return new Intl.NumberFormat("id-ID", {
      style: "currency",
      currency: "IDR",
      minimumFractionDigits: 0,
    }).format(value);
  }
  const styleLeft = {
    textAlign: "left",
    paddingTop: "0px",
    paddingBottom: "0px",
  };
  const styleRight = {
    textAlign: "right",
    paddingTop: "0px",
    paddingBottom: "0px",
  };
  return (
    <LayoutHome>
      <LayoutModal onSave={submitHandler}>{modalForm()}</LayoutModal>
      <Card>
        <Stack direction="horizontal" style={{ marginBottom: "5px" }} gap={2}>
          <Div style={{ margin: "10px" }}>
            <ButtonGroup>
              <ToggleButton
                variant={"outline-warning"}
                style={{ padding: "3px 3px 3px 5px" }}
                onClick={() => openModal("", "Select Data")}
              >
                {" SELECT DATA "}
              </ToggleButton>
            </ButtonGroup>
            {listTable.length > 0 ? (
              <Table style={{ marginTop: "20px", marginBottom: "0px" }}>
                <Tables.Body>
                  <Tables.Tr>
                    <Tables.Td style={styleLeft}>Omset</Tables.Td>
                    <Tables.Td style={styleRight}>{omset}</Tables.Td>
                  </Tables.Tr>
                  {seller !== "Reseller" ? (
                    <>
                      <Tables.Tr>
                        <Tables.Td style={styleLeft}>Org</Tables.Td>
                        <Tables.Td style={styleRight}>{orgn}</Tables.Td>
                      </Tables.Tr>
                      <Tables.Tr>
                        <Tables.Td style={styleLeft}>Seller</Tables.Td>
                        <Tables.Td style={styleRight}>{sharing}</Tables.Td>
                      </Tables.Tr>
                    </>
                  ) : (
                    ""
                  )}
                </Tables.Body>
              </Table>
            ) : (
              ""
            )}
          </Div>
          <Div
            className="ms-auto"
            style={{ alignSelf: "flex-end", width: "120px" }}
          >
            <Form.Control
              type={"text"}
              placeholder={"Cari Voucher . . ."}
              onChange={(e) => setSearchVal(e.target.value)}
            />
          </Div>
        </Stack>
        {setLayoutTable()}
      </Card>
    </LayoutHome>
  );
};

export default HotspotHistory;
