//d:default, m:master, s:service
const PageMenu = {
  d_todo: "/todo",
  d_sigUp: "/sigUp",
  d_sigIn: "/sigIn",
  d_home: "/home",
  d_user: "/user",
  d_menus: "/menus",
  m_org: "/master/org",
  m_access: "/master/access",
  m_settings: "/master/settings",
  m_whatsapp: "/master/whatsapp",
  m_message: "/master/message",
  m_mikrotik: "/master/mikrotik",
  m_users: "/master/users",
  sh_setting: "/service/hotspot/setting",
  sh_profile: "/service/hotspot/profile",
  sh_voucher: "/service/hotspot/voucher",
  sh_active: "/service/hotspot/active",
  sh_online: "/service/hotspot/online",
  sh_history: "/service/hotspot/history",
  sh_template: "/service/hotspot/template",
  ss_profile: "/service/static/profile",
  sp_profile: "/service/ppoe/profile",
  f_org: "/finace/org",
  f_user: "/finace/user",
};
export { PageMenu };
