function randNumb() {
  return Math.floor(Math.random() * 1000000);
}
const Icon = ({ children, addClass, ...props }) => {
  if (addClass === undefined) {
    addClass = "";
  }
  return (
    <i key={randNumb()} {...props} className={"material-icons" + addClass}>
      {children}
    </i>
  );
};

export default Icon;
