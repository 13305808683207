import { combineReducers } from "redux";

import globalReducer from "./globalReducer";
import pHomeReducer from "./primary/pHomeReducer";
import pUserReducer from "./primary/pUserReducer";
import pPagesReducer from "./primary/pPagesReducer";
import mAccessReducer from "./master/mAccessReducer";
import mOrgReducer from "./master/mOrgReducer";
import mUserReducer from "./master/mUserReducer";
import mMessageReducer from "./master/mMessageReducer";
import mWhatsappReducer from "./master/mWhatsappReducer";
import mSettingReducer from "./master/mSettingReducer";
import mMikrotikReducer from "./master/mMikrotikReducer";
import shSettingReducer from "./service/hotspot/shSettingReducer";
import shProfileReducer from "./service/hotspot/shProfileReducer";
import shVoucherReducer from "./service/hotspot/shVoucherReducer";
import shActiveReducer from "./service/hotspot/shActiveReducer";
import shOnlineReducer from "./service/hotspot/shOnlineReducer";
import shHistoryReducer from "./service/hotspot/shHistoryReducer";
import shTemplateReducer from "./service/hotspot/shTemplateReducer";
import fUserReducer from "./finance/fUserReducer";
import fOrgReducer from "./finance/fOrgReducer";

export default combineReducers({
  globalReducer,
  pHomeReducer,
  pUserReducer,
  pPagesReducer,
  mAccessReducer,
  mOrgReducer,
  mUserReducer,
  mMessageReducer,
  mWhatsappReducer,
  mSettingReducer,
  mMikrotikReducer,
  shSettingReducer,
  shProfileReducer,
  shVoucherReducer,
  shActiveReducer,
  shOnlineReducer,
  shHistoryReducer,
  shTemplateReducer,
  fUserReducer,
  fOrgReducer,
});
