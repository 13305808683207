import { Form } from "react-bootstrap";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";

const PhoneLabel = ({ title, error, ...rest }) => {
  return (
    <Form.Group className="mb-4">
      {title === "" ? "" : <Form.Label>{title}</Form.Label>}

      <PhoneInput
        inputStyle={{ width: "100%" }}
        className="m-b-md"
        country={"id"}
        enableSearch={true}
        disableSearchIcon={true}
        {...rest}
      />
      <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
    </Form.Group>
  );
};

export default PhoneLabel;
