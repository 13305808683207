import { Button, Card, Form, Image, Modal } from "react-bootstrap";
import Div from "../../components/Div";
import LayoutHome from "../../components/Layout/Home";
import Icon from "../../components/Icon";
import { useDispatch, useSelector } from "react-redux";
import { IdnKab, profile } from "../../assets";
import Swalert from "../../components/Swalert";
import { useEffect, useRef, useState } from "react";
import InputLabel from "../../components/InputLabel";
import SelectLabel from "../../components/SelectLabel";
import PhoneLabel from "../../components/PhoneLabel";
import AuthGoogle from "../../utils/AuthGoogle";
import UseLocalStorage from "../../utils/UseLocalStorage";
import { storage } from "../../utils/StaticString";
import EasyCrop from "../../components/Cropped/EasyCrop";
import Span from "../../components/Span";
import JWToken from "../../utils/JWToken";
import { actions } from "../../store/actions";
import { service } from "../../store/service";
import LayoutModal from "../../components/LayoutModal";

const User = () => {
  const dispatch = useDispatch();
  const { modalAction } = useSelector((state) => state.globalReducer);
  const { array1, object1, other1, other2 } = useSelector(
    (state) => state.pUserReducer
  );

  const [dataUser, setDataUser] = UseLocalStorage(storage.dataUser, "");

  const [userAuth, logIn, logOut] = AuthGoogle();

  const [iconEmail, setIconEmail] = useState("");
  const [formValidate, setFormValidate] = useState(false);
  const [waValidate, setWaValidate] = useState(true);

  const actUserLogin = "userLogin";
  const actPassword = "Password";
  const actDetail = "Detail";
  const actProfile = "Profile";
  const actCreatedLogin = "LoginPassword";

  const optionsGender = [
    { value: "male", label: "Laki Laki" },
    { value: "female", label: "Perempuan" },
  ];

  useEffect(() => {
    const kab = new IdnKab().getAllData();
    actions.primary.user.setArray1(dispatch, kab);
  }, []);

  useEffect(() => {
    if (dataUser) {
      if (dataUser.email === "") {
        setIconEmail("mail_lock");
      } else {
        setIconEmail("mark_email_read");
      }
      if (dataUser.user === "") {
        openModal(actCreatedLogin, "Created New Login User", {}, false);
      }
    }
  }, [dataUser]);

  useEffect(() => {
    if (userAuth !== null) {
      updateEmail(true);
    }
  }, [userAuth]);

  async function actionGmail() {
    if (dataUser.email === "") {
      logIn();
    } else {
      const swal = await new Swalert().confirm(
        "Hapus Tautan " + dataUser.email + " ?",
        "Yes"
      );
      if (swal) {
        return updateEmail(false);
      }
    }
  }

  async function openModal(action, header, item, close = true) {
    actions.global.setModalShow(dispatch, true);
    actions.global.setModalAction(dispatch, action);
    actions.global.setModalTitleHeader(dispatch, header);
    actions.global.setModalBtnClose(dispatch, close);

    await actions.primary.user.setObject1(dispatch, item);
    if (action === actDetail) {
      const result = await service.primary.user._read(dispatch);
      if (result.status) {
        optionsGender.map((item) => {
          if (result.data.gender === item.value) {
            actions.primary.user.setOther1(dispatch, item);
          }
        });

        array1.map((item) => {
          if (result.data.temLahir === item.value) {
            actions.primary.user.setOther2(dispatch, item);
          }
        });

        actions.primary.user.setObject1(dispatch, result.data);
        return;
      }
      return new Swalert().error(result.data);
    }
    return;
  }
  function handleChangeWA(value, country) {
    const contryCode = country.dialCode;
    if (value.length > contryCode.length) {
      if (value.slice(0, contryCode.length) === contryCode) {
        if (value.slice(contryCode.length, contryCode.length + 1) !== "0") {
          setWaValidate(true);
        } else {
          setWaValidate(false);
        }
      } else {
        setWaValidate(false);
      }
    } else {
      setWaValidate(false);
    }
    updateData("whatsapp", value);
  }
  const updateData = (name, value) => {
    setFormValidate(false);
    const newData = { [name]: value };
    actions.primary.user.updateObject1(dispatch, newData);
  };
  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      submitHandler();
    }
  };
  const submitHandler = async () => {
    if (modalAction === actUserLogin) {
      updateUserLogin();
    }
    if (modalAction === actPassword) {
      updatePasswordLogin();
    }
    if (modalAction === actDetail) {
      updateDataDetail();
    }
    if (modalAction === actProfile) {
      updateDataProfile();
    }
    if (modalAction === actCreatedLogin) {
      createdNewLogin();
    }
  };
  async function updateUserLogin() {
    if (!object1.oldUser || !object1.newUser || !object1.password) {
      return setFormValidate(true);
    }
    if (object1.oldUser !== dataUser.user) {
      return new Swalert().error("User login lama salah");
    }
    const result = await service.primary.user._newUserLogin(dispatch, object1);
    if (result.status) {
      setDataUser(result.data);
      actions.global.setDataUser(dispatch, result.data);
      actions.global.setModalShow(dispatch, false);
      return new Swalert().success("Update Data Success");
    }
    return new Swalert().error(result.data);
  }
  async function updatePasswordLogin() {
    if (
      !object1.newPassword ||
      !object1.repeatNewPass ||
      !object1.oldPassword
    ) {
      return setFormValidate(true);
    }
    if (object1.newPassword !== object1.repeatNewPass) {
      return new Swalert().error("Password Baru Tidak Sama");
    }
    const result = await service.primary.user._newPasswordLogin(
      dispatch,
      object1
    );
    if (result.status) {
      actions.global.setModalShow(dispatch, false);
      actions.global.setDataUser(dispatch, result.data);
      return new Swalert().success("Update Data Success");
    }
    return new Swalert().error(result.data);
  }
  async function createdNewLogin() {
    if (!object1.userLogin || !object1.password) {
      return setFormValidate(dispatch, true);
    }

    const result = await service.primary.user._createdNewLogin(
      dispatch,
      object1
    );
    if (result.status) {
      setDataUser(result.data);
      actions.global.setDataUser(dispatch, result.data);
      actions.global.setModalShow(dispatch, false);
      const swal = await new Swalert().success(
        "Created Login Success, Silahkan Login Kembali"
      );
      if (swal) {
        new JWToken()._removeCookie();
        return (window.location.href = "/");
      }
    }
    return new Swalert().error(result.data);
  }
  async function updateDataDetail() {
    if (
      object1.name === "" ||
      object1.tglLahir === "" ||
      object1.tglLahir === "0000-00-00"
    ) {
      return actions.global.setFormValidate(dispatch, true);
    }

    if (object1.gender === "") {
      return new Swalert().error("Berikan Jenis Kelamin yang valid");
    }
    if (object1.temLahir === 0) {
      return new Swalert().error("Berikan Tempat Lahir yang valid");
    }

    if (!waValidate) {
      return new Swalert().error("Berikan Nomor Whatsapp yang valid");
    }
    const result = await service.primary.user._update(dispatch, object1);
    if (result.status) {
      actions.global.setModalShow(dispatch, false);
      actions.global.setDataUser(dispatch, result.data);
      return new Swalert().success("Update Data Success");
    }
    return new Swalert().error(result.data);
  }
  async function updateEmail(action) {
    let uid = "";
    let email = "";
    if (action) {
      uid = userAuth.uid;
      email = userAuth.email;
    }
    const dataEmail = { uidEmail: uid, email: email };
    const result = await service.primary.user._update(dispatch, dataEmail);
    console.log(result);
    if (result.status) {
      setDataUser(result.data);
      actions.global.setModalShow(dispatch, false);
      actions.global.setDataUser(dispatch, result.data);
      if (action) {
        setInterval(function () {
          return logOut();
        }, 1000);
        return new Swalert().success("Berhasil Tautkan ke " + userAuth.email);
      } else {
        return new Swalert().success("Success Hapus Tautan");
      }
    }
    return new Swalert().error(result.data);
  }

  const clickSelect = useRef();
  const clickCrop = useRef();
  async function updateDataProfile() {
    clickCrop.current?.childFunctionCrop();
  }
  function modalFormProfile() {
    const saveImageCrop = async ({ status, response }) => {
      if (!status) {
        return new Swalert().warning("Please, select new image");
      }
      const dataImage = { image: response };
      const result = await service.primary.user._profile(dispatch, dataImage);
      if (result.status) {
        setDataUser(result.data);
        actions.global.setModalShow(dispatch, false);
        actions.global.setDataUser(dispatch, result.data);
        return new Swalert().success("Update Success");
      }
      return new Swalert().error(result.data);
    };
    return (
      <>
        <Button
          style={{ marginBottom: "10px" }}
          onClick={() => {
            clickSelect.current?.childFunctionSelect();
          }}
        >
          <Span>Select File</Span>
        </Button>
        <EasyCrop
          image={dataUser.profile === "" ? profile : dataUser.profile}
          rHeight={4}
          rWidth={5}
          clickSelect={clickSelect}
          clickCrop={clickCrop}
          clickSave={saveImageCrop}
        />
      </>
    );
  }
  function modalFormUserLogin() {
    return (
      <Form noValidate validated={formValidate}>
        <InputLabel
          title="User Login Lama"
          name={"oldUser"}
          error={"Please provide a valid"}
          required
          onChange={(e) => updateData(e.target.name, e.target.value)}
          onKeyDown={handleKeyDown}
        />
        <InputLabel
          title="User Login Baru"
          name={"newUser"}
          error={"Please provide a valid"}
          required
          onChange={(e) => updateData(e.target.name, e.target.value)}
          onKeyDown={handleKeyDown}
        />
        <InputLabel
          title="Password"
          type={"password"}
          name={"password"}
          error={"Please provide a valid"}
          required
          onChange={(e) => updateData(e.target.name, e.target.value)}
          onKeyDown={handleKeyDown}
        />
      </Form>
    );
  }
  function modalFormUserPassword() {
    return (
      <Form noValidate validated={formValidate}>
        <InputLabel
          title="Password Baru"
          type={"password"}
          name={"newPassword"}
          error={"Please provide a valid"}
          required
          onChange={(e) => updateData(e.target.name, e.target.value)}
          onKeyDown={handleKeyDown}
        />
        <InputLabel
          title="Ulangi Password Baru"
          type={"password"}
          name={"repeatNewPass"}
          error={"Please provide a valid"}
          required
          onChange={(e) => updateData(e.target.name, e.target.value)}
          onKeyDown={handleKeyDown}
        />
        <InputLabel
          title="Password Lama"
          type={"password"}
          name={"oldPassword"}
          error={"Please provide a valid"}
          required
          onChange={(e) => updateData(e.target.name, e.target.value)}
          onKeyDown={handleKeyDown}
        />
      </Form>
    );
  }
  function modalFormCreatedLogin() {
    return (
      <Form noValidate validated={formValidate}>
        <InputLabel
          title="New User Login"
          name={"userLogin"}
          required
          onChange={(e) => updateData(e.target.name, e.target.value)}
          onKeyDown={handleKeyDown}
        />
        <InputLabel
          title="New Password"
          type={"password"}
          name={"password"}
          required
          onChange={(e) => updateData(e.target.name, e.target.value)}
          onKeyDown={handleKeyDown}
        />
      </Form>
    );
  }
  function modalFormDetail() {
    return (
      <Form noValidate validated={formValidate}>
        <InputLabel
          title="Nama Lengkap"
          name={"name"}
          value={object1.name}
          required
          onChange={(e) => updateData(e.target.name, e.target.value)}
          onKeyDown={handleKeyDown}
        />
        <SelectLabel
          title={"Jenis Kelamin"}
          isSearchable={false}
          required
          value={other1}
          options={optionsGender}
          onChange={(e) => {
            actions.primary.user.setOther1(dispatch, e);
            updateData("gender", e.value);
          }}
        />
        <SelectLabel
          title={"Tempat"}
          isSearchable={true}
          required
          value={other2}
          options={array1}
          onChange={(e) => {
            actions.primary.user.setOther2(dispatch, e);
            updateData("temLahir", e.value);
          }}
        />
        <InputLabel
          title={"Tanggal Lahir"}
          type="date"
          name={"tglLahir"}
          required
          value={object1.tglLahir}
          onChange={(e) => updateData(e.target.name, e.target.value)}
          onKeyDown={handleKeyDown}
        />
        <PhoneLabel
          title="Nomor Whatsapp"
          value={object1.whatsapp}
          onChange={handleChangeWA}
          onKeyDown={handleKeyDown}
        />
      </Form>
    );
  }
  return (
    <LayoutHome>
      <Card>
        <LayoutModal onSave={submitHandler}>
          {modalAction === actProfile ? modalFormProfile() : ""}
          {modalAction === actUserLogin ? modalFormUserLogin() : ""}
          {modalAction === actPassword ? modalFormUserPassword() : ""}
          {modalAction === actCreatedLogin ? modalFormCreatedLogin() : ""}
          {modalAction === actDetail ? modalFormDetail() : ""}
        </LayoutModal>
        <Div style={{ width: "100%", height: "100%", margin: "15px" }}>
          <Div className="d-flex flex-column align-items-center text-center">
            <Image
              onClick={() => openModal(actProfile, "Update Profile", {})}
              src={dataUser.profile === "" ? profile : dataUser.profile}
              alt="img"
              style={{ width: "150px", borderRadius: "5px", cursor: "pointer" }}
            />
            <Form.Control
              hidden
              type="file"
              accept="image/png, image/jpeg"
              style={{ height: "0", overflow: "hidden" }}
            />
            <Div className="mt-3">
              <p className="text-muted font-size-sm">{dataUser.user}</p>
              <h4 style={{ marginBottom: "30px" }}>{dataUser.fullName}</h4>
              <Button
                onClick={actionGmail}
                variant="outline-primary"
                style={{ padding: "5px 5px 5px 18px", marginRight: "15px" }}
              >
                <Icon>{iconEmail}</Icon>
              </Button>
              <Button
                variant="outline-primary"
                style={{ padding: "5px 5px 5px 18px" }}
                onClick={() => openModal(actUserLogin, "Update User Login", {})}
              >
                <Icon>key</Icon>
              </Button>
              <Button
                variant="outline-primary"
                style={{
                  padding: "5px 5px 5px 18px",
                  marginLeft: "15px",
                  marginRight: "15px",
                }}
                onClick={() => openModal(actPassword, "Update Password", {})}
              >
                <Icon>lock</Icon>
              </Button>
              <Button
                variant="outline-primary"
                style={{ padding: "5px 5px 5px 18px" }}
                onClick={() => openModal(actDetail, "Update Detail", {})}
              >
                <Icon>edit_square</Icon>
              </Button>
            </Div>
          </Div>
        </Div>
      </Card>
    </LayoutHome>
  );
};

export default User;
