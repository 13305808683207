import { useState } from "react";
import { Form, Toast, ToggleButton } from "react-bootstrap";

const InputLabel = ({ title, type, error, toast, notify, ...rest }) => {
  const [showToast, setShowToast] = useState(false);
  if (type === undefined) {
    type = "text";
  }
  if (title === undefined) {
    title = "";
  }
  if (error === undefined) {
    error = "Please provide a valid";
  }
  if (toast === undefined) {
    toast = false;
  }
  if (notify === undefined) {
    notify = false;
  }
  return (
    <Form.Group className={"mb-4"}>
      {title !== undefined && title !== "" ? (
        <>
          <Form.Label>{title}</Form.Label>
          {toast ? (
            <>
              <ToggleButton
                variant={"outline-primary"}
                style={{ padding: "0px 3px 0px 3px" }}
                onClick={() => setShowToast(!showToast)}
              >
                ?
              </ToggleButton>
              <Toast
                style={{ position: "absolute", maxWidth: "250px" }}
                show={showToast}
                onClick={() => setShowToast(!showToast)}
              >
                <Toast.Body>{toast}</Toast.Body>
              </Toast>
            </>
          ) : (
            ""
          )}
        </>
      ) : (
        ""
      )}
      <Form.Control type={type} {...rest} />
      <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
      {notify ? <span>{notify}</span> : ""}
    </Form.Group>
  );
};

export default InputLabel;
