const FilterListTable = (searchVal, arrayList) => {
  if (searchVal === "") {
    return arrayList;
  }
  const filterBySearch = arrayList.filter((item) => {
    if (item.name.toLowerCase().includes(searchVal.toLowerCase())) {
      return item;
    }
  });
  return filterBySearch;
};

export default FilterListTable;
