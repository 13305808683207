const actionType = {
  setArray1: "actions.service.hotspot.active.setArray1",
  addArray1: "actions.service.hotspot.active.addArray1",
  updateArray1: "actions.service.hotspot.active.updateArray1",
  deleteArray1: "actions.service.hotspot.active.deleteArray1",

  setArray2: "actions.service.hotspot.active.setArray2",
  addArray2: "actions.service.hotspot.active.addArray2",
  updateArray2: "actions.service.hotspot.active.updateArray2",
  deleteArray2: "actions.service.hotspot.active.deleteArray2",

  setArray3: "actions.service.hotspot.active.setArray3",
  addArray3: "actions.service.hotspot.active.addArray3",
  updateArray3: "actions.service.hotspot.active.updateArray3",
  deleteArray3: "actions.service.hotspot.active.deleteArray3",

  setArray4: "actions.service.hotspot.active.setArray4",
  addArray4: "actions.service.hotspot.active.addArray4",
  updateArray4: "actions.service.hotspot.active.updateArray4",
  deleteArray4: "actions.service.hotspot.active.deleteArray4",

  setObject1: "actions.service.hotspot.active.setObject1",
  updateObject1: "actions.service.hotspot.active.updateObject1",
};

const setArray1 = async (dispatch, payload) => {
  dispatch({ type: actionType.setArray1, payload: payload });
};
const addArray1 = async (dispatch, payload) => {
  dispatch({ type: actionType.addArray1, payload: payload });
};
const updateArray1 = async (dispatch, payload) => {
  dispatch({ type: actionType.updateArray1, payload: payload });
};
const deleteArray1 = async (dispatch, payload) => {
  dispatch({ type: actionType.deleteArray1, payload: payload });
};

const setArray2 = async (dispatch, payload) => {
  dispatch({ type: actionType.setArray2, payload: payload });
};
const addArray2 = async (dispatch, payload) => {
  dispatch({ type: actionType.addArray2, payload: payload });
};
const updateArray2 = async (dispatch, payload) => {
  dispatch({ type: actionType.updateArray2, payload: payload });
};
const deleteArray2 = async (dispatch, payload) => {
  dispatch({ type: actionType.deleteArray2, payload: payload });
};

const setArray3 = async (dispatch, payload) => {
  dispatch({ type: actionType.setArray3, payload: payload });
};
const addArray3 = async (dispatch, payload) => {
  dispatch({ type: actionType.addArray3, payload: payload });
};
const updateArray3 = async (dispatch, payload) => {
  dispatch({ type: actionType.updateArray3, payload: payload });
};
const deleteArray3 = async (dispatch, payload) => {
  dispatch({ type: actionType.deleteArray3, payload: payload });
};

const setArray4 = async (dispatch, payload) => {
  dispatch({ type: actionType.setArray4, payload: payload });
};
const addArray4 = async (dispatch, payload) => {
  dispatch({ type: actionType.addArray4, payload: payload });
};
const updateArray4 = async (dispatch, payload) => {
  dispatch({ type: actionType.updateArray4, payload: payload });
};
const deleteArray4 = async (dispatch, payload) => {
  dispatch({ type: actionType.deleteArray4, payload: payload });
};
const setObject1 = async (dispatch, payload) => {
  dispatch({ type: actionType.setObject1, payload: payload });
};
const updateObject1 = async (dispatch, payload) => {
  dispatch({ type: actionType.updateObject1, payload: payload });
};

export const active = {
  actionType,
  setArray1,
  addArray1,
  updateArray1,
  deleteArray1,
  setArray2,
  addArray2,
  updateArray2,
  deleteArray2,
  setArray3,
  addArray3,
  updateArray3,
  deleteArray3,
  setArray4,
  addArray4,
  updateArray4,
  deleteArray4,
  setObject1,
  updateObject1,
};
