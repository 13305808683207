import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { config, storage } from "../../utils/StaticString";
import { useDispatch } from "react-redux";
import UseLocalStorage from "../../utils/UseLocalStorage";
import JWToken from "../../utils/JWToken";
import CriptoJS from "../../utils/CriptoJS";
import Swalert from "../../components/Swalert";
import LayoutAuth from "../../components/Layout/Auth";
import { orgLogo } from "../../assets";
import { Image } from "react-bootstrap";
import { service } from "../../store/service";

const removeAllStorage = async () => {
  try {
    if (typeof window !== "undefined") {
      //window.localStorage.clear();
      const cekStorageUser = window.localStorage.getItem(storage.dataUser);
      if (cekStorageUser !== null) {
        window.localStorage.removeItem(storage.dataUser);
      }

      const cekStorageAccess = window.localStorage.getItem(storage.accessMenu);
      if (cekStorageAccess !== null) {
        window.localStorage.removeItem(storage.accessMenu);
      }
    }
  } catch (error) {
    console.log(error);
  }
};
const Refferal = () => {
  removeAllStorage();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { ref } = useParams();
  console.log(ref);

  const [accMenu, setAccMenu] = UseLocalStorage(storage.accessMenu, false);
  const [dataUser, setDataUser] = UseLocalStorage(storage.dataUser, false);
  const [dataOrg, setDataOrg] = UseLocalStorage(storage.dataOrg, false);
  const [theme, setTheme] = UseLocalStorage(storage.themes, false);

  useEffect(() => {
    resultLogin();
  }, []);

  const resultLogin = async () => {
    const payload = { kodeRefferal: ref };
    const result = await service.auth.sign.signRefferal(dispatch, payload);
    if (result.status === "succes_login") {
      new JWToken()._setJWToken(result.token);

      setTheme(result.theme);
      setDataUser(result.dataUser);
      setDataOrg(result.dataOrg);

      setAccMenu(new CriptoJS().encript(result.dataMenu));
      const swal = await new Swalert().success("Login Berhasil . . .");
      if (swal) {
        navigate("/", { replace: true });
      }
    } else {
      const swal = await new Swalert().warning(result.error);
      if (swal) {
        navigate("/", { replace: true });
      }
    }
  };
  return (
    <LayoutAuth type={"sigIn"}>
      <div className="logo">
        <table style={{ width: "100%" }}>
          <tbody>
            <tr>
              <td style={{ padding: "10px", textAlign: "center" }}>
                <Image
                  style={{ height: "250px" }}
                  src={
                    dataOrg
                      ? dataOrg.orgIcon
                        ? dataOrg.orgIcon
                        : orgLogo
                      : orgLogo
                  }
                ></Image>
              </td>
            </tr>
          </tbody>
        </table>
        <h1 style={{ textAlign: "center" }}>
          {dataOrg
            ? dataOrg.orgName
              ? dataOrg.orgName
              : config.orgName
            : config.orgName}
        </h1>
      </div>
      <p className="auth-description">
        Please sign-in to your account and continue to the dashboard
      </p>
    </LayoutAuth>
  );
};

export default Refferal;
